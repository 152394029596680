import React, { useMemo, useEffect, useState } from 'react'

const ComparisonTableAnalysisTransport = ({ data  }) => {
    //const [data, setData] = useState([]);
    //console.log("ComparisonTableAnalysisTransport data", data);
    /**
     * useEffect(() => {
        // Fetch the data from the JSON file
        fetch(jsonFile)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setData(data);  // Set the data to state
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }, []);
     */
    
    /**
     * <table className="overflow-y-auto">
        <thead>
          <tr>
            <th className="py-2">Parameter</th>
            <th className="py-2">Consumo de Agua</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.nombre}</td>
              <td>{item.kpi.consumo_agua} L</td>
            </tr>
          ))}
        </tbody>
      </table>
     */

  return (
      <table className="standard-table overflow-y-auto">
        <thead>
          <tr>
            <th>Parámetros</th>
            {data.map((item, index) => (
              <th className="py-2" key={index}>Trayecto {item.origen}-{item.destino}</th>
            ))}
          </tr>
        </thead>
        <tbody>
            <tr>
            <td>Origen</td>
            {data.map((item, index) => (
              <td key={index}>{item.origen}</td>
            ))}
          </tr> 
          <tr>
            <td>Destino</td>
            {data.map((item, index) => (
              <td key={index}>{item.destino}</td>
            ))}
          </tr>
          <tr>
            <td>Distancia</td>
            {data.map((item, index) => (
              <td key={index}>{item.distancia}</td>
            ))}
          </tr>
          <tr>
            <td>Medio de Transporte</td>
            {data.map((item, index) => (
              <td key={index}>{item.medioTransporte}</td>
            ))}
          </tr>
          <tr>
            <td>Peso de la Carga</td>
            {data.map((item, index) => (
              <td key={index}>{item.pesoCarga}</td>
            ))}
          </tr>
          <tr>
            <td>Emisiones de CO2</td>
            {data.map((item, index) => (
              <td key={index}>{item.emisionCO2}</td>
            ))}
          </tr>
        </tbody>
      </table>
  );
};

export default ComparisonTableAnalysisTransport;
