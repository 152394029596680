import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    const validate = () => {
        let isValid = true;
        let errors = {};
    
        if (!email) {
          isValid = false;
          errors.email = 'El email es necesario';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          isValid = false;
          errors.email = 'El email no es válido';
        }
    
        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (validate()) {
                // Call the serverless function to send reset token
                const response = await fetch('/api/passwordResetRequest', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        email,
                        baseUrl: window.location.origin 
                    }),
                });
    
                if (response.ok) {
                    const data = await response.json();
                    console.log("ForgotPassword data", data);
                    //setMessage(data.message);
                    setMessage("Te hemos enviado un email para crear una nueva contraseña.");
                } else {
                    // Handle non-OK responses
                    console.error('Non-OK response:', response);
                    setMessage('Error en la respuesta del servidor');
                }
            }
        } catch (error) {
            console.error('Request error:', error);
            setMessage('Error conectando con el servidor');
          }        
        
    };

    return (
        <div className="flex h-[100svh] sm:h-screen w-full flex-col justify-center items-center gap-y-4">
        <div className="flex flex-col rounded-xl py-8 md:py-12 px-3 md:px-8 w-full max-w-md gap-y-6 bg-white shadow-md">
            <h1 className="text-dark-blue-950 w-auto uppercase text-center font-bold text-3xl">Restablecer la contraseña
                </h1> 
                <form onSubmit={handleSubmit}>
                    <div className="mb-5">
                        <div className="relative">
                        <input 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            type="text" 
                            id="floating_Email" 
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                        <label 
                            htmlFor="floating_Email" 
                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">E-Mail</label>
                        </div>
                        {errors.email && 
                            <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.email}</p>
                        }
                    </div>
                    <input
                        type="submit"
                        value="Enviar correo de restablecimiento"
                        className="bg-dark-blue-700 w-full py-3 text-white uppercase font-bold rounded hover:cursor-pointer hover:bg-sky-500 transition-colors"
                        />
                </form>
                {message && <p className="text-green-500">{message}</p>}
                <div className="lg:flex lg:justify-between">    
                    <p className="text-sm text-center ">Volver a <Link
                    className="inline-block text-center text-dark-blue-700 hover:text-dark-blue-500"
                    to="/login"
                    >Login</Link></p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
