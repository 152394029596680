import React, { useState, useEffect, useMemo, useRef } from "react";
import TableProductsSelectRow from "../tables/TableProductsSelectRow";
import TableProductsSelectRowHigh from "../tables/TableProductsSelectRowHigh";
import ComparisonTableProducers from "../tables/ComparisonTableProducers";
import ComparisonTableCompaniesContact from "../tables/ComparisonTableCompaniesContact";
import ComparisonTableCompaniesLocation from "../tables/ComparisonTableCompaniesLocation";
import ComparisonTableCompaniesCertifications from "../tables/ComparisonTableCompaniesCertifications";
import ComparisonTableProducersKpi from "../tables/ComparisonTableProducersKpi";
import MapLocation from "../charts/MapLocation";
import { Tooltip } from "react-tooltip";
import { Icon } from "@iconify/react";
import AddCompany from "../components/AddCompany";
import { EditCompany } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import FinanceSection from "../components/FinanceSection";

const LeftContent = ({
  selectedData,
  showTable,
  setShowTable,
  onSearch,
  onRowSelect,
  selectedRowData,
  setSelectedRowData,
  onAddCompanyClick,
  refreshData,
  setRefreshData,
  setShowEditCompany,
  addCompanyComponentRef,
  editCompanyComponentRef,
  onDeleteButtonClick,
}) => {
  const [input, setInput] = useState("");
  const [storedItems, setStoredItems] = useState([]); //Store all items
  const [error, setError] = useState(null);
  const [showTooltipInput, setShowTooltipInput] = useState(false);
  const [showTooltipTable, setShowTooltipTable] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]); //Store only the selected items
  //
  const [responseMessage, setResponseMessage] = useState("");
  const [noDataFoundMessage, setNoDataFoundMessage] = useState("");
  const [warningMessageLeft, setWarningMessageLeft] = useState("");
  //
  const [companyName, setCompanyName] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [gs1, setGs1] = useState("");
  const [selectedCoords, setSelectedCoords] = useState(null);
  //
  const [isLoading, setIsLoading] = useState(false);
  //
  const timerRef = useRef(null);
  const tableRef = useRef(null);

  const addCompanyRef = useRef(null);
  const editCompanyRef = useRef(null);
  const delCompanyRef = useRef(null);
  //const { refreshData, setRefreshData } = useStateContext();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const fetchData = async (category) => {
    setIsLoading(true); // Start loading
    setError(null); // Reset previous errors
    try {
      // Call the serverless function here
      const response = await fetch(`/api/getTrustyData?category=${category}`, {
        method: "GET",
      });
      console.log("Empresas response", response);
      const retrievedJSON = await response.json();
      if (response.ok) {
        const formattedData = retrievedJSON.data.map((item) => ({
          ...item,
          date: new Date(item.date).toLocaleDateString("en-GB"), // Formats to 'DD/MM/YYYY'
        }));

        setStoredItems(formattedData);
        setFilteredItems(formattedData);
        setShowTable(retrievedJSON.data.length > 0); //setShowTable(true);
        console.log("Empresas data received:", retrievedJSON);
      } else {
        console.error(
          "Error recibiendo datos de Empresas:",
          retrievedJSON.message
        ); //Reads the message sent from the "backend"
        setError("No se pudieron recibir datos del servidor"); //Reads the message sent from the "backend"
      }
    } catch (error) {
      console.error("Request error:", error);
      setResponseMessage("Error conectando con el servidor");
    }

    setIsLoading(false); // End loading
  }; //End fetchData

  useEffect(() => {
    fetchData("companies");
  }, []);

  //Watches the input state and If it's not empty, reset the selectedRowData to null
  useEffect(() => {
    // Reset selected row data when input changes
    if (input.trim() !== "") {
      setSelectedRowData(null);
      setSelectedCoords(null);
    }
  }, [input, setSelectedCoords, setSelectedRowData]);

  //Triggers fetchData if AddCompany completed succesfully
  useEffect(() => {
    if (refreshData) {
      console.log("Companies useEffect Refreshing data");
      fetchData("companies");
      setRefreshData(false); // Reset the refresh trigger
    }
  }, [refreshData, setRefreshData]);

  const handleSearch = () => {
    if (input.trim() !== "") {
      const filteredData = storedItems.filter((item) =>
        item.name.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredItems(filteredData);
      setShowTable(filteredData.length > 0);

      // Set message if no data is found
      if (filteredData.length === 0) {
        setNoDataFoundMessage("No se encontraron empresas con ese nombre.");
      } else {
        setNoDataFoundMessage("");
      }
    }
    /*if (input.trim() === "") {
      setFilteredItems(storedItems);
      //setShowTable(true);
      setShowTable(storedItems.length > 0);
    } else {
      const filteredData = storedItems.filter(item => 
        item.name.toLowerCase().includes(input.toLowerCase()) 
      );
      setFilteredItems(filteredData);
      setShowTable(filteredData.length > 0);
    }    */
  };

  const handleRowSelect = (rowData) => {
    onRowSelect(rowData);
    setSelectedCoords({ lat: rowData.lat, lng: rowData.long });
    setWarningMessageLeft("");
  };
  const columns = useMemo(
    () => [
      { Header: "Empresa", accessor: "name" },
      { Header: "Estado", accessor: "status" },
      // { Header: "Fecha", accessor: "date" }
    ],
    []
  );

  const handleInputChange = (e) => {
    //setInput(e.target.value);
    /*if (e.target.value.trim() === "") {
      setFilteredItems(storedItems);  // Clear the filtered items
      //onSearch(storedItems); // Pass all stored items to the parent component
      setShowTable(storedItems.length > 0); // Show the table if there are items
      setSelectedRowData(null); // Reset selected row data
      //handleSearch();
    }*/
    const value = e.target.value;
    // Regular expression to allow only numbers, letters, accents, and spaces
    if (/^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 ]*$/.test(value)) {
      setInput(value);
      if (value.trim() === "") {
        setFilteredItems(storedItems);
        setShowTable(storedItems.length > 0);
        setSelectedRowData(null); // Reset selected row data
        setSelectedCoords(null);
        setNoDataFoundMessage(""); // Reset no data found message
      }
    }
    //handleSearch();
    setShowTooltipInput(true);
  };

  //TOOLTIPS
  const handleMouseEnter = (element) => {
    if (element === "input") {
      setShowTooltipInput(true); // Show tooltip for input
    } else if (element === "table") {
      setShowTooltipTable(true); // Show tooltip for table
    }

    timerRef.current = setTimeout(() => {
      if (element === "input") {
        setShowTooltipInput(false); // Hide tooltip for input
      } else if (element === "table") {
        setShowTooltipTable(false); // Hide tooltip for table
      }
    }, 2000);
  };

  const onMouseOut = (element) => {
    if (element === "input") {
      setShowTooltipInput(false); // Hide tooltip for input
    } else if (element === "table") {
      setShowTooltipTable(false); // Hide tooltip for table
    }
    clearTimeout(timerRef.current);
  };

  //Clears out the data in the RightContent
  //addCompanyRef and editCompanyRef are refs to the buttons añadir and edit company so that they are left out of this method
  // const handleClickOutside = (event) => {
  //   //console.log("Companies leftContent Clicked outside the table");
  //   if (tableRef.current && !tableRef.current.contains(event.target) &&
  //     (!addCompanyRef.current || !addCompanyRef.current.contains(event.target)) &&
  //     (!editCompanyRef.current || !editCompanyRef.current.contains(event.target)) &&
  //     (!delCompanyRef.current || !delCompanyRef.current.contains(event.target)) &&
  //     (!addCompanyComponentRef.current || !addCompanyComponentRef.current.contains(event.target)) &&
  //     (!editCompanyComponentRef.current || !editCompanyComponentRef.current.contains(event.target))) {
  //     setSelectedRowData(null);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  //EDIT COMPANY
  const handleEditCompany = () => {
    console.log(
      "Companies leftContent handleEditCompany selectedRowData",
      selectedRowData
    );
    if (selectedRowData) {
      setShowEditCompany(true); // Open the EditCompany component
      setWarningMessageLeft(""); // Clear any existing warning message
    } else {
      setWarningMessageLeft(
        "Para editar una empresa antes ha de seleccionar una."
      );
      //console.log("Companies lefTContent editar empresaia button clicked");
    }
    /**if (!selectedRowData) {
      setWarningMessageLeft('Para editar una empresa antes ha de seleccionar una.');
      console.log("Companies lefTContent editar empresaia button clicked");
    }**/
  };

  return (
    <div className="h-[calc(100svh_-_99px)] md:h-full flex gap-y-4 flex-col p-4 md:p-6 pt-14 md:pt-10 md:border-r border-white w-full md:w-1/2 relative">
      <h1
        className={`${
          selectedData ? " opacity-0 h-0" : ""
        } transition-all items text-3xl lg:text-3xl font-semibold text-dark-blue-600`}>
        Empresas
      </h1>
      <p
        className={`${
          selectedData ? " opacity-0 h-0" : ""
        } transition-all text-sm text-dark-blue-900`}>
        Busca por empresa para obtener más información
      </p>
      <div className="flex">
        {/* ! TODO REVISA SI OPINAS QUE ESTO ESTA BIEN CON EL FAKE USER PARA SAFARI */}
        <input
          id="fake_user_name"
          name="fake_user[name]"
          className="-top-[100px] absolute opacity-0"
          type="text"
          value="Safari Autofill Me"
        />

        <input
          className="rounded p-2 flex-grow mr-2 focus:outline-primary-200 focus:outline-none focus:ring-0"
          type="search"
          autoComplete="new-off"
          placeholder="Escribe el nombre de una empresa..."
          value={input}
          onChange={handleInputChange}
          onKeyUp={(e) => e.key === "Enter" && handleSearch()}
          onMouseEnter={() => handleMouseEnter("input")}
          onMouseOut={() => onMouseOut("input")}
          data-tooltip-delay-show={1000}
          data-tooltip-id="inputProductores"
          data-tooltip-html="Escribe el nombre de una empresa."
        />
        <button
          className="bg-dark-blue-900 hover:bg-dark-blue-700 h-full flex justify-center items-center aspect-square text-light-green-100 p-2 rounded"
          onClick={handleSearch}>
          <Icon icon="material-symbols:search" className="h-5 w-5" />
        </button>
        <Tooltip
          className="custom-tooltip"
          id="inputProductores"
          place="bottom"
          type="dark"
          effect="solid"
          isOpen={showTooltipInput}
        />
      </div>
      {noDataFoundMessage && (
        <p className="my-4 text-red-600">{noDataFoundMessage}</p>
      )}
      {isLoading && (
        <div className="h-full w-full flex justify-center items-center">
          <p className="text-sm text-dark-blue-900">Cargando...</p>
        </div>
      )}
      {error && <p className="my-4 text-red-600">{error}</p>}
      {/**Similar than the previous but for the compareButton. This also calls the method that opens the Compare window */}
      {!isLoading && showTable && (
        <>
          {/* <div className='flex flex-col gap-y-4'> */}
          <div className="rounded-md h-full overflow-clip relative">
            <div
              ref={tableRef}
              data-tooltip-id="tableCompanies"
              data-tooltip-content="Selecciona una empresa de la tabla para ver más información."
              className="h-full  shrink-1 grow-1 overflow-y-auto table-container"
              onMouseEnter={() => handleMouseEnter("table")}
              onMouseOut={() => onMouseOut("table")}
              yy>
              <TableProductsSelectRowHigh
                data={filteredItems} //data={storedItems}  data={input ? filteredItems : storedItems}
                columns={columns}
                onRowSelect={handleRowSelect}
                selectedRowData={selectedRowData}
              />{" "}
              {/**onRowSelect={onRowSelect} */}
            </div>
            <Tooltip
              className="custom-tooltip"
              id="tableCompanies"
              place="top"
              type="dark"
              effect="solid"
              isOpen={showTooltipTable}
            />
          </div>
          {warningMessageLeft && (
            <p className="text-red-500">{warningMessageLeft}</p>
          )}
          {selectedCoords && (
            <div className="h-full z-10 overflow-clip rounded-md shrink-1 grow-1 map-container">
              <MapLocation from={selectedCoords} height="100%" width="100%" />
            </div>
          )}
        </>
      )}
      {warningMessageLeft && (
        <p className="text-red-500">{warningMessageLeft}</p>
      )}
      {/**
       * <div className="flex justify-end mt-auto gap-x-2 z-[51]">
        <button ref={addCompanyRef} type="submit" className="bg-dark-green-700 hover:bg-dark-green-600 text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded" onClick={onAddCompanyClick}>
          <Icon icon="eos-icons:content-new" className="w- 4 mr-2" />
          <span>Añadir</span>
        </button>
        <button ref={editCompanyRef} type="submit" className={`text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded ${selectedRowData ? "bg-dark-blue-900 hover:bg-dark-blue-700" : "cursor-not-allowed bg-dark-blue-900/50"}`} onClick={handleEditCompany} >
          <Icon icon="material-symbols:edit" className="w- 4 mr-2" />
          <span>Editar</span>
        </button> //disabled={!selectedRowData}  
        <button ref={delCompanyRef} type="submit" className={`text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded ${selectedRowData ? "bg-red-800 hover:bg-red-500" : "cursor-not-allowed bg-red-800/50"}`} onClick={onDeleteButtonClick}>
          <Icon icon="mdi:trash-outline" className="w- 4 mr-2" />
          <span>Borrar</span>
        </button>
      </div>
       */}
    </div>
  );
}; //End leftContent

const RightContent = ({ selectedData }) => {
  //   const addCompanyRef = useRef(null);
  // const editCompanyRef = useRef(null);
  // const delCompanyRef = useRef(null);
  return (
    <div
      className={`flex flex-col gap-y-4 ${
        selectedData
          ? " items-start justify-start "
          : "translate-y-full md:translate-y-0 items-center justify-center"
      } transition-transform h-[75svh] shadow-lg md:shadow-none bg-light-green-100 md:h-full w-full md:w-1/2 pt-10 p-4 md:p-6 absolute bottom-0 md:top-0 z-50 md:z-0 inset-x-0 md:relative overflow-y-auto`}>
      {!selectedData && (
        <div className="flex flex-col justify-center h-full">
          <p className="text-dark-blue-900 text-center text-sm">
            Busca o selecciona una empresa para obtener más información.
          </p>
        </div>
      )}
      {selectedData && (
        <>
          <div className="relative w-full h-auto flex flex-col gap-y-4">
            {/**
         * {selectedData.name &&  (
            <h1 className="text-3xl md:text-4xl font-semibold text-dark-blue-900 ">{selectedData.name}</h1>
        )}
         */}
            {selectedData.name && (
              <div className="flex justify-between items-center">
                <h1 className="text-3xl md:text-4xl font-semibold text-dark-blue-900 ">
                  {selectedData.name}
                </h1>
                {selectedData.logo && (
                  <div className="ml-4 w-16 h-16 md:w-24 md:h-24 rounded-full overflow-hidden border border-gray-200 shadow-sm">
                    <img
                      src={selectedData.logo}
                      alt="Company Logo"
                      className="object-cover w-full h-full"
                    />
                  </div>
                )}
              </div>
            )}
            {/* <button className="absolute top-0 right-0 md:hidden text-xl">
              <Icon icon="mdi:close" />
            </button> */}
            <div className="mb-4 w-full">
              <h6>Ubicación</h6>
              <ComparisonTableCompaniesLocation data={selectedData} />
            </div>
            <div className="mb-4 w-full">
              <h6>Contacto</h6>
              <ComparisonTableCompaniesContact data={selectedData} />
            </div>
            <div className="mb-4 w-full">
              <h6>Detalles identicativos</h6>
              <ComparisonTableProducers data={selectedData} />
            </div>
            <div>
              <h6>Certificados</h6>
              <ComparisonTableCompaniesCertifications
                data={selectedData.certifications}
              />
            </div>
            {/**
           * <div className="mb-4 w-full">
           <h6>KPI</h6>
           <ComparisonTableProducersKpi data={selectedData}/>
           </div>   
          */}
          </div>
        </>
      )}
      {/* <div className="flex justify-end mt-auto gap-x-2">
        <button ref={editCompanyRef} type="submit" className={`text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded bg-dark-blue-900 hover:bg-dark-blue-700`} onClick={handleEditCompany} >
          <Icon icon="material-symbols:edit" className="w- 4 mr-2" />
          <span>Editar</span>
        </button>
        <button ref={delCompanyRef} type="submit" className={`text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded ${selectedRowData ? "bg-red-800 hover:bg-red-500" : "cursor-not-allowed bg-red-800/50"}`} onClick={onDeleteButtonClick}>
          <Icon icon="mdi:trash-outline" className="w- 4 mr-2" />
          <span>Borrar</span>
        </button>
      </div>       */}
    </div>
  );
};

const Companies = () => {
  const [divHeight, setDivHeight] = useState("calc(100vh)"); // Initial height set to 100vh
  const [filteredData, setFilteredData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [showEditCompany, setShowEditCompany] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const addCompanyComponentRef = useRef(null);
  const editCompanyComponentRef = useRef(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  const handleRefresh = () => {
    console.log("handleRefresh called in Companies");
    setRefreshData(true); // Trigger a refresh
  };

  useEffect(() => {
    const setDivHeightFunc = () => {
      // Assuming your navbar has an id of 'navbar'
      //Apparently this doesn't work. Findout the name of the navbar.
      const navbar = document.getElementById("Navbar");
      if (navbar) {
        const navbarHeight = navbar.offsetHeight;
        const newDivHeight = window.innerHeight - navbarHeight;
        setDivHeight(`${newDivHeight}px`);
      }
    };

    setDivHeightFunc(); // Set initial height
    window.addEventListener("resize", setDivHeightFunc); // Update on resize

    return () => window.removeEventListener("resize", setDivHeightFunc); // Cleanup
  }, []); // Empty dependency array means this useEffect runs once on mount and cleanup on unmount

  const handleSearch = (data) => {
    setFilteredData(data);
  };

  const handleRowSelect = (data) => {
    console.log("S: companies row selected:", data);
    setSelectedRowData(data);
  };

  const handleAddCompany = (companyName) => {
    // Logic to add the company
    console.log("Adding company:", companyName);
  };

  /*const handleEditCompany = (companyName) => {
    // Logic to add the company
    console.log("Editing company:", companyName);
    if (selectedRowData) {
      setShowEditCompany(true);    
      setWarningMessage(''); // Clear any existing warning message  
    } else {
      setWarningMessage('Para editar una empresa antes ha de seleccionar una.');
      console.log('Warning Message set:', warningMessage);
    }
  };*/

  //DELETE COMPANY POPUP CONFIRMATION COMPONENT
  const handleDelCompany = async () => {
    if (!selectedRowData) {
      setWarningMessage("Por favor, seleccione una empresa para borrar.");
      return;
    }

    try {
      // Call the serverless function to delete the selected company
      const response = await fetch(
        `/api/delTrustyData?category=companies&uuid=${selectedRowData.uuid}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseBody = await response.json();
      if (response.ok) {
        setSuccessMessage("Empresa eliminada con éxito."); //CAMBIAR COLOR O USAR SUCCESS
        setTimeout(() => {
          setRefreshData(true); // Refresh the data to reflect the deletion
          setShowConfirmationDialog(false);
        }, 2000);
      } else {
        console.error("Error eliminando empresa:", responseBody.message);
        setWarningMessage(
          `Error al eliminar la empresa: ${responseBody.message}`
        );
      }
    } catch (error) {
      console.error("Request error:", error);
      setWarningMessage("Error conectando con el servidor");
    }
  }; //End handleDelCompany

  //Function to handle the delete button click (show confirmation dialog)
  const handleDeleteButtonClick = () => {
    if (!selectedRowData) {
      setWarningMessage("Por favor, seleccione una empresa para borrar.");
      return;
    }
    setShowConfirmationDialog(true);
  };

  //Function to handle the confirmation of deletion
  /*const handleConfirmDeletion = () => {
    handleDelCompany(); // Your existing delete function
    setShowConfirmationDialog(false);
  };*/

  //Function to handle cancellation of deletion
  const handleCancelDeletion = () => {
    setShowConfirmationDialog(false);
  };

  //The confirmation dialog component
  const ConfirmationDialog = () => {
    return (
      <div className="bg-white font-sans flex flex-col rounded-t-xl py-8 px-4 md:px-8 w-full gap-y-6 ">
        <p>¿Está seguro de que desea borrar la empresa seleccionada?</p>
        {successMessage && (
          <p className="text-xs text-green-500">{successMessage}</p>
        )}
        {warningMessage && (
          <p className="text-xs text-red-500">{warningMessage}</p>
        )}
        <div className="flex justify-between gap-x-2 mt-2">
          <button
            className="flex justify-center bg-dark-green-600 hover:bg-dark-green-500 text-white px-4 py-2 rounded-md w-full"
            onClick={handleDelCompany}>
            Aceptar
          </button>
          <button
            className="flex justify-center bg-red-700 hover:bg-red-500 text-white px-4 py-2 rounded-md w-full"
            onClick={handleCancelDeletion}>
            Cancelar
          </button>
        </div>
        {/* <div className="flex justify-between mr-4 my-2">
          <button className="flex justify-center bg-blue-500 text-white px-4 py-2 mx-2 rounded-md w-full" onClick={handleDelCompany}>Aceptar</button>
          <button className="flex justify-center bg-blue-500 text-white px-4 py-2 mx-2 rounded-md w-full" onClick={handleCancelDeletion}>Cancelar</button>
        </div>
        {successMessage && <p className="text-green-500">{successMessage}</p>}
        {warningMessage && <p className="text-red-500">{warningMessage}</p>} */}
      </div>
    );
  };

  return (
    <div className="flex relative flex-col w-full md:overflow-clip h-full">
      <div className="flex h-[calc(100svh_-_99px)] flex-col md:flex-row">
        <LeftContent
          onSearch={handleSearch}
          showTable={showTable}
          setShowTable={setShowTable}
          onRowSelect={handleRowSelect}
          selectedRowData={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          onAddCompanyClick={() => setShowAddCompany(true)}
          //onEditCompanyClick={() => setShowEditCompany(true)}
          setShowEditCompany={setShowEditCompany}
          showEditCompany={showEditCompany}
          addCompanyComponentRef={addCompanyComponentRef}
          editCompanyComponentRef={editCompanyComponentRef}
          onDeleteButtonClick={handleDeleteButtonClick}
          warningMessage={warningMessage}
          setWarningMessage={setWarningMessage}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
        />{" "}
      {/**Pass setRefreshData to LeftContent */}
      <button onClick={() => setSelectedRowData(null) } className={`${(selectedRowData) ? 'opacity-100 md:hidden' : 'opacity-0 pointer-events-none'} cursor-default transition-opacity absolute  inset-0 bg-black/30  z-40`}></button>
        <RightContent selectedData={selectedRowData} />
      {/* <button onClick={() => {setShowAddCompany(false); setShowEditCompany(false); handleCancelDeletion() }} className={`${(showAddCompany || showEditCompany || showConfirmationDialog) ? 'opacity-100' : 'opacity-0 pointer-events-none'} cursor-default transition-opacity absolute  inset-0 bg-black/30  z-40`}></button> */}
      {/* <div
        ref={addCompanyComponentRef}
        className={`${
          showAddCompany
            ? "translate-y-0"
            : "translate-y-full pointer-events-none select-none"
        } duration-300 absolute bottom-0 left-0 w-full md:w-1/2 z-[70] shadow-xl`}>
        <AddCompany
          onClose={() => setShowAddCompany(false)}
          onAddCompany={handleAddCompany}
          onRefresh={handleRefresh} // Pass handleRefresh to AddCompany
        />
      </div> */}
      {/* <div
        ref={editCompanyComponentRef}
        className={`${
          showEditCompany
            ? "translate-y-0"
            : "translate-y-full pointer-events-none select-none"
        } duration-300 absolute bottom-0 left-0 w-full md:w-1/2 z-[70] shadow-xl`}>
        <EditCompany
          onClose={() => setShowEditCompany(false)}
          //onEditCompany={handleEditCompany}
          //onEditCompanyClick={handleEditCompany}
          onRefresh={handleRefresh} // Pass handleRefresh to EditCompany
          data={selectedRowData}
        />
      </div> */}
      {/* <div
        className={`${
          showConfirmationDialog
            ? "translate-y-0"
            : "translate-y-full pointer-events-none select-none"
        } duration-300 absolute bottom-0 left-0 w-full md:w-1/2 z-[70] shadow-xl`}>
        <ConfirmationDialog
          onConfirm={handleDelCompany}
          onCancel={handleCancelDeletion}
        />
      </div> */}
      </div>
      <FinanceSection />
    </div>
  );
};

export default Companies;
