import React, { useState, useEffect, useMemo, useRef } from 'react'
import BasicTable from '../tables/BasicTable';
import TableProducts from '../tables/TableProducts';
import TableProductsSelectRow from '../tables/TableProductsSelectRow';
import TableProductsSelectRowHigh from '../tables/TableProductsSelectRow';
import ComparisonTableProducts from '../tables/ComparisonTableProducts';
import ComparisonTableProductsTraceability from '../tables/ComparisonTableProductsTraceability';
import ComparisonTableProductsEvents from '../tables/ComparisonTableProductsEvents';
import {Tooltip}  from 'react-tooltip';
import { Icon } from '@iconify/react';
import { useStateContext } from '../contexts/ContextProvider';
import { AddProduct } from '../components';
import { EditProduct} from '../components';
import FinanceSection from '../components/FinanceSection';

const LeftContent = ({ showTable, setShowTable, onSearch, onRowSelect, selectedRowData, setSelectedRowData, onAddProductClick, refreshData, setRefreshData, setShowEditProduct, addProductComponentRef, editProductComponentRef, onDeleteButtonClick }) => {
  const [input, setInput] = useState("");
  const [storedItems, setStoredItems] = useState([]);
  const productosJSON= '/data/productos.json';
  const [error, setError] = useState(null);
  const [showTooltipInput, setShowTooltipInput] = useState(false);
  const [showTooltipTable, setShowTooltipTable] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]); //Store only the selected items
  //const productoresJSON= '/data/productores.json'
  //
  const [responseMessage, setResponseMessage] = useState('');
  const [noDataFoundMessage, setNoDataFoundMessage] = useState('');
  const [warningMessageLeft, setWarningMessageLeft] = useState('');
  //
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [createdAt, setCreatedAt] = useState('');  
  const [gtin, setGtin] = useState('');  
  const [company, setCompany] = useState('');  
  const [articleCode, setArticleCode] = useState('');
  //
  const [isLoading, setIsLoading] = useState(false);
  //
  const { products, setProducts, companies, setCompanies, locations } = useStateContext([]);     /**From contextProvider */
  //
  const timerRef = useRef(null);
  const tableRef = useRef(null);
  const addProductRef = useRef(null);
  const editProductRef = useRef(null);
  
  //Get company name given its id
  const getCompanyNameFromId = (companyId) => {
  
    if (!companyId) {
      console.log("Products leftContent no companyId");
      return 'No companyId'
    }

    // Find the company in the companies array
    const company = companies.find(c => c.resourceId === companyId);
    if (!company) {
      console.log('No matching company name for companyId', companyId);
    }
    // Return the company name or a default string if not found
    return company ? company.name : companyId;
  }; 

  const fetchData = async (category) => {
    setIsLoading(true); // Start loading
    setError(null); // Reset previous errors
    try {
      // Call the serverless function here
      const response = await fetch(`/api/getTrustyData?category=${category}`, {
        method: 'GET'
      });
      console.log("Productos response", response);
      const retrievedJSON = await response.json();
      if (response.ok) {
        const formattedData = retrievedJSON.data.map(item => ({
          ...item,
          date: new Date(item.date).toLocaleDateString('en-GB'), // Formats to 'DD/MM/YYYY'
          companyId: getCompanyNameFromId(item.companyId),
        }));
        setStoredItems(formattedData);
        setFilteredItems(formattedData);       
        setShowTable(retrievedJSON.data.length > 0); //setShowTable(true); 
        setProducts(formattedData); // Update context with fetched data. Data used in Events page
        console.log('Productos data received:', retrievedJSON);
      } else {
        console.error('Error recibiendo datos de Productos:', retrievedJSON.message); //Reads the message sent from the "backend"
        setError('No se pudieron recibir datos del servidor'); //Reads the message sent from the "backend"
      }
      
    } catch (error) {
      console.error('Request error:', error);
      setResponseMessage('Error conectando con el servidor');
    }
    setIsLoading(false); // End loading
  } //End fetchData

  useEffect(() => {
    fetchData('products');
  }, []);

  //Watches the input state and If it's not empty, reset the selectedRowData to null
  useEffect(() => {
    // Reset selected row data when input changes
    if (input.trim() !== "") {
        setSelectedRowData(null);
    }
  }, [input, setSelectedRowData]);

  //Triggers fetchData if AddCompany completed succesfully
  useEffect(() => {
    if (refreshData) {
      //console.log("Products useEffect Refreshing data");
      fetchData('products');
      setRefreshData(false); // Reset the refresh trigger
    }
  }, [refreshData, setRefreshData]);

  const handleSearch = () => {
    if (input.trim() !== "") {
      const filteredData = storedItems.filter(item =>
          item.name.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredItems(filteredData);
      setShowTable(filteredData.length > 0);

      // Set message if no data is found
      if (filteredData.length === 0) {
          setNoDataFoundMessage('No se encontraron empresas con ese nombre.');
      } else {
          setNoDataFoundMessage('');
      }
    }
    
    /*if (input.trim() === "") {
      setFilteredItems(storedItems);
      setShowTable(true);
    } else {
      const filteredData = storedItems.filter(item => 
        item.name.toLowerCase().includes(input.toLowerCase()) 
      );
      console.log("Producers filteredData", filteredData);     

    setFilteredItems(filteredData);
    setShowTable(filteredData.length > 0);
    }*/
    
  };  

  const handleRowSelect = (rowData) => {
    onRowSelect(rowData);
    setWarningMessageLeft('');
  };

  const columns = useMemo(() => [
    { Header: "Producto", accessor: "name" },
    { Header: "Estado", accessor: "status" },
    { Header: "Fecha", accessor: "date" }
  ], []);


  const handleInputChange = (e) => {
    const value = e.target.value;
    // Regular expression to allow only numbers, letters, accents, and spaces
    if (/^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 ]*$/.test(value)) {
        setInput(value);
        if (value.trim() === "") {
            setFilteredItems(storedItems); 
            setShowTable(storedItems.length > 0);
            setSelectedRowData(null); // Reset selected row data
            setNoDataFoundMessage(''); // Reset no data found message
        }
    }
    //handleSearch();
    setShowTooltipInput(true);
  };  

  const handleMouseEnter = (element) => {
    if (element === 'input') {
      setShowTooltipInput(true); // Show tooltip for input
    } else if (element === 'table') {
      setShowTooltipTable(true); // Show tooltip for table
    }

    timerRef.current = setTimeout(() => {
      if (element === 'input') {
        setShowTooltipInput(false); // Hide tooltip for input
      } else if (element === 'table') {
        setShowTooltipTable(false); // Hide tooltip for table
      }
    }, 2000);
  }

  const onMouseOut = (element) => {
    if (element === 'input') {
      setShowTooltipInput(false); // Hide tooltip for input
    } else if (element === 'table') {
      setShowTooltipTable(false); // Hide tooltip for table
    }
    clearTimeout(timerRef.current);
  }

  //Clears out the data in the RightContent
  //addCompanyRef and editCompanyRef are refs to the buttons añadir and edit company so that they are left out of this method
  /*const handleClickOutside = (event) => {
    //console.log("Products leftContent Clicked outside the table");
    if (tableRef.current && !tableRef.current.contains(event.target) &&
      (!addProductRef.current || !addProductRef.current.contains(event.target)) &&
      (!editProductRef.current || !editProductRef.current.contains(event.target)) &&
      (!addProductComponentRef.current || !addProductComponentRef.current.contains(event.target)) &&
      (!editProductComponentRef.current || !editProductComponentRef.current.contains(event.target))) {
      setSelectedRowData(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);*/

  //
  const handleEditProduct = () => {
    if (selectedRowData) {
      setShowEditProduct(true); // Open the EditCompany component
      setWarningMessageLeft(''); // Clear any existing warning message
    } else {
      setWarningMessageLeft('Para editar una empresa antes ha de seleccionar una.');
      //console.log("Companies lefTContent editar empresaia button clicked");
    }
    /**if (!selectedRowData) {
      setWarningMessage('Para editar una empresa antes ha de seleccionar una.');
      console.log("Companies lefTContent editar empresaia button clicked");
    }**/
  };

  //console.log("storedItems", storedItems);
  return (
    <div className="h-[100svh] md:h-full flex gap-y-4 flex-col p-4 md:p-6 pt-14 md:pt-10 md:border-r border-white w-full md:w-1/2 relative">
      <h1 className="text-3xl lg:text-3xl font-semibold text-dark-blue-600">Productos</h1>
      <p className="text-sm text-dark-blue-900">Busca por producto para obtener más información</p>
      <div className="flex">
        {/* ! TODO REVISA SI OPINAS QUE ESTO ESTA BIEN CON EL FAKE USER PARA SAFARI */}
      <input id="fake_user_name" name="fake_user[name]" className="-top-[100px] absolute opacity-0" type="text" value="Safari Autofill Me"/>
        <input 
          className="rounded p-2 flex-grow mr-2 focus:outline-primary-200 focus:outline-none focus:ring-0" 
          type="search"
          autoComplete="new-off"
          placeholder="Escribe el nombre de un producto..." 
          value={input} 
          onChange={handleInputChange} 
          onKeyUp={e => e.key === 'Enter' && handleSearch()}
          data-tooltip-id="inputProductos"
          data-tooltip-delay-show={500}
          onMouseEnter={() => handleMouseEnter('input')}
          onMouseOut={() => onMouseOut('input')}
          data-tooltip-content="Escribe el nombre de un producto." 
        />
        <button className="bg-dark-blue-900 hover:bg-dark-blue-700 h-full flex justify-center items-center aspect-square text-light-green-100 p-2 rounded" onClick={handleSearch}>
        <Icon icon="material-symbols:search" className='h-5 w-5' /></button>
        <Tooltip className="custom-tooltip" id="inputProductos"  place="bottom"  type="dark"  effect="solid" isOpen={showTooltipInput} />
      </div>
      {noDataFoundMessage && (
                <p className="my-4 text-red-600">{noDataFoundMessage}</p>
      )}
      {isLoading && 
        <div className="h-full w-full flex justify-center items-center">
          <p className="text-sm text-dark-blue-900">Cargando...</p>
        </div>
      }
      {error && (
        <p className="my-4 text-red-600">
        {error}
        </p>
      )}
      {/**Shows the table depending on the category selected on the leftContent panel and if the state showTable is active*/}
      
      {/**Similar than the previous but for the compareButton. This also calls the method that opens the Compare window */}
      {!isLoading && showTable && (
        <div class="rounded-md h-full overflow-clip relative">
          <div 
            ref={tableRef}
            data-tooltip-id="tableProductos" 
            data-tooltip-content="Seleccciona a un producto de la tabla para ver más información." 
            className="h-full  shrink-1 grow-1 overflow-y-auto table-container" 
            onMouseEnter={() => handleMouseEnter('table')}
            onMouseOut={() => onMouseOut('table')} 
          >
            <TableProductsSelectRowHigh 
              data={filteredItems} 
              columns={columns} 
              onRowSelect={handleRowSelect} 
              isOpen={showTooltipTable}
            />
          </div>                        
          <Tooltip className="custom-tooltip" id="tableProductos" place="top" type="dark" effect="solid" isOpen={showTooltipTable}/>         
        </div>           
      )}  
      {warningMessageLeft && <p className="text-red-500">{warningMessageLeft}</p>} 
      {/**
       * <div className="flex justify-end mt-auto gap-x-2 z-[51]">
        <button ref={addProductRef} type="submit" className="bg-dark-green-700 hover:bg-dark-green-600 text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded" onClick={onAddProductClick}>
        <Icon icon="eos-icons:content-new" className="w- 4 mr-2" />
        <span>Añadir</span>
        </button>
        <button ref={editProductRef} type="submit" className={`text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded ${selectedRowData ? "bg-dark-blue-900 hover:bg-dark-blue-700" : "cursor-not-allowed bg-dark-blue-900/50"}`} onClick={handleEditProduct} >
        <Icon icon="material-symbols:edit" className="w- 4 mr-2" />
        <span>Editar</span>
        </button> //disabled={!selectedRowData}  
        <button type="submit" className={`text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded ${selectedRowData ? "bg-red-800 hover:bg-red-500" : "cursor-not-allowed bg-red-800/50"}`} onClick={onDeleteButtonClick}>
        <Icon icon="mdi:trash-outline" className="w- 4 mr-2" />
        <span>Borrar</span>
        </button>
      </div>
       */} 
      
    </div>
  );
};

const RightContent = ({selectedData, products, locations}) => {

  /*const columns = useMemo(() => [
    { Header: "Lote", accessor: "lote" },
    { Header: "Origen", accessor: "origen" },
    { Header: "Cantidad", accessor: "cantidad" },
    { Header: "Comprado Por", accessor: "compradoPor" },
    { Header: "Fecha de Compra", accessor: "fechaCompra" }
  ], []);*/
  //console.log("RightContent selectedData",selectedData);

  /**
   * {!selectedData &
        <div className="flex flex-col items-center justify-center pt-8 px-8 w-2/3 relative"> 
          <div className="flex flex-col justify-center h-full">
            <p className="text-[#005571]">Busca un producto para obtener más información.</p>
          </div>
        </div>
      }
      {selectedData && (
        <>
          <div className="flex flex-col items-start justify-start pt-8 px-8 w-2/3 relative">
            <h2 className="text-xl font-semibold text-[#005571] mt-8 mb-4">{selectedData.producto}</h2>
            <ComparisonTableProducts data={selectedData}/>
          </div>
        </>
      )}
   */

    //Get product name by UUID for showing in Traceability in the rightContent
    const getProductNameByUUID = (productId) => {
      console.log("Products rightContent getProductNameByUUID Products:", products);
      const product = products.find(p => p.uuidCore === productId);
      console.log("Products rightContent getProductNameByUUID Found Product:", product);
      return product ? product.name : 'Unknown Product';
    };

    //Get location name by UUID for showing in Traceability in the rightContent
    const getLocationNameByUUID = (locationId) => {
      console.log("Products rightContent getLocationNameByUUID Locations:", locations);
      const location = locations.find(l => l.uuidCore === locationId);
      console.log("Products rightContent getLocationNameByUUID Found Location:", location);
      return location ? location.name : 'Unknown Location';
    }; 

    console.log('Products rightContent selectedData:', selectedData);
    console.log('Products rightContent selectedData.traceability:', selectedData?.traceability);
    selectedData?.traceability?.forEach(trace => {
      console.log('trace.inputs:', trace.inputs);
    });

    const enrichedTraceability = selectedData?.traceability?.map(trace => ({
      ...trace,
      location: getLocationNameByUUID(trace.location),
      inputs: (trace.inputs || []).map(input => ({
        ...input,
        productId: getProductNameByUUID(input.productId)
      }))
    })) || [];

  return (
    
    <div className={`flex flex-col gap-y-4 ${selectedData ? ' items-start justify-start ' : 'translate-y-full md:translate-y-0 items-center justify-center'} transition-transform h-[75svh] shadow-lg md:shadow-none bg-light-green-100 md:h-full w-full md:w-1/2 pt-10 p-4 md:p-6 absolute bottom-0 md:top-0 z-50 md:z-0 inset-x-0 md:relative overflow-y-auto`}>
      {!selectedData && (
        <div className="flex flex-col justify-center h-full">
          <p className=" text-dark-blue-900 text-center text-sm">Busca o selecciona un producto para obtener más información.</p>
        </div>
      )}
      {selectedData && (
          <div className="relative w-full h-auto flex flex-col gap-y-4">

        {/**
         * {selectedData.name &&  (
            <h1 className="text-3xl md:text-4xl font-semibold text-dark-blue-900 ">{selectedData.name}</h1>
        )}
         */}
        {selectedData.name && (
          <div className="flex justify-between items-center">
            <h1 className="text-3xl md:text-4xl font-semibold text-dark-blue-900 ">
              {selectedData.name}
            </h1>
            {selectedData.profileImage && (
              <div className="ml-4 w-16 h-16 md:w-24 md:h-24 rounded-full overflow-hidden border border-gray-200 shadow-sm">
                <img src={selectedData.profileImage} alt="Company Logo" className="object-cover w-full h-full" />
              </div>
            )}
          </div>
        )}
          <div className="mb-4 w-full">
            <h6 >Detalles identicativos</h6>
            <ComparisonTableProducts data={selectedData}/>
          </div>
          <div className="mb-4 w-full">
            <h6 >Trazabilidad</h6>
            {/**{selectedData?.traceability?.map((trace, index) => ( */}
            {enrichedTraceability.map((trace, index) => (
                <div key={index} className="trace-item mb-4">
                  {/**Display the name and image of each phase in traceability */}
                    {/* <div className="flex justify-between mb-2 items-center">
                        <h6 className="text-sm">{trace.name}</h6>
                        {trace.media?.[0]?.mediaCropped && (
                            <img
                                src={trace.media[0].mediaCropped}
                                alt={`Traceability Media ${index + 1}`}
                                className="w-16 h-16 object-cover" // Adjust size as needed
                            />
                        )}
                    </div> */}
                  {/**Table with the rest of the data*/}
                  {/**<TraceabilityDetailsTable data={trace} /> */}
                  <ComparisonTableProductsTraceability data={trace}/>
                </div>
            ))}       

          </div>
          {/**
           * <div className='w-full'>
            <h6 >EVENTOS</h6>
            <ComparisonTableProductsEvents data={selectedData}/>
          </div>
           */}          
          </div>
      )}            
    </div>
  );
};

const Products = () => {
  //const harvestData= '/data/harvestData.json'
  const [divHeight, setDivHeight] = useState('calc(100vh - 65px)'); // Initial height set to 100vh
  const [filteredData, setFilteredData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const addProductComponentRef = useRef(null);
  const editProductComponentRef = useRef(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const { products, setProducts, companies, setCompanies, locations } = useStateContext([]);     /**From contextProvider */

  const handleRefresh = () => {
    //console.log("handleRefresh called in Companies");
    setRefreshData(true); // Trigger a refresh
  };

  useEffect(() => {
    const setDivHeightFunc = () => {
      // Assuming your navbar has an id of 'navbar'
      //Apparently this doesn't work. Findout the name of the navbar.
      const navbar = document.getElementById('Navbar'); 
      if (navbar) {
        const navbarHeight = navbar.offsetHeight;
        const newDivHeight = window.innerHeight - navbarHeight;
        setDivHeight(`${newDivHeight}px`);
      }
    };

    setDivHeightFunc(); // Set initial height
    window.addEventListener('resize', setDivHeightFunc); // Update on resize

    return () => window.removeEventListener('resize', setDivHeightFunc); // Cleanup
  }, []); // Empty dependency array means this useEffect runs once on mount and cleanup on unmount

  const handleSearch = (data) => {
    setFilteredData(data);
  };

  const handleRowSelect = (data) => {
    //console.log("Products row selected:", data);
    setSelectedRowData(data);
  };

  const handleAddProduct = (productName) => {
    // Logic to add the company
    //console.log("Adding facility:", productName);
    // You might want to fetch or update your companies list here
  };

  //DELETE COMPANY POPUP CONFIRMATION COMPONENT
  const handleDelCompany = async () => {
    if (!selectedRowData) {
      setWarningMessage('Por favor, seleccione una empresa para borrar.');
      return;
    }
  
    try {
      // Call the serverless function to delete the selected company
      const response = await fetch(`/api/delTrustyData?category=products&uuid=${selectedRowData.uuid}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      const responseBody = await response.json();
      if (response.ok) {
        setSuccessMessage('Empresa eliminada con éxito.'); //CAMBIAR COLOR O USAR SUCCESS
        setTimeout(() => {
          setRefreshData(true); // Refresh the data to reflect the deletion
          setShowConfirmationDialog(false);
      }, 2000);
      } else {
        console.error('Error eliminando empresa:', responseBody.message);
        setWarningMessage(`Error al eliminar la empresa: ${responseBody.message}`);
      }
    } catch (error) {
      console.error('Request error:', error);
      setWarningMessage('Error conectando con el servidor');
    }
  } //End handleDelCompany

  //Function to handle the delete button click (show confirmation dialog)
  const handleDeleteButtonClick = () => {
    if (!selectedRowData) {
      setWarningMessage('Por favor, seleccione una empresa para borrar.');
      return;
    }
    setShowConfirmationDialog(true);
  };

  //Function to handle the confirmation of deletion
  /*const handleConfirmDeletion = () => {
    handleDelCompany(); // Your existing delete function
    setShowConfirmationDialog(false);
  };*/

  //Function to handle cancellation of deletion
  const handleCancelDeletion = () => {
    setShowConfirmationDialog(false);
  };

  //The confirmation dialog component
  const ConfirmationDialog = () => {
    return (
      <div className="bg-white font-sans flex flex-col rounded-t-xl py-8 px-4 md:px-8 w-full gap-y-6 ">
        <p>¿Está seguro de que desea borrar la empresa seleccionada?</p>
        {successMessage && <p className="text-xs text-green-500">{successMessage}</p>}
          {warningMessage && <p className="text-xs text-red-500">{warningMessage}</p>}                                      
          <div className="flex justify-between gap-x-2 mt-2">
              <button className="flex justify-center bg-dark-green-600 hover:bg-dark-green-500 text-white px-4 py-2 rounded-md w-full" onClick={handleDelCompany}>Aceptar</button>
              <button className="flex justify-center bg-red-700 hover:bg-red-500 text-white px-4 py-2 rounded-md w-full" onClick={handleCancelDeletion}>Cancelar</button>
          </div>     
        {/* <div className="flex justify-between mr-4 my-2">
          <button className="flex justify-center bg-blue-500 text-white px-4 py-2 mx-2 rounded-md w-full" onClick={handleDelCompany}>Aceptar</button>
          <button className="flex justify-center bg-blue-500 text-white px-4 py-2 mx-2 rounded-md w-full" onClick={handleCancelDeletion}>Cancelar</button>
        </div>
        {successMessage && <p className="text-green-500">{successMessage}</p>}
        {warningMessage && <p className="text-red-500">{warningMessage}</p>} */}
      </div>
    );
  };
  
  //<BasicTable jsonFile={harvestData} columns={COLUMNS}/>
  return (
    /**
     * react: rounded-lg and overflow-hidden = rounded borders to the table 
     * css: border-radius: 10px and overflow:hidden
     * overflow-x-auto: give table scroll in the x or y axis if overflown*/
    <div className="flex relative flex-col w-full md:overflow-clip h-full">
      <div className="flex h-[calc(100svh_-_99px)] flex-col md:flex-row">
      <LeftContent 
        onSearch={handleSearch} 
        showTable={showTable} 
        setShowTable={setShowTable} 
        onRowSelect={handleRowSelect}
        selectedRowData={selectedRowData}
        setSelectedRowData={setSelectedRowData}
        onAddProductClick={() => setShowAddProduct(true)}
        setShowEditProduct={setShowEditProduct}
        showEditProduct={showEditProduct}
        addProductComponentRef={addProductComponentRef}
        editProductComponentRef={editProductComponentRef}
        onDeleteButtonClick={handleDeleteButtonClick}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />

      <button onClick={() => setSelectedRowData(null) } className={`${(selectedRowData) ? 'opacity-100 md:hidden' : 'opacity-0 pointer-events-none'} cursor-default transition-opacity absolute  inset-0 bg-black/30  z-40`}></button>

      <RightContent selectedData={selectedRowData} products={products} locations={locations}/>
      
      {/* <button onClick={() => {setShowAddProduct(false); setShowEditProduct(false); handleCancelDeletion() }} className={`${(showAddProduct || showEditProduct) ? 'opacity-100' : 'opacity-0 pointer-events-none'} cursor-default transition-opacity absolute  inset-0 bg-black/30 z-40`}></button> */}

      {/* <div ref={addProductComponentRef} className={`${(showAddProduct) ? 'translate-y-0' : 'translate-y-full pointer-events-none select-none'} duration-300 absolute bottom-0 left-0 w-full md:w-1/2 z-[70] shadow-xl`}>
        <AddProduct 
          onClose={() => setShowAddProduct(false)} 
          onAddProduct={handleAddProduct}      
          onRefresh={handleRefresh} // Pass handleRefresh to AddCompany                  
        />
      </div> */}
      
        {/* <div ref={editProductComponentRef} className={`${(showEditProduct) ? 'translate-y-0' : 'translate-y-full pointer-events-none select-none'} duration-300 absolute bottom-0 left-0 w-full md:w-1/2 z-[70] shadow-xl`}>
          <EditProduct 
            onClose={() => setShowEditProduct(false)} 
            //onEditCompany={handleEditCompany}  
            //onEditCompanyClick={handleEditCompany}
            onRefresh={handleRefresh} // Pass handleRefresh to EditCompany           
            data={selectedRowData}
          />
        </div> */}
        {/* <div className={`${(showConfirmationDialog) ? 'translate-y-0' : 'translate-y-full pointer-events-none select-none'} duration-300 absolute bottom-0 left-0 w-full md:w-1/2 z-[70] shadow-xl`}>
          <ConfirmationDialog 
              onConfirm={handleDelCompany}
              onCancel={handleCancelDeletion}
          />
        </div> */}
      {/* )} */}
      </div>
      <FinanceSection />
    </div>
  )
}

export default Products