// src/components/ChartBar.jsx

import React, { useState, useEffect }  from 'react';
import { Bar } from 'react-chartjs-2';
import ReactECharts from 'echarts-for-react';

const ChartBarTransport = ({ selectedData  }) => {
  const [chartData, setChartData] = useState({ categories: [], values: [] });
  //console.log("Chartbar props.data", selectedData);
  //console.log("Chartbar props.data consumo agua", selectedData.kpi);
  
  useEffect(() => {
    // Process the selectedData to extract chart information
    const categories = [];
    const values = [];

    selectedData.forEach(item => {
      categories.push(item.nombre); // Using 'nombre' as category
      values.push(item.emisionCO2); // Using 'consumo_agua' as value
    });

    setChartData({ categories, values });
  }, [selectedData]);

  /**
   * series: [
      {
        data: chartData.values,//[120, 200, 150, 80, 70],
        type: 'bar',
        barWidth: '40%', // Set the width of the bars
        itemStyle: {
          color: '#74c475', // Set the color of the bars
        },
      },
    ],
   */
  const options = {
    title: {
      text: 'Emisiones de CO2',
    },
    xAxis: {
      type: 'category',
      data: chartData.categories,//['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
        {
          data: chartData.values.map((value, index) => {
            return {
              value: value,
              label: {
                show: true,
                position: 'top',
                formatter: '{c} kg', // Aquí configuras el formato de la etiqueta
              },
            };
          }),
          type: 'bar',
          barWidth: '40%',
          itemStyle: {
            color: '#74c475',
          },
        },
      ],
  };

    return (
        <div className="flex-1 pt-4 py-2 pb-2">
            <ReactECharts option={options} />
        </div>
    );
};

export default ChartBarTransport;
