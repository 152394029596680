import React from 'react';

const ComparisonTableCompaniesLocation = (props) => {
    const {data} = props;

    //console.log("ComparisonTableProducers selectedData", data);
    //console.log("ComparisonTableProducers selectedData typeof", typeof data);

    if (!data) {
        return <p>No data available</p>;
    }

    return (
        <table className='w-full standard-table'>
            <tbody>
                <tr>
                    <td>Calle</td>
                    <td>{data ? data.street : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Código Postal</td>
                    <td>{data ? data.postalCode : 'N/A'}</td>
                </tr> 
                <tr>
                    <td>Ciudad</td>
                    <td>{data ? data.city : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Provincia</td>
                    <td>{data ? data.state : 'N/A'}</td>
                </tr>    
                <tr>
                    <td>País</td>
                    <td>{data ? data.country : 'N/A'}</td>
                </tr>             
            </tbody>
        </table>
    );
};

export default ComparisonTableCompaniesLocation;
