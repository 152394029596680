import React, { useState } from 'react';
import { Icon } from '@iconify/react';



const Calculator = ({avocadoData, setAvocadoData}) => {
  
  const [errors, setErrors] = useState({
    numArboles: '',
    areaCultivo: '',
    aguaIrrigacion: '',
    typeFertilizantes: '',
    cantidadFertilizantes: '',
    typePesticidas: '',
    cantidadPesticidas: '',
    energiaConsumida: '',
    tipoEnergia: '',
    materialEmpaque: '',
    materialEmpaqueCantidad: '',
  });
  // let newErrors = {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAvocadoData({ ...avocadoData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform calculations or other operations with the avocadoData state
    // You can write your logic here to calculate the carbon footprint
    console.log('Avocado Data:', avocadoData);
    // Reset the form after submission if needed

    const {
      numArboles,
      numAguacates,
      areaCultivo,
      aguaIrrigacion,
      fertilizantes,
      pesticidas,
      energiaConsumida,
      tipoEnergia,
      materialEmpaqueCantidad,
    } = avocadoData;

    const rendimientoPorArbol = numAguacates / numArboles;
  const rendimientoPorArea = numAguacates / areaCultivo;
  const densidadArbolesArea = numArboles / areaCultivo;

    console.log('Rendimiento por árbol:', rendimientoPorArbol);
  console.log('Rendimiento por área:', rendimientoPorArea);
  console.log('Densidad de árboles por área:', densidadArbolesArea);
    setAvocadoData({
      numArboles: '',
      numAguacates: '',
      areaCultivo: '',
      aguaIrrigacion: '',
      fertilizantes: '',
      pesticidas: '',
      energiaConsumida: '',
      tipoEnergia: '',
      materialEmpaque: '',
      materialEmpaqueCantidad: '',
    });
  };

  

  return (
    <div className="bg-white font-sans flex flex-col rounded-xl p-4 md:p-4 shadow-sm w-full gap-y-6 ">
      <form className="grid grid-cols-2 gap-3" >
        {/* Your form fields */}
        <div className="col-span-2">Datos de la finca</div>
        <div className="">
          <div className="relative">
            <input
              type="number"
              id="numArboles"
              name="numArboles"
              value={avocadoData.numArboles}
              onChange={handleChange}
              required
              className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
            />
            <label htmlFor="numArboles" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Cantidad árboles</label>
          </div>
          {errors.numArboles && 
            <p id="outlined_error_help" 
            className="mt-2 text-xs text-red-600">{errors.numArboles}</p>
          }
        </div>
        <div>
          <div className="relative">
            <input
              type="number"
              id="areaCultivo"
              name="areaCultivo"
              value={avocadoData.areaCultivo}
              onChange={handleChange}
              className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
            />
            <label htmlFor="areaCultivo" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Área utilizada</label>
          <span className="absolute ml-2 text-xs leading-5 text-gray-500 pointer-events-none bottom-2 right-2 ">ha</span>
          </div>
          {/* Error message for total land area used for cultivation */}
          {errors.areaCultivo && 
            <p id="outlined_error_help" 
            className="mt-2 text-xs text-red-600">{errors.areaCultivo}</p>
          }
        </div>
        <div className="col-span-2">
          <div className="relative">
            <input
              type="number"
              id="numAguacates"
              name="numAguacates"
              value={avocadoData.numAguacates}
              onChange={handleChange}
              required
              className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
            />
            <label htmlFor="numAguacates" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Cantidad Aguacates</label>
            <span className="absolute ml-2 text-xs leading-5 text-gray-500 pointer-events-none bottom-2 right-2 ">kg</span>

          </div>
          {errors.numAguacates && 
            <p id="outlined_error_help" 
            className="mt-2 text-xs text-red-600">{errors.numAguacates}</p>
          }
        </div>
        <div className="col-span-2">Uso de recursos</div>
      {/* Water total used for irrigation */}
        <div className="col-span-2">
          <div className="relative">
            <input
              value={avocadoData.aguaIrrigacion}
              onChange={handleChange}
              type="number"
              id="aguaIrrigacion"
              name="aguaIrrigacion"
              className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
            />
            <label htmlFor="aguaIrrigacion" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Agua total utilizada</label>
            <span className="absolute ml-2 text-xs leading-5 text-gray-500 pointer-events-none bottom-2 right-2 ">L</span>

          </div>
          {errors.aguaIrrigacion && 
            <p id="outlined_error_help" 
            className="mt-2 text-xs text-red-600">{errors.aguaIrrigacion}</p>
          }
        </div>

        {/* Type and quantity of fertilizers used */}
        {/* <div>
          <div className="relative">
            <select
              value={avocadoData.typeFertilizantes}
              onChange={handleChange}
              id="typeFertilizantes"
              name="typeFertilizantes"
              className="block disabled:text-gray-500 px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
            >
              <option value="" disabled selected hidden>
            Selecciona un fertilizante
          </option>
          <option value="FertilizerNinguno">
            Ninguno
          </option>
          <option value="FertilizerBonide">
            Bonide Fruit Tree Spray Concentrate
          </option>
          <option value="FertilizerMonterey">
            Monterey LG6135 Garden Insect Spray
          </option>
          <option value="FertilizerNeem">
            Safer Brand Neem Oil Concentrate
          </option>
            <label htmlFor="typeFertilizantes" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Tipo de Fertilizantes</label>
            </select>
          </div>
          {errors.typeFertilizantes && 
            <p id="outlined_error_help" 
            className="mt-2 text-xs text-red-600">{errors.typeFertilizantes}</p>
          }
        </div>
        <div>
          <div className="relative">
            <input
            type="number"
              id="cantidadFertilizantes"
              name="cantidadFertilizantes"
              value={avocadoData.cantidadFertilizantes}
              onChange={handleChange}
              required
              className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
            />
            <label htmlFor="cantidadFertilizantes" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Cantidad</label>
              <span className="absolute ml-2 text-xs leading-5 text-gray-500 pointer-events-none bottom-2 right-2 ">L</span>

          </div>
          {errors.cantidadFertilizantes && 
            <p id="outlined_error_help" 
            className="mt-2 text-xs text-red-600">{errors.cantidadFertilizantes}</p>
          }
        </div> */}
        {/* Type and quantity of pesticides used */}
        {/* <div>
          <div className="relative">
            <select
              id="typePesticidas"
              name="typePesticidas"
              value={avocadoData.typePesticidas}
              onChange={handleChange}
              className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
            >
            <label htmlFor="typePesticidas" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Tipo de Pesticidas</label>
            <option value="" disabled selected hidden>
            Selecciona una pesticida
          </option>
          <option value="FertilizerNinguno">
            Ninguno
          </option>
          <option value="FertilizerMiracleGro">
            Miracle-Gro Water Soluble All Purpose Plant Food
          </option>
          <option value="FertilizerJobe">
            Jobe's Organics Fruit & Citrus Fertilizer Spikes
          </option>
          <option value="FertilizerOsmocote">
            Osmocote Smart-Release Plant Food Flower & Vegetable
          </option>
            </select>
          </div>
          {errors.typePesticidas && 
            <p id="outlined_error_help" 
            className="mt-2 text-xs text-red-600">{errors.typePesticidas}</p>
          }
        </div>
        <div>
          <div className="relative">
            <input
              id="cantidadPesticidas"
              name="cantidadPesticidas"
              value={avocadoData.cantidadPesticidas}
              onChange={handleChange}
              required
              className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
            />
            <label htmlFor="cantidadPesticidas" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Cantidad</label>
                  <span className="absolute ml-2 text-xs leading-5 text-gray-500 pointer-events-none bottom-2 right-2 ">L</span>

          </div>
          {errors.cantidadPesticidas && 
            <p id="outlined_error_help" 
            className="mt-2 text-xs text-red-600">{errors.cantidadPesticidas}</p>
          }
        </div> */}
      {/* <div className="col-span-2">Energía</div> */}

      <div>
        <div className="relative">
          <select
            id="tipoEnergia"
            name="tipoEnergia"
            value={avocadoData.tipoEnergia}
            onChange={handleChange}
            required
            className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
          >
          <option value="" disabled selected hidden>Seleccione una opción</option>
            <option value="EnergySolar">Energía solar</option>
            <option value="EnergyEolic">Energía eólica</option>
            <option value="EnergyHydroelecric">Energía hidroeléctrica</option>
            <option value="EnergyCoal">Combustibles fósiles</option>
            <option value="EnergyNuclear">Energía atomar</option>
          <label htmlFor="tipoEnergia" 
          className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Tipo de fuente de energía</label>
          </select>
        </div>
        {errors.tipoEnergia && 
          <p id="outlined_error_help" 
          className="mt-2 text-xs text-red-600">{errors.tipoEnergia}</p>
        }
      </div>
      {/* Energy consumption for irrigation, processing, and refrigeration */}
      <div>
        <div className="relative">
          <input
            type="text"
            id="energiaConsumida"
            name="energiaConsumida"
            value={avocadoData.energiaConsumida}
            onChange={handleChange}
            required
            className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
          />
          
          <label htmlFor="energiaConsumida" 
          className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Consumo energía</label>
          <span className="absolute ml-2 text-xs leading-5 text-gray-500 pointer-events-none bottom-2 right-2 ">kWh</span>

        </div>
        {errors.energiaConsumida && 
          <p id="outlined_error_help" 
          className="mt-2 text-xs text-red-600">{errors.energiaConsumida}</p>
        }
      </div>

      {/* Type of energy source used */}

      <div className="col-span-2">Empaquetado</div>
      {/* Type and quantity of packaging materials used */}
      <div>
        <div className="relative">
          <select
            id="materialEmpaque"
            name="materialEmpaque"
            value={avocadoData.materialEmpaque}
            onChange={handleChange}
            required
            className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
          >
            <option value="" disabled selected hidden>Seleccione una opción</option>
            <option value="Plastic">Plástico</option>
            <option value="Paper">Cartón</option>
            {/* <option value="Bolsas biodegradables">Bolsas biodegradables</option> */}
            {/* <option value="Material reutilizable">Material reutilizable</option> */}
          <label htmlFor="materialEmpaque" 
          className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Tipo de empaque utilizado</label>
          </select>
        </div>
        {errors.materialEmpaque && 
          <p id="outlined_error_help" 
          className="mt-2 text-xs text-red-600">{errors.materialEmpaque}</p>
        }
      </div>
      <div>
        <div className="relative">
          <input
            id="materialEmpaqueCantidad"
            name="materialEmpaqueCantidad"
            value={avocadoData.materialEmpaqueCantidad}
            onChange={handleChange}
            required
            className="block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"  placeholder=" "
          />
          <label htmlFor="materialEmpaqueCantidad" 
          className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Peso</label>
                    <span className="absolute ml-2 text-xs leading-5 text-gray-500 pointer-events-none bottom-2 right-2 ">g</span>

        </div>
        {errors.materialEmpaqueCantidad && 
          <p id="outlined_error_help" 
          className="mt-2 text-xs text-red-600">{errors.materialEmpaqueCantidad}</p>
        }
      </div>

        {/* <button onClick={handleSubmit} className="col-start-2 bg-light-green-500 hover:bg-light-green-400 text-light-green-100 px-2 py-2.5 rounded flex-grow flex gap-x-2 w-full justify-start items-center" 
        type="submit"><Icon icon="ion:calculator" className="w-5" />
         Calcular</button> */}
      </form>
      </div>
    // </div>
  );
}

const Results = ({ avocadoData }) => {

// Cantidad de horas de trabajo por rendimiento:
// Horas de Mano de Obra por Rendimiento = Total de horas empleadas / Cantidad de rendimiento
// Coste de Producción por kg de Aguacates
// Coste de producción por kg = Coste total de producción / kg totales de aguacates producidos

// GHG Emissions: kg(CO2)/kg(Avocado)
// Paper packaging: 0.035 
// Plastic packaging: 0.0534

const packagingTypes = {
  Paper: 0.035,
  Plastic: 0.0534
}

const selectedPackagingType = avocadoData.materialEmpaque;
const packagingType = parseFloat(avocadoData.materialEmpaqueCantidad);   

let totalPackagingEmissions = 0;
if (selectedPackagingType && packagingType) {
  const packagingFactor = packagingTypes[selectedPackagingType];
  totalPackagingEmissions = (packagingFactor * packagingType); // Convert g to kg
}

// Other factors:
// Tipos de energía: g(CO2)/kWh
const energyCO2EmissionFactors = {
  EnergySolar: 35,  // EnergySolar: 35 (20-50)
  EnergyEolic: 15,  // EnergyEolic: 15 (10-20)
  EnergyHydroelecric: 15,  // EnergyHydroelecric: 15 (5-25)
  EnergyCoal: 720,  // EnergyCoal: 720 (400-500 -- 800-1000 -- 700-900)
  EnergyNuclear: 15,  // EnergyNuclear: 15 (10-20)
};
   
const selectedEnergyType = avocadoData.tipoEnergia;
const energyConsumption = parseFloat(avocadoData.energiaConsumida);   
   
let energyCo2Emissions = 0;
  if (selectedEnergyType && energyConsumption) {
    const co2EmissionFactor = energyCO2EmissionFactors[selectedEnergyType];
    energyCo2Emissions = (co2EmissionFactor * energyConsumption) / 1000; // Convert g to kg
  }


const rendimientoPorArbol = avocadoData.numAguacates / avocadoData.numArboles;

// Rendimiento por área = Número total de aguacates cosechados / Área utilizada
const rendimientoPorArea = avocadoData.numAguacates / avocadoData.areaCultivo;

// Eficiencia del uso de la tierra = Número de árboles por hectárea
const eficienciaTierra = avocadoData.numArboles / avocadoData.areaCultivo;

// Uso de agua por kg aguacate = Total de agua utilizada para riego / Total de kg de aguacates producidos
const usoAguaPorKg = avocadoData.aguaIrrigacion / avocadoData.numAguacates;

// Uso de agua por árbol = Total de agua utilizada para riego / Número de árboles
const usoAguaPorArbol = avocadoData.aguaIrrigacion / avocadoData.numArboles;

// Uso de fertilizantes por árbol = Cantidad total utilizada / Número de árboles
const usoFertilizantesPorArbol = avocadoData.cantidadFertilizantes / avocadoData.numArboles;

// Uso de fertilizantes por árbol = Cantidad total utilizada / Número de árboles
const usoFertilizantesPorKg = avocadoData.cantidadFertilizantes / avocadoData.numAguacates;

// Uso de pesticidas por árbol = Cantidad total utilizada / Número de árboles
const usoPesticidasPorArbol = avocadoData.cantidadPesticidas / avocadoData.numArboles;

const usoPesticidasPorKg = avocadoData.cantidadPesticidas / avocadoData.numAguacates;
// Energía por árbol = Consumo total de energía / Número de árboles
const energiaPorArbol = avocadoData.energiaConsumida / avocadoData.numArboles;

// Energía por kg aguacate = Consumo total de energía / Total de kg de aguacates producidos
const energiaPorKg = energyCo2Emissions / avocadoData.numAguacates;


// Absorción de CO2 por árbol = Promedio de CO2 absorbido por árbol (kg(co2))
const absorcionCO2PorArbol = 20;

// Absorción de CO2 por kg aguacate = Promedio de CO2 absorbido por kg de aguacate
const absorcionCO2PorKg = absorcionCO2PorArbol / avocadoData.numArboles * avocadoData.numAguacates;

// Absorción total de CO2 por finca = Absorción de CO2 por árbol * Número de árboles
const absorcionTotalCO2 = absorcionCO2PorArbol * avocadoData.numArboles;


  return (
    <div className="h-full flex flex-col gap-y-4 py-4">
      <h3>Resultados</h3>
      <div className="flex flex-col gap-y-4 div ide-y divide-dark-blue-900">
        <div className="flex flex-col gap-y-2">
          <h6 className="mb-0">Rendimiento</h6>
          {/* Rendimiento por árbol = Número total de aguacates cosechados / Número de árboles */} 
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Por arbol:</p>
            <p className="text-sm flex flex-row gap-x-1">{rendimientoPorArbol ? rendimientoPorArbol : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              kg <span className="w-full h-px bg-dark-blue-950"></span> 
            arbol</div></p>
          </div>
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Por área:</p>
            <p className="text-sm flex flex-row gap-x-1">{rendimientoPorArea ? rendimientoPorArea : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              kg <span className="w-full h-px bg-dark-blue-950"></span> 
            ha</div></p>
          </div>
          {/* Eficiencia del uso de la tierra = Número de árboles por hectárea */}
        <div className="flex justify-between flex-row">
          <p className="text-sm ">Densidad de arboles/area:</p>
          <p className="text-sm flex flex-row gap-x-1">{eficienciaTierra ? eficienciaTierra : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              arboles <span className="w-full h-px bg-dark-blue-950"></span> 
            ha</div></p>
        </div>
        </div>
    
        <div className="flex flex-col gap-y-2">  
          <h6 className="mb-0">Agua</h6>
          {/* Uso de agua por kg aguacate = Total de agua utilizada para riego / Total de kg de aguacates producidos */}
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Uso de agua por kg:</p>
            <p className="text-sm flex flex-row gap-x-1">{usoAguaPorKg ? usoAguaPorKg : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              l <span className="w-full h-px bg-dark-blue-950"></span> 
            kg</div></p>
          </div>
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Uso de agua por árbol:</p>
            <p className="text-sm flex flex-row gap-x-1">{usoAguaPorArbol ? usoAguaPorArbol : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              l <span className="w-full h-px bg-dark-blue-950"></span> 
            arbol</div></p>
          </div>
        </div>
        {/* <div className="flex flex-col gap-y-2">  
        <h6 className="mb-0">Cifras medioambientales</h6>

          <div className="flex justify-between flex-row">
            <p className="text-sm ">Fertilizantes por aguacate:</p>
            <p className="text-sm flex flex-row gap-x-1">{usoFertilizantesPorKg ? usoFertilizantesPorKg : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              l <span className="w-full h-px bg-dark-blue-950"></span> 
            kg</div></p>
          </div>
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Fertilizantes por arbol:</p>
            <p className="text-sm flex flex-row gap-x-1">{usoFertilizantesPorArbol ? usoFertilizantesPorArbol : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              l <span className="w-full h-px bg-dark-blue-950"></span> 
            arbol</div></p>
          </div>
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Pesticidas por aguacate:</p>
            <p className="text-sm flex flex-row gap-x-1">5 <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              l <span className="w-full h-px bg-dark-blue-950"></span> 
            kg</div></p>
          </div>
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Pesticidas por arbol:</p>
            <p className="text-sm flex flex-row gap-x-1">{usoPesticidasPorArbol ? usoPesticidasPorArbol : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              l <span className="w-full h-px bg-dark-blue-950"></span> 
            arbol</div></p>
          </div>
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Pesticidas por kg:</p>
            <p className="text-sm flex flex-row gap-x-1">{usoPesticidasPorKg ? usoPesticidasPorKg : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              l <span className="w-full h-px bg-dark-blue-950"></span> 
            kg</div></p>
          </div>
          </div> */}
          <div className="flex flex-col gap-y-2">  
          <h6 className="mb-0">Energía</h6>

          <div className="flex justify-between flex-row">
            <p className="text-sm ">Energía por arbol:</p>
            <p className="text-sm flex flex-row gap-x-1">{energiaPorArbol ? energiaPorArbol : 0} <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              kWh <span className="w-full h-px bg-dark-blue-950"></span> 
            arbol</div></p>
          </div>
          <div className="flex justify-between flex-row">
            <p className="text-sm">Energía por kg:</p>
            <p className="text-sm flex flex-row gap-x-1">{energiaPorKg ? energiaPorKg.toFixed(2) : 0} 
            <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              kWh
              <span className="w-full h-px bg-dark-blue-950"></span> 
            kg</div>
            </p>
          </div>
          {/* EMISIONES CO2 POR TIPO DE ENERGIA */}
          </div>
          <div className="flex flex-col gap-y-2">  
          <h6 className="mb-0">Absorción CO2</h6>
          {/* Absorción de CO2 por árbol = Promedio de CO2 absorbido por árbol y año */}
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Absorción de CO2 por kg aguacate:</p>
            <p className="text-sm flex flex-row gap-x-1">{absorcionCO2PorKg ? absorcionCO2PorKg : 0} 
            <div className='flex items-center flex-col text-[0.5rem] leading-[1.3]'>
              kg(CO2)
              <span className="w-full h-px bg-dark-blue-950"></span> 
            kg(aguacate)</div>
            {/* <span>kg<span className="align-sub text-[0.6rem]">CO2</span> / año</span> */}
            </p>
          </div>
          <div className="flex justify-between flex-row">
            <p className="text-sm ">Absorción de CO2 por arbol:</p>
            <p className="text-sm flex flex-row gap-x-1">{absorcionCO2PorArbol ? absorcionCO2PorArbol : 0} 
            <div className='flex flex-col items-center text-[0.5rem] leading-[1.3]'>
              kg(CO2)
              <span className="w-full h-px bg-dark-blue-950"></span> 
            arból</div>
            </p>
          </div>

          <div className="flex justify-between flex-row">
            <p className="text-sm ">Absorción total de CO2 por finca:</p>
            <p className="text-sm">{absorcionTotalCO2} <span className='text-xs'>kg(CO2)</span></p>
          </div>
        </div>
        <div className="flex flex-col gap-y-2">  
          <h6 className="mb-0">Empaquetado</h6>
            <div className="flex justify-between flex-row">
              <p className="text-sm ">Emisiones de empaquetado:</p>
              <p className="text-sm">{totalPackagingEmissions ? totalPackagingEmissions.toFixed(2) : 0} <span className='text-xs'>kg(CO2)</span></p>
            </div>
        </div>
      </div>
    </div>
  )
}

const AvocadoCarbonCalculator = () =>  {
  const [avocadoData, setAvocadoData] = useState({
    numArboles: '',
    numAguacates: '',
    areaCultivo: '',
    aguaIrrigacion: '',
    fertilizantes: '',
    pesticidas: '',
    energiaConsumida: '',
    tipoEnergia: '',
    materialEmpaque: ''
  });

  
  
  // Updated error messages in Spanish
  const errorMessages = {
    numArboles: 'Por favor ingrese la cantidad de árboles.',
    areaCultivo: 'Por favor ingrese el área utilizada.',
    aguaIrrigacion: 'Por favor ingrese el total de agua utilizada.',
    typeFertilizantes: 'Seleccione el tipo de fertilizante utilizado.',
    cantidadFertilizantes: 'Ingrese la cantidad de fertilizante utilizada.',
    typePesticidas: 'Seleccione el tipo de pesticida utilizado.',
    cantidadPesticidas: 'Ingrese la cantidad de pesticida utilizada.',
    energiaConsumida: 'Ingrese la cantidad de energía consumida.',
    tipoEnergia: 'Seleccione el tipo de fuente de energía.',
    materialEmpaque: 'Seleccione el tipo de empaque utilizado.',
    materialEmpaqueCantidad: 'Ingrese la cantidad de material de empaque utilizado.',
  };
  return(
      <div className="h-[100svh] sm:h-full relative flex-col flex p-4 md:p-6 pt-6 md:pt-10 w-full overflow-y-auto">
        <span className="text-md w-auto tracking-wide uppercase text-dark-blue-900 self-end">Calculo CO2</span>
        <h1 className="text-2xl mb-4 md:mb-10 w-auto tracking-wide font-bold uppercase text-dark-blue-900 self-end">Productores</h1>
        <div className="h-full w-full flex flex-col gap-4 md:flex-row ">
        <div className="md:w-1/2 sticky md:top-">
          <Calculator avocadoData={avocadoData} setAvocadoData={setAvocadoData} />
        </div>
        <div className="md:w-1/2">
          <Results avocadoData={avocadoData} />
        </div>
        </div>
      </div>
    )
}


export default AvocadoCarbonCalculator;

