import React, { useMemo, useEffect, useState } from 'react'

const ComparisonTable = ({ data  }) => {
    //const [data, setData] = useState([]);

    /**
     * useEffect(() => {
        // Fetch the data from the JSON file
        fetch(jsonFile)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setData(data);  // Set the data to state
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }, []);
     */
    
    /**
     * <table className="overflow-y-auto">
        <thead>
          <tr>
            <th className="py-2">Parameter</th>
            <th className="py-2">Consumo de Agua</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.nombre}</td>
              <td>{item.kpi.consumo_agua} L</td>
            </tr>
          ))}
        </tbody>
      </table>
     */

  return (
      <table className="standard-table overflow-y-auto">
        <thead>
          <tr>
            <th>Parameter</th>
            {data.map((item, index) => (
              <th key={index}>{item.nombre}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Consumo de Agua</td>
            {data.map((item, index) => (
              <td key={index}>{item.kpi.consumo_agua2} L</td>
            ))}
          </tr>

        </tbody>
      </table>
  );
};

export default ComparisonTable;
