import React, { useState, useEffect } from 'react';
import ChartBarAnalysis from '../charts/ChartBarAnalysis';
import ChartBarTransport from '../charts/ChartBarTransport';
import Map from '../charts/map';
import ComparisonTableAnalysisTransport from '../tables/ComparisonTableAnalysisTransport';
import debounce from 'lodash.debounce';
import {Tooltip} from 'react-tooltip';
import { Icon } from '@iconify/react';
import Select from 'react-select';

const CalcCO2 = () => {
  //
  const [origen, setOrigen] = useState('');
  const [destino, setDestino] = useState('');
  const [medioTransporte, setMedioTransporte] = useState('');
  const [pesoCarga, setPesoCarga] = useState('');
  // Nuevos estados para almacenar los datos necesarios
  const [chartEmisionCO2, setChartEmisionCO2] = useState(0);
  const [chartDistancia, setChartDistancia] = useState(0);
  const [chartCoordenadasOrigen, setChartCoordenadasOrigen] = useState({});
  const [chartCoordenadasDestino, setChartCoordenadasDestino] = useState({});
  const [mapCoordenadasOrigen, setMapCoordenadasOrigen] = useState({});
  const [mapCoordenadasDestino, setMapCoordenadasDestino] = useState({});
  const [isCalculated, setIsCalculated] = useState(false);
  const [nombreCiudadOrigen, setNombreCiudadOrigen] = useState('');
  const [tableData, setTableData] = useState([]);
  //
  const [searchResultsOrigen, setSearchResultsOrigen] = useState([]);
  const [searchResultsDestino, setSearchResultsDestino] = useState([]);
  const [selectedCityOrigen, setSelectedCityOrigen] = useState(null);
  const [selectedCityDestino, setSelectedCityDestino] = useState(null);
  //
  const [shortNameOrigen, setShortNameOrigen] = useState('');
  const [shortNameDestino, setShortNameDestino] = useState('');
  //
  const [isLoading, setIsLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  //
  const [showAñadirCalculo, setShowAñadirCalculo] = useState(false);
  const [showResults, setShowResults] = useState(false);
  //
  const [selectedData, setSelectedData] = useState(null);
  //
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  //Managing the error/warning messages to the user
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'error', 'warning', etc.
  //
  const [mapRoutes, setMapRoutes] = useState([]);
  //
  const zoomLevel = 7; //6
  //
  const [selectedOrigen, setSelectedOrigen] = useState(null);
  const [selectedDestino, setSelectedDestino] = useState(null);

  //Translations
  const countryNameTranslations = {
    "España": "Spain",
    "Francia": "France",
    "Paises Bajos": "Netherlands"
  };  

  const transportMethodTranslations = {
    "camion": "truck",
    "tren": "train",
    "barco": "ship",
    "avion": "plane"
  };
  //Seaports for overseas co2 calc
  const distanciasAPuertos = [
    {"ciudad": "Veracruz", "distanciaAValenciaKm": 9154.23},
    {"ciudad": "Valparaíso", "distanciaAValenciaKm": 10874.93},
    {"ciudad": "Callao", "distanciaAValenciaKm": 9744.72},
    {"ciudad": "Los Ángeles", "distanciaAValenciaKm": 9694.33},
    {"ciudad": "Rotterdam", "distanciaAValenciaKm": 1430.19},
    {"ciudad": "Mersin", "distanciaAValenciaKm": 3064.39},
    {"ciudad": "Tánger", "distanciaAValenciaKm": 629.56}
  ];

  const fetchCities = async (city, setSearchResults) => {
    if (!city) {
      setSearchResults([]);
      return;
    }

    try {
      const response = await fetch(`/api/getCoordenadas?city=${encodeURIComponent(city)}`);
      //console.log("fetchCities response",response);
      const data = await response.json();
      //console.log("data",data);
      setSearchResults(data);
      //return cityData;
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }; //End fetchCities 

  //Debounce the API call
  //const debouncedFetchCities = debounce(fetchCities, 500);
  //Debounce the wrapper function instead of fetchCities directly
  /*const debouncedFetchCities = debounce((fetchFunction) => {
    fetchFunction();
  }, 500);*/
  const debouncedFetchCitiesOrigen = debounce((value) => {
    fetchCities(value, setSearchResultsOrigen);
  }, 1000);

  const debouncedFetchCitiesDestino = debounce((value) => {
      fetchCities(value, setSearchResultsDestino);
  }, 1000);

  //Validar  
  const handleOrigenChange = (e) => {
    //setOrigen(e.target.value.replace(/[^A-Za-zñÑ, ']/g, ''))
    //debouncedFetchCities(e.target.value, setSearchResultsOrigen);
    // Prepare the fetchCities function with the current arguments
    /*const fetchFunction = () => fetchCities(e.target.value, setSearchResultsOrigen);
    // Call the debounced function with the prepared function
    debouncedFetchCities(fetchFunction);*/
    //debouncedFetchCitiesOrigen(e.target.value);
    const newValue = e.target.value.replace(/[^A-Za-zñÑ, ']/g, '');
    setOrigen(newValue);

    if (newValue === '') {
        setSearchResultsOrigen([]);  // Clear search results if input is empty
    } else {
        debouncedFetchCitiesOrigen(newValue);
    }
  };
  const handleDestinoChange = (e) => {
    const newValue = e.target.value.replace(/[^A-Za-zñÑ, ']/g, '');
    setDestino(newValue);

    if (newValue === '') {
        setSearchResultsDestino([]);  // Clear search results if input is empty
    } else {
        debouncedFetchCitiesDestino(newValue);
    }
  };
  const handleMedioTransporteChange = (e) => setMedioTransporte(e.target.value);
  const handlePesoCargaChange = (e) => setPesoCarga(e.target.value.replace(/[^0-9]/g, ''));      

  // Cleanup useEffect to cancel debounced calls on unmount
  useEffect(() => {
    return () => {
        debouncedFetchCitiesOrigen.cancel();
        debouncedFetchCitiesDestino.cancel();
    };
  }, []);
  
  /*const getCoordinates = async (cityData, setCoordinates) => {   
    const coordinates = [cityData[0].lon, cityData[0].lat];
    console.log("coordinates",coordinates);
    setCoordinates(coordinates);
    return coordinates;
  }*/

  const getCoordinates = async (cityData) => {
    //console.log("getCoordinates cityData:", cityData);
    return [cityData.lon, cityData.lat];
  };

  const flipCoordinates = async (city) => {
    return [city.lat, city.lon];
  }

  const getDistance = async (coordenadasOrigen, coordenadasDestino) => {
    // Paso 2: Find out the distance 
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ coordinates: [coordenadasOrigen, coordenadasDestino] })
    };
    //console.log("getDistance start");
    //console.log("Request Options:", requestOptions);
    const responseDistancia = await fetch(`/api/getDistancia`, requestOptions);
    //console.log("responseDistancia",responseDistancia);
    const dataDistancia = await responseDistancia.json();
    //console.log("dataDistancia",dataDistancia);
    //const distancia = dataDistancia.features[0].properties.summary.distance / 1000; // Convertir a km
    const distancia = dataDistancia.routes[0].summary.distance / 1000; // La distancia está en metros
    //console.log("distancia",distancia);
    //console.log("getDistance end");
    setChartDistancia(distancia);
    return distancia;
  }

  const getCO2 = async (distancia, medioTransporte, pesoCarga) => {
      // Paso 3: Calculate CO2 emissions
      //Translate transport to English
      const translatedTransportMethod = transportMethodTranslations[medioTransporte] || medioTransporte;
      const requestOptions2 = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          distance_value: distancia,
          transport_method: translatedTransportMethod,
          weight_value: pesoCarga,
          type: "shipping",
          weight_unit: "kg",
          distance_unit: "km"
        })
      }
      const responseCO2 = await fetch(`/api/getCalcularCO2`, requestOptions2);
      const dataCO2 = await responseCO2.json();
      //console.log("getCO2 requestOptions2",requestOptions2);
      //console.log("getCO2 responseCO2",responseCO2);
      //console.log("dataCO2",dataCO2);
      const emisionCO2 = dataCO2.data.attributes.carbon_kg;
  
      // Mostrar o procesar la emisión de CO2
      //console.log('getCO2 Emisión de CO2:', emisionCO2, 'kg');
      //setChartDistancia(distancia);
      //setChartCoordenadasOrigen(coordenadasOrigen);
      //setChartCoordenadasDestino(coordenadasDestino);
      setChartEmisionCO2(emisionCO2);
      return emisionCO2;
  }

  const selectCity = (city, setCityField, setSearchResults, setSelectedCity, setShortName) => {
    const cityNameFull = city.display_name; // Nombre completo
    const cityNameShort = city.display_name.split(',')[0]; // Nombre corto
    // Split the city display name by comma and take the first part
    //const cityName = city.display_name.split(',')[0];
    setCityField(cityNameFull);
    setShortName(cityNameShort);
    //console.log("selectCity setCityField",setCityField);
    //console.log("selectCity setShortName",setShortName);
    // Store lat, lon, and display_name in state as needed
    setSelectedCity(city);
    setSearchResults([]);
  };

  //handleOrigenSelectChange updated to use react-select
  const handleOrigenSelectChange = selectedOption => {
    if (!selectedOption) return;

    // Assuming selectedOption.value is the place_id
    const selectedPlaceId = parseInt(selectedOption.value, 10);
    const selectedCity = searchResultsOrigen.find(city => city.place_id === selectedPlaceId);

    if (selectedCity) {
        // Here, setOrigen might be used to set the label (name of the city)
        // and setSelectedCityOrigen to store the selected city object or its ID
        setOrigen(selectedCity.display_name);
        setSelectedCityOrigen(selectedCity);
        // You might also want to clear the search results once a city is selected
        setSearchResultsOrigen([]);
        // And set the short name of the city if you still need this
        const cityNameShort = selectedCity.display_name.split(',')[0];
        setShortNameOrigen(cityNameShort);
        //
        setSelectedOrigen(selectedOption); 
    }
  };

  //handleOrigenSelectChange updated to use react-select
  const handleDestinoSelectChange = selectedOption => {
    if (!selectedOption) return;

    // Assuming selectedOption.value is the place_id
    const selectedPlaceId = parseInt(selectedOption.value, 10);
    const selectedCity = searchResultsDestino.find(city => city.place_id === selectedPlaceId);

    if (selectedCity) {
        // Here, setOrigen might be used to set the label (name of the city)
        // and setSelectedCityOrigen to store the selected city object or its ID
        setDestino(selectedCity.display_name);
        setSelectedCityDestino(selectedCity);
        // You might also want to clear the search results once a city is selected
        setSearchResultsDestino([]);
        // And set the short name of the city if you still need this
        const cityNameShort = selectedCity.display_name.split(',')[0];
        setShortNameDestino(cityNameShort);
        //
        setSelectedDestino(selectedOption); 
    }
  };

  //Original method
  /*const handleOrigenSelectChange = (e) => {
    //console.log("handleOrigenSelectChange Selected place_id:", e.target.value); // Depura el valor seleccionado
    //console.log("handleOrigenSelectChange SsearchResultsOrigen:", searchResultsOrigen); // Depura el valor seleccionado
    const selectedPlaceId = parseInt(e.target.value, 10); // Convertir a número
    //console.log("Selected place_id:", selectedPlaceId);
    const selectedCity = searchResultsOrigen.find(city => city.place_id === selectedPlaceId);
    //console.log("handleOrigenSelectChange selectedCity",selectedCity);
    if (selectedCity) {
        selectCity(selectedCity, setOrigen, setSearchResultsOrigen, setSelectedCityOrigen, setShortNameOrigen);
    }
  };*/

  /*const handleDestinoSelectChange = (e) => {
    const selectedPlaceId = parseInt(e.target.value, 10); // Convertir a número
    const selectedCity = searchResultsDestino.find(city => city.place_id === selectedPlaceId);
    if (selectedCity) {
        selectCity(selectedCity, setDestino, setSearchResultsDestino, setSelectedCityDestino, setShortNameDestino);
    }
  };*/

  // Funciones para manejar los botones
  const handleCalcular = async () => {
    /// Reset warning state
    setShowWarning(false);
    setMessage('');
    setMessageType('');
    // Check if the required fields are filled
    if (!origen || !destino || !medioTransporte || !pesoCarga) {
      setShowWarning(true); // Show warning message
      return; // Exit the function to prevent further execution
    } else {
      setIsLoading(true);
    }
    try {          
      if (selectedCityOrigen && selectedCityDestino) {
        const coordenadasOrigen = await getCoordinates(selectedCityOrigen);
        const coordenadasDestino = await getCoordinates(selectedCityDestino);
        //
        const distancia = await getDistance(coordenadasOrigen, coordenadasDestino);
        const emisionCO2 = await getCO2(distancia, medioTransporte, pesoCarga);
        //
        setChartCoordenadasOrigen(coordenadasOrigen);
        setChartCoordenadasDestino(coordenadasDestino);

        /*const mapaCoordenadasOrigen = await flipCoordinates(selectedCityOrigen);
        const mapaCoordenadasDestino = await flipCoordinates(selectedCityDestino);
        setMapCoordenadasOrigen(mapaCoordenadasOrigen);
        setMapCoordenadasDestino(mapaCoordenadasDestino);*/

        // Set the initial route
        const initialRoute = {
          from: await flipCoordinates(selectedCityOrigen),
          to: await flipCoordinates(selectedCityDestino),
          distance: distancia,
          co2: emisionCO2
        };
        //Set the routes for the map
        setMapRoutes([initialRoute]);

        setTableData([
          { 
            origen: shortNameOrigen, 
            destino: shortNameDestino, 
            distancia: `${distancia.toFixed(2)} km`, // Format distance to two decimal places
            emisionCO2: `${emisionCO2} kg`,
            medioTransporte: medioTransporte, // Add medioTransporte
            pesoCarga: `${pesoCarga} kg`  
          } 
        ]);  
        setSelectedData([{ nombre: shortNameOrigen, emisionCO2 }]); //this data will be passed to the chartBar   
        setIsCalculated(true);
        setIsLoading(false); // Stop loading once everything is ready
        //console.log("handleCalcular selectedData", selectedData);
        //console.log("Data for charts", chartDistancia, chartCoordenadasOrigen, chartCoordenadasDestino, chartEmisionCO2);
        //Checks if there's data available to display. 
        setIsDataAvailable(tableData.length > 0);
      } else {
        console.error('No se han seleccionado ciudades');
      }
      //const coordenadasOrigen = await getCoordinates(origen, setChartCoordenadasOrigen);
      //const coordenadasDestino = await getCoordinates(destino, setChartCoordenadasDestino);            
    } catch (error) {
      console.error('Error al calcular CO2:', error);
    }
  };
  //console.log("Data for charts outside", chartDistancia, chartCoordenadasOrigen, chartCoordenadasDestino, chartEmisionCO2);

  const handleBorrar = () => {
    setOrigen('');
    setDestino('');
    setMedioTransporte('');
    setPesoCarga('');
    setSelectedOrigen(null);
    setSelectedDestino(null);
  };

  const handleAñadir = async () => {
    /// Reset warning state
    setShowWarning(false);
    setMessage('');
    setMessageType('');
    // Hide existing results before updating data
    //setShowResults(false);
    setShowAñadirCalculo(false);
    // Check if all input fields have values
    /*if (!origen || !destino || !medioTransporte || !pesoCarga) {
      setShowWarning(true); // Show a warning message
      return; // Exit the function if any field is empty
    } else {
      setIsLoading(true);
    }*/
    // Check if data for initial calculation is available
    if (!shortNameOrigen || chartEmisionCO2 === 0 || tableData.length === 0) {
      setMessage('Por favor realiza un cálculo antes de añadir uno nuevo.');
      setMessageType('Error');
      return;
    }

    // Check if input fields are filled
    if (!origen || !destino || !medioTransporte || !pesoCarga) {
      setShowWarning(true); // Show a warning message
      setMessage('Por favor rellena todos los campos.');
      setMessageType('warning');
      return;
    }

    // Compare new input data with existing data
    if (origen === shortNameOrigen && destino === shortNameDestino) {
      setMessage('Los datos nuevos son los mismos que los anteriores. Por favor añade nuevos datos.');
      setMessageType('Error');
      return;
    }

    // Before proceeding with the addition, check for duplicate entry
    if (isDuplicateEntry()) {
      setMessage('Los nuevos datos que ha introducido están duplicados.');
      setMessageType('error');
      return;
  }

    setIsLoading(true);

    try {

      //ADD CONDITION TO START CALCULATIONS...

      // Perform new calculations
      const coordenadasOrigen = await getCoordinates(selectedCityOrigen);
      const coordenadasDestino = await getCoordinates(selectedCityDestino);
      const distancia = await getDistance(coordenadasOrigen, coordenadasDestino);
      const emisionCO2 = await getCO2(distancia, medioTransporte, pesoCarga);
      //console.log("handleAñadir new calcs coordenadasOrigen", coordenadasOrigen);
      //console.log("handleAñadir new calcs coordenadasOrigen", coordenadasDestino);
      //console.log("handleAñadir new calcs coordenadasOrigen", distancia);
      //console.log("handleAñadir new calcs coordenadasOrigen", emisionCO2);
      setChartCoordenadasOrigen(coordenadasOrigen);
      setChartCoordenadasDestino(coordenadasDestino);

      const mapaCoordenadasOrigen = await flipCoordinates(selectedCityOrigen);
      const mapaCoordenadasDestino = await flipCoordinates(selectedCityDestino);
      setMapCoordenadasOrigen(mapaCoordenadasOrigen);
      setMapCoordenadasDestino(mapaCoordenadasDestino);

      const newRoute = {
        from: await flipCoordinates(selectedCityOrigen),
        to: await flipCoordinates(selectedCityDestino),
        distance: distancia,
        co2: emisionCO2
      };

      // Add the new route to the existing routes
      setMapRoutes(prevRoutes => [...prevRoutes, newRoute]);

      // Create new entry
      const addData = {
        origen: shortNameOrigen, 
        destino: shortNameDestino, 
        distancia: `${distancia.toFixed(2)} km`, 
        emisionCO2: `${emisionCO2} kg`,
        medioTransporte, 
        pesoCarga: `${pesoCarga} kg`
      };
      //console.log("handleAñadir addData", addData);
      // Add new entry to data, keeping only the last 5 entries
      //console.log("handleAñadir shortNameOrigen",shortNameOrigen);
      //console.log("handleAñadir chartEmisionCO2",chartEmisionCO2);
      //console.log("handleAñadir selectedData",selectedData);
      const updatedTableData = [...tableData, addData].slice(-5);
      //const updatedSelectedData = [...selectedData, { nombre: shortNameOrigen, chartEmisionCO2 }].slice(-5);
      const updatedSelectedData = selectedData.concat([{ nombre: shortNameOrigen, emisionCO2 }].slice(-5)); //this data will be passed to the chartBar
      //console.log("handleAñadir updatedTableData",updatedTableData);
      //console.log("handleAñadir updatedSelectedData",updatedSelectedData);
      // Update state
      setTableData(updatedTableData);
      setSelectedData(updatedSelectedData);
      //Checks if there's data available to display. 
      setIsDataAvailable(tableData.length > 0);
      // Show updated results
      //setShowResults(true);
      setIsCalculated(true); //Modified to true because now the data is passed inside the method handleAñadir instead of calling the same component (chartBar, map and table) in different places to pass different data
      setShowAñadirCalculo(true);
      setIsLoading(false); //Stop loading
    } catch (error) {
      console.error('Error al añadir nuevo cálculo de CO2:', error);
    }    
  };

  const isDuplicateEntry = () => {
    return tableData.some(entry => 
        entry.origen === shortNameOrigen && 
        entry.destino === shortNameDestino && 
        entry.medioTransporte === medioTransporte && 
        entry.pesoCarga === `${pesoCarga} kg` // assuming pesoCarga in tableData is stored with 'kg'
    );
};

  const handleNuevo = () => {
    //Reset text fields
    setOrigen('');
    setDestino('');
    setMedioTransporte('');
    setPesoCarga('');
    setNombreCiudadOrigen('');

    //Reset chartBar and table data
    setChartEmisionCO2(0);
    setChartDistancia(0);
    setChartCoordenadasOrigen({});
    setChartCoordenadasDestino({});
    setTableData([]);
    setSelectedData(null);
    //Reset state for Adding new calc
    setShowAñadirCalculo(false);
    //Reset state that indicates there have been a previous calc
    setIsDataAvailable(false);
    //Indicate that it has not yet been calculated
    setIsCalculated(false);
    // Reset message and message type
    setMessage('');
    setMessageType('');
    //Reset the react-select
    setSelectedOrigen(null);
    setSelectedDestino(null);
  }

  //Options for the react-select component used in the input field for origen city
  const origenOptions = searchResultsOrigen.map(city => ({
    value: city.place_id.toString(),
    label: city.display_name
  }));
  const destinoOptions = searchResultsDestino.map(city => ({
    value: city.place_id.toString(),
    label: city.display_name
  }));
  
  /**
   * <div>
          <label className="text-dark-blue-900">Introduce País Origen:</label>
          <input
            type="text"
            value={paisOrigen}
            onChange={handleOrigenChange}
            className="w-full p-2 mt-2 bg-white"
          />
        </div>

        <div>
          <label className="text-dark-blue-900">Introduce País Destino:</label>
          <input
            type="text"
            value={paisDestino}
            onChange={handleDestinoChange}
            className="w-full p-2 mt-2 bg-white"
          />
        </div>
   */
  return (
    <div className="h-[100svh] sm:h-full relative flex-col flex p-4 md:p-6 pt-6 md:pt-10 w-full overflow-y-auto overflow-x-clip">
      <span className="text-md tracking-wide uppercase text-dark-blue-900 mb-1 self-end">Calculo CO2</span>
      <h1 className="text-2xl mb-4 md:mb-10 tracking-wide font-bold uppercase text-dark-blue-900 self-end">Transportistas</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-3 md:gap-4 mb-4 bg-white font-sans flex flex-col rounded-xl p-4 md:p-4 shadow-sm w-full ">
        <div >
          <label className="text-dark-blue-900 mb-2">Introduce Origen:</label>
            <Select
            classNames={{
              control: ()=> "h-8 !rounded text-sm"
                        }}
              value={selectedOrigen}
              options={origenOptions}
              onChange={handleOrigenSelectChange}
              onInputChange={debouncedFetchCitiesOrigen}
              placeholder="Escribe el nombre de una población"
              data-tooltip-id="inputOrigen"
              data-tooltip-delay-show={1000}
              data-tooltip-content="Escribe el nombre de una población y selecciona la que desees."
              className="w-full rounded mt-2 flex-grow focus:outline-primary-200 focus:outline-none focus:ring-0" //p-2
            />
            <Tooltip className="custom-tooltip" id="inputOrigen" place="top" type="dark" effect="solid"/>
          </div>        
        <div>
          <label className="text-dark-blue-900">Introduce Destino:</label>
            <Select
            classNames={{
                            control: ()=> "h-8 !rounded text-sm"
                        }}
              value={selectedDestino}
              options={destinoOptions}
              onChange={handleDestinoSelectChange}
              onInputChange={debouncedFetchCitiesDestino}
              placeholder="Escribe el nombre de una población"
              data-tooltip-id="inputDestino"
              data-tooltip-delay-show={1000}
              data-tooltip-content="Escribe el nombre de una población y selecciona la que desees."
              className="w-full rounded mt-2 flex-grow focus:outline-primary-200 focus:outline-none focus:ring-0" //p-2
            />
            <Tooltip className="custom-tooltip" id="inputDestino" place="top" type="info" effect="solid"/>
        </div>        
        <div>
          <label className="text-dark-blue-900">Selecciona transporte:</label>
          <select
            value={medioTransporte}
            onChange={handleMedioTransporteChange}
            className="block px-2.5 pb-1.5 mt-2 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"
            data-tooltip-id="inputTransporte"
            data-tooltip-delay-show={1000}
            data-tooltip-content="Elige el medio de transporte."
          >
            <option value="">Seleccionar...</option>
            <option value="camion">Camión</option>
            <option value="tren">Tren</option>
            <option value="barco">Barco</option>
            <option value="avion">Avión</option>
          </select>
          <Tooltip className="custom-tooltip" id="inputTransporte" place="top" type="dark" effect="solid"/>
        </div>
        <div>
          <label className="text-dark-blue-900">Introduce peso de la carga:</label>
          <div className="relative w-full mt-2 p-2 rounded bg-white flex items-center block px-2.5 pb-1.5 pt-2 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400">
            <input
              type="text"
              value={pesoCarga}
              onChange={handlePesoCargaChange}
              className="w-full rounded flex-grow focus:outline-primary-200 focus:outline-none focus:ring-0"
              style={{ boxSizing: 'border-box' }}
              placeholder="Introduce peso de la carga"
              data-tooltip-id="inputCarga"
              data-tooltip-delay-show={1000}
              data-tooltip-content="Introduce el peso de la carga en kg"
            />
            {/* TODO Convert span into unit selector */}
            <span className="ml-2 text-sm leading-5 text-gray-500 pointer-events-none">Kg</span>
            <Tooltip className="custom-tooltip" id="inputCarga" place="top" type="dark" effect="solid"/>
          </div>
        </div>
      <div className="md:col-span-2 grid grid-cols-2 justify-center gap-2 md:gap-4 w-full">
        <button 
          onClick={handleCalcular} 
          data-tooltip-id="bCalcular"
          data-tooltip-delay-show={1000}
          data-tooltip-content="Calcula las emisiones de CO2 entre las dos poblaciones seleccionadas." 
          className="bg-light-green-500 hover:bg-light-green-400 text-light-green-100 px-1 md:px-2 py-2 md:py-2.5 rounded flex-grow text-sm md:text-base flex gap-x-1 md:gap-x-2 w-full justify-start items-center">
            <Icon icon="ion:calculator" className="w-5" />
            Calcular
            </button>
        
        <button 
          onClick={handleNuevo}
          data-tooltip-id="bNuevo"
          data-tooltip-delay-show={1000}
          data-tooltip-content="Borra el contenido del formulario y los datos de los calculos previos" 
          className="bg-dark-blue-900 hover:bg-dark-blue-700 text-light-green-100 px-1 md:px-2 py-2 md:py-2.5 rounded flex-grow text-sm md:text-base flex gap-x-1 md:gap-x-2 w-full justify-start items-center">
            <Icon icon="eos-icons:content-new" className="w-5" />
            Nuevo Cálculo
            </button>
        <button 
          onClick={handleAñadir} 
          data-tooltip-id="bAñadir"
          data-tooltip-delay-show={1000}
          data-tooltip-content="Añade los resultados actuales a otro previamente existente" 
          className="bg-dark-blue-900 hover:bg-dark-blue-700 text-light-green-100 px-1 md:px-2 py-2 md:py-2.5 rounded flex-grow text-sm md:text-base flex gap-x-1 md:gap-x-2 w-full justify-start items-center">
            <Icon icon="mdi:plus" className="w-5" />
            Añadir Cálculo
            </button>
            <button 
          onClick={handleBorrar} 
          data-tooltip-id="bBorrar"
          data-tooltip-delay-show={1000}
          data-tooltip-content="Borra todos los campos." 
          className="bg-red-800 hover:bg-red-500 text-light-green-100 px-1 md:px-2 py-2 md:py-2.5 rounded flex-grow text-sm md:text-base flex gap-x-1 md:gap-x-2 w-full justify-start items-center">
            <Icon icon="mdi:trash-outline" className="w-5" />
            Borrar
            </button>
        <Tooltip className="custom-tooltip" id="bCalcular" place="top" type="dark" effect="solid"/>
        <Tooltip className="custom-tooltip" id="bBorrar" place="top" type="dark" effect="solid"/>
        <Tooltip className="custom-tooltip" id="bNuevo" place="top" type="dark" effect="solid"/>
        <Tooltip className="custom-tooltip" id="bAñadir" place="top" type="dark" effect="solid"/>
      </div>
      </div>

      {/* Warning message */}
      {showWarning && (
        <p className="my-4 text-red-600">Por favor, rellene todos los campos antes de pulsar calcular.</p>
      )}
      {message && (
            <p className={`my-4 ${messageType === 'error' ? 'text-red-600' : 'text-yellow-600'}`}>{message}</p>
        )}
      {isLoading && (
        <div className="h-full w-full flex justify-center items-center">
          <p className="text-sm text-dark-blue-900">Cargando...</p>
        </div>
      )}
      {/* Muestra los componentes ChartBarAnalysis y MapRoute solo si isCalculated es true */}
      {!isLoading && isCalculated && (
        <>          
          <div className="grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-2 gap-4 w-full h-full py-2">
            <div className="md:col-span-1">
              <ChartBarTransport selectedData={selectedData} />
            </div>
            <div className="md:col-span-1 bg-white p-2.5 rounded-lg">
              <Map
                routes={mapRoutes}
                zoomLevel={zoomLevel}
                height="100%" 
                width="100%"
                showInfo={false}
              />
            </div>
          </div>
          {/**
           * <Map
                routes={[
                  {
                    from: mapCoordenadasOrigen,
                    to: mapCoordenadasDestino,
                    distance: chartDistancia,
                    co2: chartEmisionCO2
                  }
                ]}
                zoomLevel={zoomLevel}
                height="100%" 
                width="100%"
                showInfo={false}
              />
           */}
          {/**
           * <Map
                from={mapCoordenadasOrigen}
                to={mapCoordenadasDestino}
                distance={chartDistancia}
                co2={chartEmisionCO2}
                zoomLevel={zoomLevel}
                height="100%" 
                width="100%"
              />
           */}
          {/**
           * <Map
            from={chartCoordenadasOrigen}
            to={chartCoordenadasDestino}
            distance={chartDistancia}
            co2={chartEmisionCO2}
          />
           */}
           <ComparisonTableAnalysisTransport data={tableData}/>
        </>
      )}
      {/**showResults OR ANOTHER STATE? */}
      {/**
       * {!isLoading && isDataAvailable && showAñadirCalculo && (
          <div className="grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-2 gap-4 w-full h-full py-2">
            <div className="md:col-span-1">
              <ChartBarTransport selectedData={selectedData} />
            </div>
            <div className="md:col-span-1 bg-white p-2.5 rounded-lg">
              <Map
                routes={mapRoutes}
                zoomLevel={zoomLevel}
                height="100%" 
                width="100%"
                showInfo={false}
              />
            </div>
          </div>
      )}
       */}      
    </div>
  );
};

export default CalcCO2;
