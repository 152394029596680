import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate, Link } from "react-router-dom";
import ZeroFoodLogoNobackground from "../helpers/ZeroFood-logo.svg";
import { Icon } from "@iconify/react";
import LogoEu from "../helpers/logo-eu.webp";
import LogoGob from "../helpers/logo-gob.webp";
import LogoGeneralitatValenciana from "../helpers/logo-generalitat-valenciana.svg";
import LogoRPTR from "../helpers/logo-rptr.webp";
import LogoIVACE from "../helpers/logo-ivace.webp";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { handleLogin } = useAuth(); // Get the login function from AuthContext
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // State for spinner visibility
  // const { setIsLoggedIn } = useStateContext();

  /*useEffect(() => {
        if (successMessage) {
            // Redirect to the dashboard after a successful login
            navigate('/dashboard');
        }
    }, [successMessage, navigate]); */

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!email) {
      isValid = false;
      errors.email = "El email es necesario";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      isValid = false;
      errors.email = "El email no es válido";
    }

    if (!password) {
      isValid = false;
      errors.password = "Se necesita contraseña.";
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        // Call the serverless function here
        const response = await fetch("/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });
        console.log("login response", response);
        const data = await response.json();
        if (response.ok) {
          //Clear out error messages
          setErrors({});
          setResponseMessage("");
          //Handle successful login
          //Store the token in localStorage
          //localStorage.setItem('userToken', data.token); //USE HTTP-ONLY COOKIES
          //Show messages for success or failure
          //setIsLoggedIn(true); //Update login state
          setSuccessMessage("Sesión iniciada correctamente");
          //setErrors(prevErrors => ({ ...prevErrors, success: 'Sesión iniciada correctamente' }));
          //errors.success = 'Sesión iniciada correctamente';
          //handleLogin(); //If successful we call login from AuthContext to update the state isAuthenticated
          //navigate('/dashboard'); //Redirect to the dashboard
          //set a delay for showing the success message
          setTimeout(() => {
            handleLogin();
            navigate("/dashboard");
          }, 1000); // Delay the login handling and navigation
        } else {
          // If email not verified
          if (response.status === 403) {
            setResponseMessage(
              "No has activado tu cuenta. Revisa tu cuenta de correo electrónico y busca el email con el enlace de activación."
            );
          } else {
            //console.error('Login error:', data.message); //Reads the message sent from the "backend"
            setResponseMessage(data.message || "Error durante login");
          }
        }
        /**
             * if (response.ok) {
              //Clear out error messages
              setErrors({});
              setResponseMessage('');
              //Handle successful login
              //Store the token in localStorage
              //localStorage.setItem('userToken', data.token); //USE HTTP-ONLY COOKIES
              //Show messages for success or failure
              //setIsLoggedIn(true); //Update login state
              setSuccessMessage('Sesión iniciada correctamente');
              //setErrors(prevErrors => ({ ...prevErrors, success: 'Sesión iniciada correctamente' }));
              //errors.success = 'Sesión iniciada correctamente';
              //handleLogin(); //If successful we call login from AuthContext to update the state isAuthenticated
              //navigate('/dashboard'); //Redirect to the dashboard
              //set a delay for showing the success message
              setTimeout(() => {
                handleLogin();
                navigate('/dashboard');
              }, 1000); // Delay the login handling and navigation
              console.log('Login successful:', data);
              //console.log('Login successMessage:', successMessage);
              //console.log('Login errors.success:', errors.success);
              //console.log('Login errors:', errors);
            } else {
              // Handle login error
              console.error('Login error:', data.message); //Reads the message sent from the "backend"
              setResponseMessage(data.message || 'Error durante login'); //Reads the message sent from the "backend"
            }
             */
      } catch (error) {
        console.error("Request error:", error);
        setResponseMessage("Error conectando con el servidor");
      } finally {
        setLoading(false); // Hide spinner after request completes
      }
      //console.log('Login successMessage:', successMessage);
    }
  };

  return (
    <div className="flex h-[100svh] sm:h-screen w-full flex-col justify-center items-center ">
      <div className="h-full w-full p-4 flex justify-center items-center">
        <div className="flex flex-col h-fit rounded-xl pt-3 md:pt-10 pb-3 md:pb-6 px-3 md:px-8 w-full max-w-md gap-y-6 bg-white shadow-md">
          <div className="flex flex-col gap-y-3 items-center">
            {/**Note: Change of colors and style of login for testing purposes. Colors originally: text-sky-600, For testing: dark-blue-900 */}
            <h1 className="text-dark-blue-950 w-fit text-center font-bold uppercase text-xl md:text-2xl">
              Bienvenido a
            </h1>
            <img
              src={ZeroFoodLogoNobackground}
              alt="zerofood logo"
              className="pointer-events-none object-contain h-16 w-20 md:h-24"
            />
          </div>
          <form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
            <div>
              <div className="relative">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  id="floating_Email"
                  className="block px-1.5 md:px-2.5 pb-1.5 md:pb-2.5 pt-3 md:pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="floating_Email"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                  E-Mail
                </label>
              </div>
              {errors.email && (
                <p
                  id="outlined_error_help"
                  className="mt-2 text-xs text-red-600">
                  {errors.email}
                </p>
              )}
            </div>
            <div>
              <div className="relative">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  id="floating_Password"
                  className="block px-1.5 md:px-2.5 pb-1.5 md:pb-2.5 pt-3 md:pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="floating_Password"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                  Contraseña
                </label>
              </div>
              {errors.password && (
                <p
                  id="outlined_error_help"
                  className="mt-2 text-xs text-red-600">
                  {errors.password}
                </p>
              )}
            </div>

            <input
              type="submit"
              value="Iniciar Sesión"
              className="bg-dark-blue-600 w-full py-2 md:py-3 text-white uppercase font-bold rounded hover:cursor-pointer hover:bg-sky-500 transition-colors"
            />
          </form>
          <div className="h-3 flex justify-center">
            {loading && (
              <Icon
                icon="svg-spinners:180-ring"
                className="text-dark-blue-700"
              />
            )}
            {successMessage && (
              <p className="text-sm text-green-500">{successMessage}</p>
            )}
            {responseMessage && (
              <p className="text-sm text-red-500">{responseMessage}</p>
            )}
            {errors.success && (
              <p className="text-sm text-green-500">{errors.success}</p>
            )}
          </div>
          <nav className="flex flex-col sm:flex-row justify-between items-center md:gap-2 gap-1">
            <p className="text-sm text-center ">
              ¿No tienes una cuenta?
              <Link
                className="inline-block text-center ml-1 text-dark-blue-700 hover:text-dark-blue-500"
                to="/register">
                Regístrate
              </Link>
            </p>
            <Link
              className="block text-center text-dark-blue-700 hover:text-dark-blue-500 w-fit text-sm"
              to="/forgotPassword">
              Olvidé mi contraseña
            </Link>
          </nav>
          {/**<button className='text-sm'>¿Has olvidado tu contraseña?</button>**/}
        </div>
      </div>
      <div className="w-full mt-auto flex flex-col sm:flex-col bg-white justify-center items-center space-y-2 md:space-y-4 p-2 pb-0">
        <p className="text-xs md:text-sm ">
          Esta convocatoria de ayudas esta contemplada dentro del Programa de
          Capacidades para el Crecimiento de la PYME del Componente 13 del Plan
          de Recuperación, Transformación y Resiliencia.
        </p>
        <div className="flex overflow-x-auto w-full flex-row space-x-2 justify-between">
          <div className=" shrink-0">
            <p className="text-sm ">Financiado por:</p>
            <div className="flex  flex-row md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full">
              <img
                src={LogoEu}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
              <img
                src={LogoGob}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
              <img
                src={LogoRPTR}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
            </div>
          </div>
          <div className=" shrink-0">
            <p className="text-sm ">En colaboración con:</p>
            <div className="flex justify-start flex-row md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full">
              <img
                src={LogoGeneralitatValenciana}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
              <img
                src={LogoIVACE}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
