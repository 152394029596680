// src/components/ChartBar.jsx

import React, { useState, useEffect }  from 'react';
import { Bar } from 'react-chartjs-2';
import ReactECharts from 'echarts-for-react';

const ChartBarAnalysis = ({ selectedData  }) => {
  const [chartData, setChartData] = useState({ categories: [], values: [] });
  // console.log("Chartbar props.data", selectedData);
  //console.log("Chartbar props.data consumo agua", selectedData.kpi);
  
  useEffect(() => {
    // Process the selectedData to extract chart information
    const categories = [];
    const values = [];

    selectedData.forEach(item => {
      categories.push(item.nombre); // Using 'nombre' as category
      values.push(item.kpi.consumo_agua2); // Using 'consumo_agua' as value
    });

    setChartData({ categories, values });
  }, [selectedData]);

  const options = {
    title: {
      text: 'Consumo de Agua por Productor',
    },
    xAxis: {
      type: 'category',
      data: chartData.categories,//['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
    },
    yAxis: {
      type: 'value',
    },
    responsive: true,
    series: [
      {
        data: chartData.values,//[120, 200, 150, 80, 70],
        type: 'bar',
        barWidth: '40%', // Set the width of the bars
        itemStyle: {
          color: '#74c475', // Set the color of the bars
        },
      },
    ],
  };

    return (
      <div className="flex w-full grow-1 items-center h-full flex p-2">
      <ReactECharts 
            option={options}
            style={{  height: '100%', width: '100%', }}
          />
      </div>
    );
};

export default ChartBarAnalysis;
