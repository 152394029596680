// src/components/ChartScatter.jsx

import React from 'react';
import ReactECharts from 'echarts-for-react';

const ChartScatter = ({selectedData}) => {
    const categories = selectedData.map(item => item.nombre);
    let scatterData = [];
    console.log('ChartScatter data:', selectedData);

    selectedData.forEach((item, index) => {
        // Add two data points for each item
        scatterData.push({ value: [index, item.kpi.consumo_agua[0]], name: item.nombre });
        scatterData.push({ value: [index, item.kpi.consumo_agua[1]], name: item.nombre });
    });

    /**
     * const options = {
      title: {
        text: 'Comparativa',
      },
      xAxis: {
        name: 'X Axis Label', // Name for the x-axis
        nameLocation: 'middle', // Position of the name, options: 'start', 'middle', or 'end'
        nameGap: 25, // Distance between the name and the axis line
      },
      yAxis: {
        name: 'Y Axis Label', // Name for the y-axis
        nameLocation: 'middle', // Position of the name, options: 'start', 'middle', or 'end'
        nameGap: 45, // Distance between the name and the axis line
        nameRotate: 90, // Rotate the name, useful for the y-axis
      },
      series: [
        {
          symbolSize: 20,
          data: [
            [10.0, 8.04],
            [8.0, 6.95],
            [13.0, 7.58],
            [9.0, 8.81],
            [11.0, 8.33],
          ],
          type: 'scatter',
        },
      ],
    };
     */
    /**
     * const options = {
      title: {
        text: 'Comparativa de Consumo de Agua',
      },
      xAxis: {
        type: 'category',
        name: 'Productores',
        data: categories,
      },
      yAxis: {
        type: 'value',
        name: 'Consumo de Agua (L)',
      },
      series: [
        {
          symbolSize: 20,
          data: scatterData,
          type: 'scatter',
        },
      ],
    };
     */

    const options = {
      title: {
        text: 'Comparativa de Consumo de Agua',
      },
      xAxis: {
        type: 'category',
        nameLocation: 'end',
        nameGap: 0,
        nameTextStyle: {
          align: 'right',
          verticalAlign: 'top',
          padding: [30, 0, 0, 0]
        },
        name: 'Productores',
        data: categories,
      },

      yAxis: {
        nameLocation: 'end',
        nameGap: 0,
        nameTextStyle: {
          align: 'left',
          verticalAlign: 'bottom',
          padding: [0, 0, 15, -40]
        },
          type: 'value',
          name: 'Consumo de Agua (L)',
      },
      responsive: true,
      series: [
        {
          symbolSize: 20,
          data: scatterData,
          type: 'scatter',
        },
      ],
    };

    return (
      <div className="flex w-full grow-1 items-center h-full flex p-2">
      <ReactECharts option={options} style={{  height: '100%', width: '100%', }}/>
        </div>
    );
};

export default ChartScatter;
