import React from 'react';
import { Icon } from '@iconify/react';

const CardKpi = ({ title, number, unit, icon, link, onKpiClick  }) => (
  <div className="group hover:shadow-md transition-shadow bg-white relative overflow-clip flex flex-col gap-y-2 justify-between  p-3 lg:p-4 rounded-lg shadow-sm h-full w-full">
    <div className="flex justify-between">
      <h3 className="w-full text-sm tracking-wide md:white space-nowrap  font-sans uppercase text-black">{title}</h3>
      <Icon className='text-xl -translate-y-0.5 translate-x-px' icon={icon} />
    </div>
    <div className="w-fit font-bold font-serif">
      <span className='h-auto leading-none [font-size:_clamp(2em,8vw,10em)] md:[font-size:_clamp(2em,5vw,10em)] mr-px'>
      {number}
      </span>
      <span className="text-lg lg:text-2xl">{unit}</span></div>
      <div className="group-hover:opacity-100 opacity-0 transition-opacity flex flex-row md:flex-col absolute gap-2 bottom-3 right-3 md:bottom-3 md:right-3">
        {/* MAKE THIS BUTTON CHANGE THE VIEW */}
        <a 
          href="/" 
          onClick={(e) => {
            e.preventDefault(); // Prevent the default anchor action
            onKpiClick(title, number); // Call the handler and pass the KPI data
          }}
          className=' bg-black hover:bg-dark-green-500 transition-colors text-white rounded-md p-1.5 w-fit text-sm'>
          <Icon className="text-lg" icon="octicon:graph-24" />
        </a>
        <a href={link} className=' bg-black hover:bg-dark-green-500 transition-colors text-white rounded-md p-1.5 w-fit text-sm'>
          <Icon className="text-lg" icon="mdi:arrow-right" />
        </a>
      </div>
  </div>
);

export default CardKpi;
