import React from 'react';

const ComparisonTableProducts = (props) => {
    const {data} = props;

    //console.log("ComparisonTableProducts selectedData", data);
    //console.log("ComparisonTableProducts selectedData typeof", typeof data);

    if (!data) {
        return <p>No data available</p>;
    }

    return (
        <table className='standard-table'>
            <tbody>
                <tr>
                    <td>GTIN</td>
                    <td>{data ? data.gtin : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Nombre</td>
                    <td>{data ? data.name : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Código del Artículo</td>
                    <td>{data ? data.articleCode : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Empresa</td>
                    <td>{data ? data.companyId : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Tipo de producto</td>
                    <td>{data ? data.productType : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Descripción</td>
                    <td>{data ? data.description : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Fecha añadida</td>
                    <td>{data ? data.date : 'N/A'}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default ComparisonTableProducts;
