import React, { useState, useEffect, useMemo, useRef } from 'react'
import TableProductsSelectRow from '../tables/TableProductsSelectRow';
import TableProductsSelectRowHigh from '../tables/TableProductsSelectRow';
import ComparisonTableTransports from '../tables/ComparisonTableTransports';
import ComparisonTableCompaniesLocation from '../tables/ComparisonTableCompaniesLocation';
import {Tooltip} from 'react-tooltip';
import { Icon } from '@iconify/react';
import MapLocation from '../charts/MapLocation';
import { useStateContext } from '../contexts/ContextProvider';
import { AddFacility } from '../components';
import { EditFacility } from '../components';
import FinanceSection from '../components/FinanceSection';

const LeftContent = ({ showTable, setShowTable, onSearch, onRowSelect, selectedRowData, setSelectedRowData, onAddFacilityClick, refreshData, setRefreshData, setShowEditFacility, addFacilityComponentRef, editFacilityComponentRef, onDeleteButtonClick }) => {
  const [input, setInput] = useState("");
  const [storedItems, setStoredItems] = useState([]);
  const transportistasJSON = '/data/transportistas.json';
  const [error, setError] = useState(null);
  const [showTooltipInput, setShowTooltipInput] = useState(false);
  const [showTooltipTable, setShowTooltipTable] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]); //Store only the selected items
  //
  const [responseMessage, setResponseMessage] = useState('');
  const [noDataFoundMessage, setNoDataFoundMessage] = useState('');
  const [warningMessageLeft, setWarningMessageLeft] = useState('');
  //
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [gln, setGln] = useState('');
  const [facilityCode, setFacilityCode] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [selectedCoords, setSelectedCoords] = useState(null);
  //
  const [isLoading, setIsLoading] = useState(false);
  //
  const {locations, setLocations, traceLocations} = useStateContext(); //Share data from Instalations (Transports) menu and events
  //
  const timerRef = useRef(null);
  const tableRef = useRef(null);
  const addFacilityRef = useRef(null);
  const editFacilityRef = useRef(null);

  const getRoleName = (gln) => {
    const traceLocation = traceLocations.find(p => p.gln === gln);
    return traceLocation ? traceLocation.role : '';
  }

  const fetchData = async (category) => {
    setIsLoading(true); // Start loading
    setError(null); // Reset previous errors
    try {
      // Call the serverless function here
      const response = await fetch(`/api/getTrustyData?category=${category}`, {
        method: 'GET'
      });
      //console.log("Instalaciones response", response);
      const retrievedJSON = await response.json();
      if (response.ok && Array.isArray(retrievedJSON.data)) {
        const formattedData = retrievedJSON.data.map(item => ({
          ...item,
          role: getRoleName(item.gln),
          date: new Date(item.date).toLocaleDateString('en-GB') // Formats to 'DD/MM/YYYY'
        }));

        setStoredItems(formattedData);
        setFilteredItems(formattedData);       
        setShowTable(retrievedJSON.data.length > 0); //setShowTable(true); 
        setLocations(formattedData);
        //console.log('Instalaciones data received:', retrievedJSON);
      } else {
        console.error('Login error:', retrievedJSON.message); //Reads the message sent from the "backend"
        //setError('No se pudieron recibir datos del servidor'); //Reads the message sent from the "backend"
        setError('Error en el formato de datos o en la respuesta del servidor')
      }
      
    } catch (error) {
      console.error('Request error:', error);
      setResponseMessage('Error conectando con el servidor');
    }
    setIsLoading(false); // End loading
  } //End fetchData 

  useEffect(() => {
    fetchData('facilities');
  }, []);

  //Watches the input state and If it's not empty, reset the selectedRowData to null
  useEffect(() => {
    // Reset selected row data when input changes
    if (input.trim() !== "") {
        setSelectedRowData(null);
        setSelectedCoords(null);
    }
  }, [input, setSelectedCoords, setSelectedRowData]);

  //Triggers fetchData if AddCompany completed succesfully
  useEffect(() => {
    if (refreshData) {
      //console.log("Companies useEffect Refreshing data");
      fetchData('facilities');
      setRefreshData(false); // Reset the refresh trigger
    }
  }, [refreshData, setRefreshData]);

  const handleSearch = () => {
    if (input.trim() !== "") {
      const filteredData = storedItems.filter(item =>
          item.name.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredItems(filteredData);
      setShowTable(filteredData.length > 0);

      // Set message if no data is found
      if (filteredData.length === 0) {
          setNoDataFoundMessage('No se encontraron instalaciones con ese nombre.');
      } else {
          setNoDataFoundMessage('');
      }
    }
    /*if (input.trim() === "") {
      setFilteredItems(storedItems);
      setShowTable(true);
    } else {
      const filteredData = storedItems.filter(item => 
        item.name.toLowerCase().includes(input.toLowerCase())
      );
    console.log("Transports filteredData", filteredData);    
    //setStoredItems(filteredData); 
    setFilteredItems(filteredData);
    //onSearch(filteredData);
    setShowTable(filteredData.length > 0);
    }*/
  }  
  //console.log("storedItems", storedItems);
  const columns = useMemo(() => [
    { Header: "Instalación", accessor: "name" },
    { Header: "Rol", accessor: "role" },
    // 
    // { Header: "Estado", accessor: "status" },
    // { Header: "Fecha", accessor: "date" }
  ], []);

  const handleRowSelect = (rowData) => {
    onRowSelect(rowData);
    // Update selected producer's coordinates
    setSelectedCoords({ lat: rowData.lat, lng: rowData.long });  
    setWarningMessageLeft('');
    //console.log("Facilities leftContent handleRowSelect rowData", rowData);
    //console.log("selectedCoords selectedCoords", selectedCoords);
    //console.log("selectedCoords lat and lng", rowData.coordenadas.latitud, rowData.coordenadas.longitud);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Regular expression to allow only numbers, letters, accents, and spaces
    if (/^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 ]*$/.test(value)) {
        setInput(value);
        if (value.trim() === "") {
            setFilteredItems(storedItems); 
            setShowTable(storedItems.length > 0);
            setSelectedRowData(null); // Reset selected row data
            setSelectedCoords(null);
            setNoDataFoundMessage(''); // Reset no data found message
        } else {
          handleSearch(); // Call handleSearch to filter items based on the input
        }
    }
    //handleSearch();
    setShowTooltipInput(true);
    /*setInput(e.target.value);
    console.log("handleInputChange input", input);
    if (e.target.value.trim() === "") {
      setFilteredItems(storedItems);  // Clear the filtered items
      //onSearch(storedItems); // Pass all stored items to the parent component
      setShowTable(storedItems.length > 0); // Show the table if there are items
      setSelectedRowData(null); // Reset selected row data
      //handleSearch();
    }
    setShowTooltipInput(true);*/
  };  

  const handleMouseEnter = (element) => {
    if (element === 'input') {
      setShowTooltipInput(true); // Show tooltip for input
    } else if (element === 'table') {
      setShowTooltipTable(true); // Show tooltip for table
    }

    timerRef.current = setTimeout(() => {
      if (element === 'input') {
        setShowTooltipInput(false); // Hide tooltip for input
      } else if (element === 'table') {
        setShowTooltipTable(false); // Hide tooltip for table
      }
    }, 2000);
  }

  const onMouseOut = (element) => {
    if (element === 'input') {
      setShowTooltipInput(false); // Hide tooltip for input
    } else if (element === 'table') {
      setShowTooltipTable(false); // Hide tooltip for table
    }
    clearTimeout(timerRef.current);
  }

  //Clears out the data in the RightContent
  //addCompanyRef and editCompanyRef are refs to the buttons añadir and edit company so that they are left out of this method
  /*const handleClickOutside = (event) => {
    //console.log("Companies leftContent Clicked outside the table");
    if (tableRef.current && !tableRef.current.contains(event.target) &&
      (!addFacilityRef.current || !addFacilityRef.current.contains(event.target)) &&
      (!editFacilityRef.current || !editFacilityRef.current.contains(event.target)) &&
      (!addFacilityComponentRef.current || !addFacilityComponentRef.current.contains(event.target)) &&
      (!editFacilityComponentRef.current || !editFacilityComponentRef.current.contains(event.target))) {
      setSelectedRowData(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);*/

  //
  const handleEditFacility = () => {
    //console.log("Facilities lefTContent handleEditFacility selectedRowData", selectedRowData);
    if (selectedRowData) {
      setShowEditFacility(true); // Open the EditFacility component
      setWarningMessageLeft(''); // Clear any existing warning message
    } else {
      setWarningMessageLeft('Para editar una instalación antes ha de seleccionar una.');
      //console.log("Companies lefTContent editar empresaia button clicked");
    }
    /**if (!selectedRowData) {
      setWarningMessage('Para editar una empresa antes ha de seleccionar una.');
      console.log("Companies lefTContent editar empresaia button clicked");
    }**/
    //console.log("Companies handleEditFacility showEditFacilty", showEditFacility);
  };

  return (
    <div className="h-[100svh] md:h-full flex gap-y-4 flex-col p-4 md:p-6 pt-14 md:pt-10 md:border-r border-white w-full md:w-1/2 relative">
      <h1 className="text-3xl lg:text-3xl font-semibold text-dark-blue-600">Instalaciones</h1>
      <p className="text-sm text-dark-blue-900">Busca por nombre instalación</p>
      <div className="flex">
      {/* ! TODO REVISA SI OPINAS QUE ESTO ESTA BIEN CON EL FAKE USER PARA SAFARI */}
      <input id="fake_user_name" name="fake_user[name]" className="-top-[100px] absolute opacity-0" type="text" value="Safari Autofill Me"/>
      <input 
          className="rounded p-2 flex-grow mr-2 focus:outline-primary-200 focus:outline-none focus:ring-0" 
          type="search" 
          placeholder="Escribe el nombre de una instalación..." 
          autoComplete="new-off"
          value={input} 
          onChange={handleInputChange} 
          onKeyUp={e => e.key === 'Enter' && handleSearch()}
          onMouseEnter={() => handleMouseEnter('input')}
          onMouseOut={() => onMouseOut('input')}
          data-tooltip-delay-show={500}
          data-tooltip-id="inputTransportistas"
          data-tooltip-html="Escribe el nombre de una instalación." 
        />
        <button className="bg-dark-blue-900 hover:bg-dark-blue-700 h-full flex justify-center items-center aspect-square text-light-green-100 p-2 rounded" onClick={handleSearch}>
        <Icon icon="material-symbols:search" className='h-5 w-5' /></button>
        <Tooltip className="custom-tooltip" id="inputTransportistas" place="bottom" type="dark" effect="solid" isOpen={showTooltipInput}/>
      </div>
      {noDataFoundMessage && (
                <p className="my-4 text-red-600">{noDataFoundMessage}</p>
      )}
      {isLoading && 
        <div className="h-full w-full flex justify-center items-center">
          <p className="text-sm text-dark-blue-900">Cargando...</p>
        </div>
      }
      {error && (
        <p className="my-4 text-red-600">
        {error}
        </p>
      )}   
      {/**
       * {filteredItems.length === 0 && (
        <p className="my-4 text-red-600">No se encontraron datos coincidentes</p>
      )} 
       */}              
      {/**Similar than the previous but for the compareButton. This also calls the method that opens the Compare window */}
      {/**{showTable && filteredItems.length > 0 && (*/}
      {!isLoading && showTable && (
        <>      
          <div className="rounded-md h-full overflow-clip relative">
            <div 
              data-tooltip-id="tableTransportistas" 
              data-tooltip-content="Selecciona una instalación de la tabla para ver más información." 
              className="h-full  shrink-1 grow-1 overflow-y-auto table-container" 
              onMouseEnter={() => handleMouseEnter('table')}
              onMouseOut={() => onMouseOut('table')}  
            >
              <TableProductsSelectRowHigh 
                data={filteredItems} 
                columns={columns} 
                onRowSelect={handleRowSelect} 
              />                                  
            </div>          
            <Tooltip className="custom-tooltip" id="tableTransportistas" place="top" type="dark" effect="solid" isOpen={showTooltipTable}/>         
          </div>
          {warningMessageLeft && <p className="text-red-500">{warningMessageLeft}</p>}
          {/* {selectedCoords  &&
          <div className="h-full z-10 overflow-clip rounded-md shrink-1 grow-1 map-container">
             <MapLocation from={selectedCoords} height="100%" width="100%"/>
          </div>
          } */}
        </>
      )}
      {warningMessageLeft && <p className="text-red-500">{warningMessageLeft}</p>} 
      {/**
       * <div className="flex justify-end mt-auto gap-x-2 z-[51]">
        <button ref={addFacilityRef} type="submit" className="bg-dark-green-700 hover:bg-dark-green-600 text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded" onClick={onAddFacilityClick}>
        <Icon icon="eos-icons:content-new" className="w- 4 mr-2" />
        <span>Añadir</span>
        </button>
        <button ref={editFacilityRef} type="submit" className={`text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded ${selectedRowData ? "bg-dark-blue-900 hover:bg-dark-blue-700" : "cursor-not-allowed bg-dark-blue-900/50"}`} onClick={handleEditFacility} >
        <Icon icon="material-symbols:edit" className="w- 4 mr-2" />
        <span>Editar</span>
        </button> //disabled={!selectedRowData}  
        <button type="submit" className={`text-light-green-100 text-sm flex sm:text-base items-center py-1.5 px-3 rounded ${selectedRowData ? "bg-red-800 hover:bg-red-500" : "cursor-not-allowed bg-red-800/50"}`} onClick={onDeleteButtonClick}>
        <Icon icon="mdi:trash-outline" className="w- 4 mr-2" />
        <span>Borrar</span>
        </button>
      </div>
       */}
      
    </div>
  );
};

const RightContent = ({selectedData}) => {
  //const selectedCoords = { lat: selectedData.lat, lng: selectedData.long }
  const selectedCoords = selectedData ? { lat: selectedData.lat, lng: selectedData.long } : null;
  //console.log("Facilities RightContent selectedData", selectedData);
  //console.log("Facilities RightContent selectedData", selectedCoords);
  return (
    
    <div className={`flex flex-col gap-y-4 ${selectedData ? ' items-start justify-start ' : 'translate-y-full md:translate-y-0 items-center justify-center'} transition-transform h-[75svh] shadow-lg md:shadow-none bg-light-green-100 md:h-full w-full md:w-1/2 pt-10 p-4 md:p-6 absolute bottom-0 md:top-0 z-50 md:z-0 inset-x-0 md:relative overflow-y-auto`}>
      {!selectedData && (
        <div className="flex flex-col justify-center h-full">
          <p className="text-center text-sm text-dark-blue-900">Busca Instalaciónes o área de operación para obtener más información.</p>
        </div>
      )}
      {selectedData && (
        <>
        <div className="relative w-full h-auto flex flex-col gap-y-4">
          {selectedData.name &&  (
                <h1 className="text-3xl md:text-4xl font-semibold text-dark-blue-900 ">{selectedData.name}</h1>
          )}          
          {/* <button onClick={() => setSelectedRowData(null) } className='absolute top-0 right-0 md:hidden text-xl' ><Icon icon="mdi:close" /></button> */}
          <div className="mb-4 flex gap-y-4 flex-col h-auto w-full">
            <h6>Ubicación</h6>
            {selectedCoords  &&
              <div className="h-48 z-10 overflow-clip rounded-md shrink-1 grow-1 map-container mb-4">
                <MapLocation from={selectedCoords} height="100%" width="100%"/>
              </div>
            }
            <ComparisonTableCompaniesLocation data={selectedData}/>
          </div>       
          <div className="mb-4 w-full">
              <h6>Detalles</h6>
              <ComparisonTableTransports data={selectedData}/>
          </div>    
          </div>
        </>
      )}            
    </div>
  );
};


const Facilities = () => {
  //const harvestData= '/data/harvestData.json'
  const [divHeight, setDivHeight] = useState('calc(100vh)'); // Initial height set to 100vh
  const [filteredData, setFilteredData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showAddFacility, setShowAddFacility] = useState(false);
  const [showEditFacility, setShowEditFacility] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const addFacilityComponentRef = useRef(null);
  const editFacilityComponentRef = useRef(null);
  const [warningMessage, setWarningMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleRefresh = () => {
    //console.log("handleRefresh called in Companies");
    setRefreshData(true); // Trigger a refresh
  };

  useEffect(() => {
    const setDivHeightFunc = () => {
      // Assuming your navbar has an id of 'navbar'
      //Apparently this doesn't work. Findout the name of the navbar.
      const navbar = document.getElementById('Navbar'); 
      if (navbar) {
        const navbarHeight = navbar.offsetHeight;
        const newDivHeight = window.innerHeight - navbarHeight;
        setDivHeight(`${newDivHeight}px`);
      }
    };

    setDivHeightFunc(); // Set initial height
    window.addEventListener('resize', setDivHeightFunc); // Update on resize

    return () => window.removeEventListener('resize', setDivHeightFunc); // Cleanup
  }, []); // Empty dependency array means this useEffect runs once on mount and cleanup on unmount

  const handleSearch = (data) => {
    setFilteredData(data);
  };

  const handleRowSelect = (data) => {
    //console.log("Facilities handleRowSelect row selected:", data);
    setSelectedRowData(data);
  };
  //console.log("Facilities selectedRowData:", selectedRowData);
  //console.log("Facilities showEditFacility:", showEditFacility);

  const handleAddFacility = (facilityName) => {
    // Logic to add the Facility
    //console.log("Adding facility:", facilityName);
    // You might want to fetch or update your companies list here
  };

  //DELETE COMPANY POPUP CONFIRMATION COMPONENT
  const handleDelCompany = async () => {
    if (!selectedRowData) {
      setWarningMessage('Por favor, seleccione una empresa para borrar.');
      return;
    }
  
    try {
      // Call the serverless function to delete the selected company
      const response = await fetch(`/api/delTrustyData?category=facilities&uuid=${selectedRowData.uuid}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      const responseBody = await response.json();
      if (response.ok) {
        setSuccessMessage('Empresa eliminada con éxito.'); //CAMBIAR COLOR O USAR SUCCESS
        setTimeout(() => {
          setRefreshData(true); // Refresh the data to reflect the deletion
          setShowConfirmationDialog(false);
      }, 2000);
      } else {
        console.error('Error eliminando empresa:', responseBody.message);
        setWarningMessage(`Error al eliminar la empresa: ${responseBody.message}`);
      }
    } catch (error) {
      console.error('Request error:', error);
      setWarningMessage('Error conectando con el servidor');
    }
  } //End handleDelCompany

  //Function to handle the delete button click (show confirmation dialog)
  const handleDeleteButtonClick = () => {
    if (!selectedRowData) {
      setWarningMessage('Por favor, seleccione una empresa para borrar.');
      return;
    }
    setShowConfirmationDialog(true);
  };

  //Function to handle the confirmation of deletion
  /*const handleConfirmDeletion = () => {
    handleDelCompany(); // Your existing delete function
    setShowConfirmationDialog(false);
  };*/

  //Function to handle cancellation of deletion
  const handleCancelDeletion = () => {
    setShowConfirmationDialog(false);
  };

  //The confirmation dialog component
  const ConfirmationDialog = () => {
    return (
      <div className="bg-white font-sans flex flex-col rounded-t-xl py-8 px-4 md:px-8 w-full gap-y-6 ">
        <p>¿Está seguro de que desea borrar la empresa seleccionada?</p>
        {successMessage && <p className="text-xs text-green-500">{successMessage}</p>}
          {warningMessage && <p className="text-xs text-red-500">{warningMessage}</p>}                                      
          <div className="flex justify-between gap-x-2 mt-2">
              <button className="flex justify-center bg-dark-green-600 hover:bg-dark-green-500 text-white px-4 py-2 rounded-md w-full" onClick={handleDelCompany}>Aceptar</button>
              <button className="flex justify-center bg-red-700 hover:bg-red-500 text-white px-4 py-2 rounded-md w-full" onClick={handleCancelDeletion}>Cancelar</button>
          </div>     
        {/* <div className="flex justify-between mr-4 my-2">
          <button className="flex justify-center bg-blue-500 text-white px-4 py-2 mx-2 rounded-md w-full" onClick={handleDelCompany}>Aceptar</button>
          <button className="flex justify-center bg-blue-500 text-white px-4 py-2 mx-2 rounded-md w-full" onClick={handleCancelDeletion}>Cancelar</button>
        </div>
        {successMessage && <p className="text-green-500">{successMessage}</p>}
        {warningMessage && <p className="text-red-500">{warningMessage}</p>} */}
      </div>
    );
  };

  //<BasicTable jsonFile={harvestData} columns={COLUMNS}/>
  return (
    <div className="flex relative flex-col w-full md:overflow-clip h-full">
      <div className="flex h-[calc(100svh_-_99px)] flex-col md:flex-row">
      <LeftContent 
        onSearch={handleSearch} 
        showTable={showTable} 
        setShowTable={setShowTable} 
        onRowSelect={handleRowSelect}
        selectedRowData={selectedRowData}
        setSelectedRowData={setSelectedRowData}
        onAddFacilityClick={() => setShowAddFacility(true)}
        setShowEditFacility={setShowEditFacility}
        showEditFacility={showEditFacility}
        addFacilityComponentRef={addFacilityComponentRef}
        editFacilityComponentRef={editFacilityComponentRef}
        onDeleteButtonClick={handleDeleteButtonClick}
        warningMessage={warningMessage}
        setWarningMessage={setWarningMessage}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />

      <button onClick={() => setSelectedRowData(null) } className={`${(selectedRowData) ? 'opacity-100 md:hidden' : 'opacity-0 pointer-events-none'} cursor-default transition-opacity absolute  inset-0 bg-black/30  z-40`}></button>
      
      <RightContent selectedData={selectedRowData}/>
      
      {/* <button onClick={() => {setShowAddFacility(false); setShowEditFacility(false); handleCancelDeletion() }} className={`${(showAddFacility || showEditFacility) ? 'opacity-100' : 'opacity-0 pointer-events-none'} cursor-default transition-opacity absolute  inset-0 bg-black/30 z-40`}></button> */}
      
      {/* <div className={`${(showAddFacility) ? 'translate-y-0' : 'translate-y-full pointer-events-none select-none'} duration-300 absolute bottom-0 left-0 w-full md:w-1/2 z-[70] shadow-xl`}>
        <AddFacility 
          onClose={() => setShowAddFacility(false)} 
          onAddCompany={handleAddFacility}      
          onRefresh={handleRefresh}                
        />
      </div> */}
      {/* <div ref={editFacilityComponentRef} className={`${(showEditFacility) ? 'translate-y-0' : 'translate-y-full pointer-events-none select-none'} duration-300 absolute bottom-0 left-0 w-full md:w-1/2 z-[1000] shadow-xl`}>
        <EditFacility 
          onClose={() => setShowEditFacility(false)} 
          //onEditCompany={handleEditCompany}  
          //onEditCompanyClick={handleEditCompany}
          onRefresh={handleRefresh} // Pass handleRefresh to EditCompany           
          data={selectedRowData}
        />
      </div> */}
      {/* <div className={`${(showConfirmationDialog) ? 'translate-y-0' : 'translate-y-full pointer-events-none select-none'} duration-300 absolute bottom-0 left-0 w-full md:w-1/2 z-[70] shadow-xl`}>
          <ConfirmationDialog 
              onConfirm={handleDelCompany}
              onCancel={handleCancelDeletion}
          />
        </div> */}
      </div>
      <FinanceSection />
    </div>
  )
}

export default Facilities