import React, { useState, useEffect } from 'react'
import BasicTable from '../tables/BasicTable';
import ChartLine from '../charts/ChartLine';
import { COLUMNS_LOTS } from '../columns/columnLotsDashboard'
import { COLUMNS } from '../columns/column'
import ChartPie from '../charts/ChartPie';
import Map from '../charts/map';
import { CardKpi } from '../components/';
import { ChartSelection } from '../components/';
import { RankingSelection } from '../components/';
import { useStateContext } from '../contexts/ContextProvider';

const Dashboard = () => {
  const { companyCount, facilityCount, productCount, eventsCount } = useStateContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTable, setShowTable] = useState('products'); // 'products' or 'lots'
  //const [zoomLevel, setZoomLevel] = useState(13); // You can set the default zoom level as needed
  //const harvestData = '/data/harvestData.json';
  //const batches = '/data/batches.json';
  
  // Add state to hold the selected KPI data for the chart
  const [selectedKpiData, setSelectedKpiData] = useState(null);
  const [selectedKpiName, setSelectedKpiName] = useState("");

  useEffect(() => {
    const currentCounts = { facilityCount, companyCount, productCount, eventsCount };
    updateHistoricalData(currentCounts);
  }, []);
  
  // Handler to switch between KPIs
  const handleKpiClick = (kpiName, currentCount) => {
    let historicalData = JSON.parse(localStorage.getItem('historicalData')) || [];
    console.log("Dashboard handleKpiClick",historicalData);
    const todayString = new Date().toISOString().split('T')[0]; // Today's date in YYYY-MM-DD format
  
    const kpiPropertyMap = {
      'Instalaciones': 'facilityCount',
      'Empresas': 'companyCount',
      'Productos': 'productCount',
      'Eventos': 'eventsCount'
    };
  
    const kpiProperty = kpiPropertyMap[kpiName];
  
    if (!kpiProperty) {
      console.error(`Invalid KPI name: ${kpiName}`);
      return;
    }

    // Transform the historical data based on the clicked KPI
    const transformedData = historicalData.map(item => {
      return {
        date: item.date,
        value: item[kpiProperty] // dynamically get the KPI value based on kpiName
      };
    });

    // Check if today's data already exists in historicalData
    if (!historicalData.find(item => item.date === todayString)) {
      // Add today's count for the selected KPI
      historicalData.push({
        date: todayString,
        [kpiProperty]: currentCount
    });

    transformedData.push({
      date: todayString,
      value: currentCount
    });
  }

    // If there's only one data point, add dummy data points for the day before and after
    if (transformedData.length === 1) {
      const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
      const actualDate = new Date(transformedData[0].date);
      const dayBefore = new Date(actualDate.getTime() - oneDay).toISOString().split('T')[0];
      const dayAfter = new Date(actualDate.getTime() + oneDay).toISOString().split('T')[0];

      transformedData.unshift({
        date: dayBefore,
        value: transformedData[0].value // or some variation of it
      });

      transformedData.push({
        date: dayAfter,
        value: transformedData[0].value // or some variation of it
      });
    }

    // Update state and local storage
    setSelectedKpiData(transformedData);
    setSelectedKpiName(kpiName);
    localStorage.setItem('historicalData', JSON.stringify(historicalData));
  };
  
  //calculates the number of days between the last data entry and the current date.
  const daysSinceLastUpdate = (historicalData) => {
    if (historicalData.length === 0) {
      return Infinity; // If there's no data, return a large number to signify a long time has passed
    }
  
    const lastEntryDate = new Date(historicalData[historicalData.length - 1].date); // Get the date of the last entry
    const today = new Date(); // Today's date
    const diffTime = Math.abs(today - lastEntryDate); // Difference in time in milliseconds
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    console.log("Dashboard diffDays",diffDays);

    return diffDays;
  };
  

  const updateHistoricalData = (currentCounts) => {
    let historicalData = JSON.parse(localStorage.getItem('historicalData')) || [];
  
    const todayString = new Date().toISOString().split('T')[0]; // Today's date in YYYY-MM-DD format
  
    if (historicalData.length === 0 || daysSinceLastUpdate(historicalData) >= 7) {
      // Add new data point
      const newDataPoint = {
        date: todayString,
        ...currentCounts
      };
  
      console.log("Dashboard newDataPoint",newDataPoint);
      historicalData.push(newDataPoint);
  
      localStorage.setItem('historicalData', JSON.stringify(historicalData));
    }
  };
  

  const handleTableSwitch = (tableType) => {
    setShowTable(tableType);
  };
  //TEST: data for the map 
  // You might want to dynamically fetch these details based on user input or another source
  const from = [ 51.505, -0.09 ];
  const to = [ 51.515, -0.1 ];
  const distance = 100; // Example distance
  const co2 = 20; // Example CO2 emission
  const zoomLevel = 13;

  return (
    <div className='relative flex flex-col  h-full p-6 pt-14 md:pt-10 gap-y-4'>
      <h1 className="transition-all items text-3xl lg:text-3xl font-semibold text-dark-blue-600">Bienvenidos!</h1>
      {/* TODO NOMBRE */}
      <div className='grid h-full w-full grid-cols-1 md:grid-cols-4 gap-4 '>
      {/**className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4 " 
       * flex flex-row rounded-lg gap-4 mb-4
       * NOTE: lg:items-center lg:justify-center makes the charts being in the center.
       * Try different configurations. Also try for smaller screen sizes.
       * ERROR: With bigger than sm sizes scrolling down makes the navbar disappear on top.
      */}
      <div className="col-span-1 md:col-span-3 min-h-[24rem] md:min-h-[16rem] overflow-clip">
        {selectedKpiData ? (
          <ChartLine data={selectedKpiData} title={selectedKpiName} value={selectedKpiName}/>
        ) : (
          <ChartSelection/>
        )}
      </div>
      <div className="col-span-1 row-start-1 md:row-auto grid grid-cols-2 md:grid-cols-1 gap-4">
          <CardKpi title="Instalaciones" number={facilityCount} unit="" icon="ic:round-factory" link="Facilities" onKpiClick={handleKpiClick}/>
          <CardKpi title="Empresas" number={companyCount} unit="" icon="mdi:company" link="Companies" onKpiClick={handleKpiClick}/>
          <CardKpi title="Productos" number={productCount} unit="" icon="gridicons:product" link="Products" onKpiClick={handleKpiClick}/>
          <CardKpi title="Eventos" number={eventsCount} unit="" icon="material-symbols:event-note" link="Events" onKpiClick={handleKpiClick}/>             
      </div>          
      {/* <div className="md:col-span-2 bg-white p-2.5 min-h-[20rem] h-full rounded-lg overflow-hidden shadow-sm">
        <Map routes={[{from: from, to: to, distance: distance, co2: co2}]} zoomLevel={zoomLevel} height="100%" width="100%" showInfo/>
      </div>
        <RankingSelection/>  */}
    </div>
    </div>
  )
}

export default Dashboard