// src/components/ChartPie.jsx

import React from 'react';
import { Pie } from 'react-chartjs-2';
import ReactECharts from 'echarts-for-react';

const ChartPie = () => {
    /*const options = {
      title: {
        text: 'Consumo de electricidad',
        subtext: ' por fase',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'vertical',
        left: 'left',
      },
      series: [
        {
          name: 'Electricity Usage',
          type: 'pie',
          radius: '50%',
          data: [
            { value: 1048, name: 'Production' },
            { value: 735, name: 'Transport' },
            { value: 580, name: 'Storage' },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };*/
    const options = {      
      tooltip: {
        trigger: 'item',        
      },
      responsive: true,

      legend: {
        orient: 'horizontal',
        bottom: 'bottom'
      },
      series: [
        {
          name: 'Environmental Impact',
          type: 'pie',
          radius: '50%',
          data: [
            { value: 40, name: 'Producción', detail1: 'Consumo eléctrico (producción en invernaderos)', detail2:'Emisiones de GEI (Consumo eléctrico)'},
            { value: 30, name: 'Transporte',detail1: 'Consumo de combustible', detail2:'Emisiones de CO2' },
            { value: 20, name: 'Almacenaje',detail1: 'Consumo eléctrico (Tiempo de refrigeración)'},
            { value: 10, name: 'Empaquetado',detail1: 'Materiales usados', detail2:'Residuos generados' }
          ],
          label: {
            formatter: params => {
              const { name, value, percent, data } = params;
              return `{name|${params.name}}: {percent|${percent}%}\n{details1|${data.detail1}}\n{details2|${data.detail2}}`;
            },
            rich: {
              name: {
                color: '#333',
                fontSize: 16,
                align: 'center',
              },
              value: {
                color: '#333',
                fontSize: 16,
                align: 'center',
              },
              percent: {
                color: '#333',
                fontSize: 16,
                align: 'center',
              },
              details1: {
                color: '#666',
                fontSize: 12,
                align: 'left',
              },
              details2: {
                color: '#666',
                fontSize: 12,
                align: 'left',
              },              
            }            
          },
          labelLine: {
            show: true,
            length: 20,
            length2: 30
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };


    return (
      <div className="flex w-full grow-1 items-center h-full flex p-2">
      <ReactECharts option={options}             style={{  height: '100%', width: '100%', }}
 />
        </div>
    );
};

export default ChartPie;
