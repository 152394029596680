export const COLUMNS_PRODUCERS = [
    {
        Header: 'Nombre',
        accessor: 'nombre'
    },
    {
        Header: 'Producto',
        accessor: 'producto'
    },
    {
        Header: 'Localidad',
        accessor: 'localidad'
    },
    {
        Header: 'País',
        accessor: 'pais'
    },
    
];
