import React, {createContext, useContext, useState, useEffect} from 'react';
import fetchTrusty from '../helpers/fetchTrusty';

const StateContext = createContext();

/**We define the initial state of the chat, cart and notification in the navbar at the top. If they are opened or closed. */
const initialState = {
    help: false,
    settings: false,
    userProfile: false,
    notification: false,
}

export const ContextProvider = ({children}) => {
    const [activeMenu, setActiveMenu] = useState (true);
    const [isClicked, setIsClicked] = useState (initialState);
    /**on click we take the initial state (everything's false) and we change only the value of the object we clicked */
    const handleClick = (clicked) => {setIsClicked({ ...initialState, [clicked]:true})};
    /**set screen size for handling small size screens */
    const [screenSize, setScreenSize] = useState (undefined);
    const [activeAppbar, setActiveAppbar] = useState (true);
    const [activeLoginForm, setActiveLoginForm] = useState (false);
    const [showIconsOnly, setShowIconsOnly] = useState(false);
    //
    const [companies, setCompanies] = useState([]); //Share data from Instalations (Transports) menu and events
    const [locations, setLocations] = useState([]); //Share data from Instalations (Transports) menu and events
    const [traceLocations, setTraceLocations] = useState([]); 
    const [products, setProducts] = useState([]); //Share data from Productos menu and events
    const [events, setEvents] = useState([]); 
    //
    const [companyCount, setCompanyCount] = useState(0);
    const [facilityCount, setFacilityCount] = useState(0);
    const [productCount, setProductCount] = useState(0);
    const [eventsCount, setEventsCount] = useState(0);
    //
    //const [refreshData, setRefreshData] = useState(false);
    // State variables for user profile
    const [userProfile, setUserProfile] = useState({
        name: '',
        email: '',
        profession: ''
    });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    //
    const [CO2Transport, setCO2Transport] = useState(null);
    const [CO2Packaging, setCO2Packaging] = useState(null);
    const [CO2TransportMx, setCO2TransportMx] = useState(null);
    const [CO2PackagingMx, setCO2PackagingMx] = useState(null);
    const [CO2absorption, setCO2absorption] = useState(null);
    //Stores CO2 data for different events
    const [co2DataCache, setCo2DataCache] = useState({}); 

    //Fetch data from instalaciones and productos. It'll be used in eventos to show the names
    //of locations and products instead of the GTIN number.
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetching data for locations and products
                const companiesData = await fetchTrusty('companies');
                const traceLocationsData = await fetchTrusty('traceFacilities');
                const locationsData = await fetchTrusty('facilities');
                const productsData = await fetchTrusty('products');
                const eventsData = await fetchTrusty('events');

                //The data from fethTrusty is returned as an object, containing the status and the data.
                //Here he check that's not empty and we set only the data
                // Check if the response has data and is valid
                if (companiesData.status && companiesData.data) {
                    setCompanies(companiesData.data);
                    setCompanyCount(companiesData.data.length);
                }

                if (traceLocationsData.status && traceLocationsData.data) {
                    setTraceLocations(traceLocationsData.data);
                }

                if (locationsData.status && locationsData.data) {
                    setLocations(locationsData.data);
                    setFacilityCount(locationsData.data.length);
                }

                if (productsData.status && productsData.data) {
                    setProducts(productsData.data);
                    setProductCount(productsData.data.length);
                }
                if (eventsData.status && eventsData.data) {
                    setEvents(eventsData.data);
                    setEventsCount(eventsData.data.length);
                }
                //console.log("ContextProvider companiesData", companiesData);
                //console.log("ContextProvider traceLocationsData", traceLocationsData);
                //console.log("ContextProvider locationsData", locationsData);
                //console.log("ContextProvider productsData", productsData);
                //console.log("ContextProvider eventsData", eventsData);

                // Update the states
                //setLocations(locationsData);
                //setProducts(productsData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    //Cache session to store and retrieve CO2 data from events
    /**
     * Note: creating eventKey
     * // Assume you have event details
    const sourceLocation = "LocationA";
    const destinationLocation = "LocationB";
    const quantity = 100;

    // Create an eventKey based on the above details
    const eventKey = `CO2-${sourceLocation}-${destinationLocation}-${quantity}`;

    // To cache CO2 data for this event
    const co2Value = calculateCO2ForEvent(sourceLocation, destinationLocation, quantity);
    cacheCO2Data(eventKey, co2Value);

    // To retrieve CO2 data from cache for this event
    const cachedCO2Data = getCO2DataFromCache(eventKey);
    if (cachedCO2Data) {
        // Use cached data
    } else {
        // Calculate and cache CO2 data if not already cached
    }
     */
    // Function to set CO2 data
    const cacheCO2Data = (eventKey, co2Value) => {
        sessionStorage.setItem(eventKey, JSON.stringify(co2Value)); // Cache in sessionStorage
        setCo2DataCache(prevData => ({ ...prevData, [eventKey]: co2Value })); // Update state
    };

    // Function to get CO2 data from cache
    const getCO2DataFromCache = (eventKey) => {
        if (co2DataCache[eventKey]) {
            return co2DataCache[eventKey]; // Return from state cache
        }
        
        const cachedData = sessionStorage.getItem(eventKey);
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            setCo2DataCache(prevData => ({ ...prevData, [eventKey]: parsedData })); // Update state cache if not present
            return parsedData; // Return parsed data
        }
        return null; // Return null if not found
    };

    //Fetch user's Data on startup. It doesn't load the user's profile just after login in. 
    /*useEffect(() => {
        if (isLoggedIn) {
            const fetchUserProfile = async () => {
                try {
                    const response = await fetch('/api/handleUserProfile?action=get', {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if (response.ok) {
                        const data = await response.json();  // Parse the JSON data from the response
                        if (data) {
                            setUserProfile({
                                name: data.name,
                                email: data.email,
                                profession: data.profession
                            });
                        }
                        console.log("ContextProvider User's data", data);
    
                    } else {
                        // Handle cases where the response is not ok
                        console.error('Error fetching user profile:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchUserProfile();
        }        
    }, [isLoggedIn]);*/
    
    /*const handleRefresh = () => {
        setRefreshData(true);
    };*/

    return (
        <StateContext.Provider 
            value={{
                activeMenu, 
                setActiveMenu, 
                isClicked,
                setIsClicked,
                handleClick,
                screenSize,
                setScreenSize,
                activeAppbar,
                setActiveAppbar,
                activeLoginForm,
                setActiveLoginForm,
                showIconsOnly,
                setShowIconsOnly,
                companies,
                setCompanies,
                traceLocations,
                setTraceLocations,
                locations,
                setLocations,
                products,
                setProducts,
                events,
                setEvents,
                userProfile,
                setUserProfile,
                companyCount,
                facilityCount,
                productCount,
                eventsCount,
                CO2Transport,
                setCO2Transport,
                CO2Packaging,
                setCO2Packaging,
                CO2TransportMx,
                setCO2TransportMx,
                CO2PackagingMx,
                setCO2PackagingMx,
                co2DataCache,
                cacheCO2Data,
                getCO2DataFromCache,
                CO2absorption,
                setCO2absorption 
            }}>                                   
            {children}
        </StateContext.Provider>
    )
}

/**We use the activeMenu inside the components of the app by exporting the useStateContext. 
 * It's a function that returns the calls to the useContext but we pass which context will we wanna use.
 * Nemo tip: "Give me the data from the context (useStateContext) by using the context (useContext) and we specify which one (StateContext)"
 * In this app we'll have one context but in bigger applications we can have multiple context providers.
*/
export const useStateContext = () => useContext (StateContext);