import React from 'react'
import LogoEu from "../helpers/logo-eu.webp";
import LogoGob from "../helpers/logo-gob.webp";
import LogoGeneralitatValenciana from "../helpers/logo-generalitat-valenciana.svg";
import LogoRPTR from "../helpers/logo-rptr.webp";
import LogoIVACE from "../helpers/logo-ivace.webp";

const FinanceSection = () => {
  return (
      <div className="w-full mt-auto flex flex-col sm:flex-col bg-white justify-center items-center space-y-2 md:space-y-4 p-2 pb-0">
      <div className="flex overflow-x-auto w-full flex-row space-x-2 justify-between">
          <div className=" shrink-0">
            <p className="text-sm ">Financiado por:</p>
            <div className="flex  flex-row md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full">
              <img
                src={LogoEu}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
              <img
                src={LogoGob}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
              <img
                src={LogoRPTR}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
            </div>
          </div>
          <div className=" shrink-0">
            <p className="text-sm ">En colaboración con:</p>
            <div className="flex justify-start flex-row md:flex-row space-y-2 md:space-y-0 md:space-x-4 w-full">
              <img
                src={LogoGeneralitatValenciana}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
              <img
                src={LogoIVACE}
                alt="Logo de la Unión Europea"
                className="h-12 md:h-16 object-contain shrink-0"
              />
            </div>
          </div>
        </div>
      </div>
)};

export default FinanceSection