// src/pages/Analysis.jsx

import React, { useState } from 'react';
import SearchBar from '../components/SearchBar';
import ChartBar from '../charts/ChartBar';
import ChartBarAnalysis from '../charts/ChartBarAnalysis';
import ChartScatter from '../charts/ChartScatter';
import ChartPie from '../charts/ChartPie';
import Map from '../charts/map';
import ComparisonTable from '../tables/ComparisonTable';
//import { COLUMNS_COMPARE } from '../columns/columnCompareProd'
import { COLUMNS_PRODUCERS } from '../columns/columnAnalysisProducers';
import { FaCaretLeft } from 'react-icons/fa';
import {Tooltip} from 'react-tooltip';
import { Icon } from '@iconify/react';

const CompareProducers = ({storedItems, selectedData, onBack, title, setSelectedData }) => {
    
    //TEST: data for the map 
    // You might want to dynamically fetch these details based on user input or another source
    const from = { lat: 51.505, lng: -0.09 };
    const to = { lat: 51.515, lng: -0.1 };
    const distance = 100; // Example distance
    const co2 = 20; // Example CO2 emission
    //
    const compareProducers = '/data/compareProducers.json';
    const chartBarExample = '/data/chartBarExample.json';
    const [selectedRows, setSelectedRows] = useState([]);

    const handleSelectionChange = (newSelection) => {
        setSelectedRows(newSelection);
    };

    const processData = (data) => {
        // Implement your data processing logic here
        console.log("CompareProducers processData data", data);
        /*const processedData = data.map(row => {
            // Transform each row as required
            return {
                nombre: row.nombre,
                consumo_agua: row.kpi.consumo_agua,

            };
        });*/

        return data;
    };

    const handleCompareClick = () => {
        // Aquí puedes procesar los datos seleccionados y actualizar las gráficas/tablas
        // Por ejemplo: actualizar el estado de selectedData con los datos seleccionados
        const processedData = processData(selectedRows); // Use the selectedRows to process data
        setSelectedData(processedData); // Update the state with processed 
    };

    //console.log("CompareProducers selectedData", selectedData);

    //flex flex-col md:flex-col lg:flex-row items-start lg:items-center lg:justify-between space-y-4 lg:space-y-0 lg:space-x-4 w-full
    /**
     * <div className="grid grid-col-2 gap-4 p-8">
            <div className="mb-4">
                <SearchBar />
            </div>
            <div className="grid lg:grid-cols-2 gap-4">
                <div>
                    <ChartBarAnalysis selectedData={selectedData}/>
                    <ChartScatter />
                </div>
                <div>
                    <ComparisonTable data={selectedData}/>
                </div>
            </div>
        </div>
     */
    /**
     * <div className="flex items-center justify-start">
                <button onClick={onBack} className="back-button pr-2 bg-[#f4f8ed] rounded-xl"><FaCaretLeft className="text-xl"/></button>
                <SearchBar 
                    data={storedItems} // Asumiendo que selectedData contiene los datos a mostrar
                    columns={COLUMNS_PRODUCERS} // Asumiendo que estas son las columnas para TableProductsCheckbox
                    onSelectionChange={handleSelectionChange}
                    onCompareClick={handleCompareClick}
                />
                <h1 className="text-2xl font-bold text-[#005571] mr-8 mt-8">Comparativa Productores</h1>
            </div>   
     */
    return (
      
        <div className="h-full flex flex-col gap-4 p-6 w-full">

            <div className="flex justify-between items-center w-full">
                <div className="flex items-center">
                    <div className="py-2 pr-1">
                    <button onClick={onBack} className="back-button p-2.5 bg-white rounded-md" data-tooltip-id="bBack" data-tooltip-content="Vuelve a la pantalla anterior">
                        {/* <FaCaretLeft className="text-xl"/> */}
                        <Icon icon="bxs:left-arrow" />
                    </button>
                    </div>
                    <SearchBar 
                        data={storedItems}
                        columns={COLUMNS_PRODUCERS}
                        onSelectionChange={handleSelectionChange}
                        onCompareClick={handleCompareClick}
                    />
                    <Tooltip className="custom-tooltip" id="bBack" place="right" type="dark" effect="solid"/>
                </div>
                <h1 className="text-2xl font-bold text-[#005571]">Comparativa Productores</h1>
            </div>   
            <div className="h-full grid grid-rows-2 grid-cols-1 md:grid-cols-2 gap-4">
                <div className=" rounded-xl bg-white p-4">
                    <ChartBarAnalysis selectedData={selectedData}/>
                </div>
                <div className="flex items-center rounded-xl bg-white p-4">
                    <ChartScatter selectedData={selectedData}/>
                </div>
                <div className="md:col-span-2 h-full">
                    <ComparisonTable data={selectedData}/>
                </div>
            </div>
        </div>
    );
};

export default CompareProducers;
