import React from 'react';
import { Icon } from '@iconify/react';

export const links = [
  {
    links: [
      {
        name: 'Dashboard',
        menuNameSpa: 'Inicio',
        icon: <Icon icon="ic:round-dashboard" />,
      },
      {
        name: 'Companies',
        menuNameSpa: 'Empresas',
        icon: <Icon icon="mdi:company" />,
      },
      {
        name: 'Facilities',
        menuNameSpa: 'Instalaciones',
        icon: <Icon icon="ic:round-factory" />,
      },
      {
        name: 'Products',
        menuNameSpa: 'Productos',
        icon: <Icon icon="gridicons:product" />,
      },
      {
        name: 'Events',
        menuNameSpa: 'Eventos',
        icon: <Icon icon="material-symbols:event-note" />,
      },
      {
        name: 'Analysis',
        menuNameSpa: 'Kit Didáctico-Simulador',
        icon: <Icon icon="mdi:calculator" />,
      },

      // Transporters
      // icon: <Icon icon="bxs:truck" />,

      // Track and Trace
      // icon: <Icon icon="tabler:timeline-event-exclamation" />,

      // Analysis
      // icon: <Icon icon="mdi:graph-box" />
    ],
  },
];