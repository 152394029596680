// src/components/ChartBar.jsx

import React, { useState, useEffect }  from 'react';
import { Bar } from 'react-chartjs-2';
import ReactECharts from 'echarts-for-react';

const ChartBar = ({ jsonFilePath  }) => {
  //console.log("Inside chartbar");
  const [chartData, setChartData] = useState({ categories: [], values: [] });

  useEffect(() => {
    fetch(jsonFilePath)
      .then((response) => {
        //console.log("ChartBar response", response);
        return response.json()
      })      
      .then((data) => setChartData(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, [jsonFilePath]);

  const options = {
    title: {
      text: chartData.description,
    },
    xAxis: {
      type: 'category',
      data: chartData.categories,//['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
    },
    yAxis: {
      type: 'value',
    },
    responsive: true,
    series: [
      {
        data: chartData.values,//[120, 200, 150, 80, 70],
        type: 'bar',
        barWidth: '40%', // Set the width of the bars
        itemStyle: {
          color: '#74c475', // Set the color of the bars
        },
      },
    ],
  };

    return (
        <div className="flex w-full grow-1 items-center h-full flex p-2">
          <ReactECharts 
            option={options} 
            style={{  height: '100%', width: '100%', }}
          />
        </div>
    );
};

export default ChartBar;
