import React from 'react';
import ReactECharts from 'echarts-for-react';

const ChartBarStack = ({ CO2Transport, CO2Packaging, CO2TransportMx, CO2PackagingMx }) => {
    // Data and configurations
  /*const rawData = [
    [100, 302],
    [320, 132],
    [220, 182],
    [150, 212],
    [820, 832]
  ];
  
  const totalData = rawData[0].map((_, idx) => rawData.reduce((acc, val) => acc + val[idx], 0));*/
  
  const rawData = [
    [CO2Transport, CO2TransportMx],
    [CO2Packaging, CO2PackagingMx]
];

  const grid = {
    left: 100,
    right: 100,
    top: 70,
    bottom: 50
  };

const colors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00']; // Red, Green, Blue, Yellow
  
  /*const series = [
    'Direct',
    'Mail Ad',
    'Affiliate Ad',
    'Video Ad',
    'Search Engine'
  ].map((name, sid) => ({
    name,
    type: 'bar',
    stack: 'total',
    barWidth: '60%',
    label: {
      show: true,
      formatter: (params) => Math.round(params.value * totalData[params.dataIndex] * 1000) / 10 + '%'
    },
    data: rawData[sid]
  }));*/

/*const series = [
    'Transporte (Local)',
    'Empaquetado (Local)',
    'Transporte (Mexico)',
    'Empaquetado (Mexico)'*/
/*const series = [
    {
        name: 'CO2 Transporte (Local)',
        type: 'bar',
        stack: 'total',
        data: rawData[0],
        itemStyle: {
            color: colors[0], // Red
        },
    },
    {
        name: 'CO2 Empaquetado (Local)',
        type: 'bar',
        stack: 'total',
        data: rawData[1],
        itemStyle: {
            color: colors[1], // Green
        },
    },
    {
        name: 'CO2 Transport (Importación)',
        type: 'bar',
        stack: 'total',
        data: rawData[2],
        itemStyle: {
            color: colors[2], // Green
        },
    },
    {
        name: 'CO2 Packaging (Importación)',
        type: 'bar',
        stack: 'total',
        data: rawData[3],
        itemStyle: {
            color: colors[3], // Green
        },
    }    */
const series = [
    'Transporte',
    'Empaquetado'
].map((name, sid) => ({
    name,
    type: 'bar',
    stack: 'total',
    barWidth: '60%',
    label: {
        show: true,
        position: 'inside',
        formatter: (params) => `${params.value} kg CO2`
    },
    data: rawData[sid]
}));
  
  const option = {
    legend: {
      selectedMode: false
    },
    grid,
    yAxis: {
      type: 'value',
      name: 'Emisiones de CO2 (kg CO2)'
    },
    xAxis: {
      type: 'category',
      data: ['Local', 'Importado']
    },
    series
  };

  return (
    <div className='bg-white p-1 rounded-md'>
        <ReactECharts option={option}/>
    </div>
  )
}

export default ChartBarStack