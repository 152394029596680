import React from 'react';

const ComparisonTableProductsTraceability = (props) => {
    const { data } = props;
    //console.log("ComparisonTableProductsTraceability data", data);
    if (!data) {
        return <p>No hay datos de trazabilidad</p>;
    }

    return (
        <table className='standard-table'>
            <tbody>
                <tr>
                    <td clasName='p-0'>
                        {/**
                         * {data.media[0].mediaCropped !== undefined &&
                         */}
                        {/**
                         * {data.media &&
                            <img
                                src={data.media[0].mediaCropped}
                                alt={`Traceability Media ${data.name}`}
                                className="w-full h-16 object-cover" // Adjust size as needed
                            />
                        } 
                         */}                                               
                        {Array.isArray(data.media) && data.media.length > 0 && data.media[0].mediaCropped && (
                            <img
                                src={data.media[0].mediaCropped}
                                alt={`Traceability Media ${data.name}`}
                                className="w-full h-16 object-cover" // Adjust size as needed
                            />
                            )
                        }
                    </td>
                    <td>{data.name}</td>
                </tr>
                <tr>
                    <td>Tipo de Evento</td>
                    <td>{data.eventType}</td>
                </tr>
                <tr>
                    <td>Ubicación</td>
                    <td>{data.location}</td>
                </tr>
                <tr>
                    <td>Product</td>
                    <td>
                        {data.inputs && data.inputs.map((input, inputIndex) => (
                            <div key={inputIndex}>
                                {input.productId}
                            </div>
                        ))}
                    </td>
                </tr>
                <tr>
                    <td>Unidad de Medida</td>
                    <td>
                        {data.inputs && data.inputs.map((input, inputIndex) => (
                            <div key={inputIndex}>
                                {input.uom}
                            </div>
                        ))}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>Descripción</td>
                </tr>
                <tr>
                    <td className='!whitespace-pre-wrap text-justify' colSpan={2}>{data.description}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default ComparisonTableProductsTraceability;


