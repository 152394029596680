// src/pages/Analysis.jsx

import React, { useState, useEffect, useCallback, useRef } from 'react';
//
import { COLUMNS_PRODUCERS } from '../columns/columnAnalysisProducers';
import { COLUMNS_TRANSPORT } from '../columns/columnAnalysisTransport';
import { COLUMNS_PRODUCT } from '../columns/columnAnalysisProductsLots';
//
import TableProductsCheckbox from '../tables/TableProductsCheckbox';
import CompareProducers from '../components/CompareProducers';
import CompareTransport from '../components/CompareTransport';
import CompareProducts from '../components/CompareProducts';
import CalcCO2 from '../components/CalcCO2';
import CalcCO2Production from '../components/CalcCO2Production';
import {Tooltip} from 'react-tooltip';
import { Icon } from '@iconify/react';

//import PropTypes from 'prop-types';

const AnalysisButton = ({ title, description, iconName, onClick, isSelected, setSelected}) => {
  const buttonClass = isSelected ? 'bg-dark-blue-900' : 'bg-white';
  const textClass = isSelected ? 'text-light-green-100' : 'text-dark-blue-900';

  const handleClick = () => {
    if (isSelected) {
      setSelected(true);    
    }
    onClick();
  };

  return (
    <button onClick={handleClick} className={`group w-full flex justify-start flex-col h-fit p-4 ${textClass} ${buttonClass} gap-x-2 rounded-xl text-left hover:bg-dark-blue-600 hover:text-light-green-100 transition-colors duration-150`}>
      <div className={` w-full h-auto flex gap-x-2 md:gap-x-1 flex-row items-center mb-3 transition-colors duration-150`}>
        <Icon icon={iconName} className="w-10 h-10 shrink-0 md:w-8 md:h-8 aspect-square " />
        <h2 className="w-full text-lg font-bold ">{title}</h2>
      </div>
      <div className="flex w-full flex-col">
        <span className={` text-sm transition-colors duration-150`}>{description}</span>
      </div>
    </button>
  );
};

const LeftContent = ({ selectedComponent, setSelectedComponent, changeComponent, setChangeComponent}) => (
<div className={`h-full ${selectedComponent && (changeComponent ? 'translate-x-0' : ' -translate-x-full')} ${selectedComponent ? ' left-0 w-full max-w-xs inset-y-0' : ''} absolute transition-transform duration-300 bg-light-green-100 z-50 flex gap-y-4 flex-col p-4 md:p-6 pt-14 md:pt-10 w-full`}>
      {/* {changeComponent} */}
      {selectedComponent && 
      <button className="absolute top-3 right-3 shadow bg-white w-fit flex gap-x-2 px-1.5 py-1.5 hover:bg-dark-blue-900 hover:text-white  justify-center item-center rounded " onClick={setChangeComponent} >
        <Icon icon="mdi:close" className="w-5 h-5 my-auto " />
        </button>
      }
      <h1 className="text-3xl font-semibold text-dark-blue-600">Calculadora</h1>
      <p className="text-sm text-dark-blue-900">Selecciona un tipo de calcular para aprender más.</p>
      <p className="w-full pb-1 border-b border-dark-blue-900 text-sm uppercase font-medium tracking-wide">Calculadoras CO<span className="text-xs">2</span></p>
      {/* <span className="w-full h-px bg-dark-blue-900"></span> */}
      <div className={`${selectedComponent ? 'flex-col' : 'flex-col md:flex-row'} flex  gap-4`}>
      {/* <AnalysisButton onClick={() => {setSelectedComponent('Productores')}} title="Productores" iconName="ic:round-factory" description="Compara el consumo de agua durante la producción entre varios productores" isSelected={selectedComponent === 'Productores'} setSelected={(value) => setSelectedComponent(value)} /> */}
      <AnalysisButton onClick={() => {setSelectedComponent('Transportistas'); setChangeComponent(false);}} title="Transportistas" iconName="bxs:truck" description="Calcula el CO2 consumido durante el transporte de las mercancías" isSelected={selectedComponent === 'Transportistas' } setSelected={(value) => setSelectedComponent('Transportistas')}/>
      <AnalysisButton onClick={() => {setSelectedComponent('CO2Production'); setChangeComponent(false);}} title="Productores" iconName="ic:round-factory" description="Calcula el CO2 consumido durante el transporte de las mercancías" isSelected={selectedComponent === 'CO2Production' } setSelected={(value) => setSelectedComponent('Transportistas')}/>
      {/**<AnalysisButton onClick={() => {setSelectedComponent('Productos/Lotes')}} title="Productos/Lotes" description="Compara varios aspectos ambientales y cuantitativos de los lotes" isSelected={selectedComponent === 'Productos/Lotes'} setSelected={(value) => setSelectedComponent(value)} />*/}
      </div>
  </div>
);

const SearchComponent = ({ title, selectedCategory, onCompareClick, showTable, setShowTable, searchTerm, setSearchTerm, onSelectedDataChange, storedItems, setStoredItems  }) => {
  //const [searchTerm, setSearchTerm] = useState('');
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  //const analysisJSON = process.env.PUBLIC_URL +'/data/analysis.json';
  const analysisJSON = '/data/analysis.json';
  //const [showTable, setShowTable] = useState(false);
  const [cat, setCat] = useState({});
  const [showData, setShowData] =  useState(null);
  //const [storedItems, setStoredItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [input, setInput] = useState("");
  const [showTooltipInput, setShowTooltipInput] = useState(false);
  const [showTooltipTable, setShowTooltipTable] = useState(false);
  const timerRef = useRef(null);
  const [showWarning, setShowWarning] = useState(false);
  const [notFoundWarning, setNotFoundWarning] = useState(false);

  //Handles search button. Fetches the json and activates the states to show/hide the table
  const handleSearch = () => {
    // Reiniciar el estado de advertencia
    setShowWarning(false);
    setNotFoundWarning(false);

    if (!searchTerm) {
      setShowWarning(true); // Mostrar advertencia si el campo de búsqueda está vacío
      return; // Salir de la función para prevenir ejecución adicional
    }
    //Checking for loops in useStates 
    //console.log("handleSearch called");       
    // Simulate fetching JSON data from an API
    fetch('/data/analysis.json')
      .then((response) => response.json())
      .then((jsonData) => {
        setJsonData(jsonData);

        // Filtering data based on the selected category
        let cat = [];
        if (selectedCategory === 'Productores') {
          cat = jsonData.productores;
        } else if (selectedCategory === 'Transportistas') {
          cat = jsonData.transportistas;
        } else if (selectedCategory === 'Productos/Lotes') {
          cat = jsonData.lote;
        }

        /*if (cat) {
          const filteredData = cat.filter(item => item.producto.toLowerCase() === searchTerm.toLowerCase());
          setStoredItems(filteredData);
          setShowTable(true);
        } else {
          setShowTable(false);
        }*/
        if (cat) {
          const filteredData = cat.filter(item => item.producto.toLowerCase() === searchTerm.toLowerCase());  
          if (filteredData.length > 0) {
            setStoredItems(filteredData);
            setShowTable(true);
          } else {
            // Mostrar advertencia si no se encuentran coincidencias
            setNotFoundWarning(true);
            setShowTable(false);
          }
        } else {
          setShowTable(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  //console.log("storedItems", storedItems);
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setShowTooltipInput(true);
  };

  const handleKeyUp = (event) => {
    //Checking for loops in useStates 
    //console.log("handleKeyUp called", event.key);
    //console.log("handleKeyUp - event: ", event);
    if (event.key === 'Enter') {
      handleSearch();
      //Clears the input textbox 
      if (!searchTerm) {
        setSearchTerm(''); 
      }
    }
  };
  //Handles compare button. This method is implemented in Analysis
  const handleCompare = () => {
    onCompareClick();
  }
  
  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
    onSelectedDataChange(newSelection); // Lift the state up to Analysis
  };

  const handleMouseEnter = (element) => {
    if (element === 'input') {
      setShowTooltipInput(true); // Show tooltip for input
    } else if (element === 'table') {
      setShowTooltipTable(true); // Show tooltip for table
    }

    timerRef.current = setTimeout(() => {
      if (element === 'input') {
        setShowTooltipInput(false); // Hide tooltip for input
      } else if (element === 'table') {
        setShowTooltipTable(false); // Hide tooltip for table
      }
    }, 2000);
  }

  const onMouseOut = (element) => {
    if (element === 'input') {
      setShowTooltipInput(false); // Hide tooltip for input
    } else if (element === 'table') {
      setShowTooltipTable(false); // Hide tooltip for table
    }
    clearTimeout(timerRef.current);
  }

  return (
    <div className="flex h-[100svh] md:h-full flex-col p-4 md:p-6 pt-14 md:pt-10 w-full md:w-2/3 relative">
      <h1 className="text-2xl tracking-wide font-bold uppercase text-dark-blue-900 self-end">{title}</h1>
      <h2 className="text-xl font-semibold text-dark-blue-900 mt-8 mb-4">Búsqueda</h2>
      <p className="text-dark-blue-900 mb-4">Busca por elementos y selecciona los que quieres comparar</p>
    <div className="flex">
      <input 
        className="rounded p-2 flex-grow mr-2 focus:outline-primary-200 focus:outline-none focus:ring-0" 
        type="text" 
        placeholder="Buscar..." 
        value={searchTerm} 
        onChange={handleInputChange} 
        onKeyUp={handleKeyUp}
        data-tooltip-id="inputAnalisis"
        data-tooltip-delay-show={500}
        data-tooltip-content="Escribe el nombre de un producto. Por ejemplo: aguacate."
        onMouseEnter={() => handleMouseEnter('input')}
        onMouseOut={() => onMouseOut('input')}
      />
      <Tooltip className="custom-tooltip" id="inputAnalisis" place="top" type="dark" effect="solid" isOpen={showTooltipInput}/>
      <button className="bg-dark-blue-900 hover:bg-dark-blue-700 h-full flex justify-center items-center aspect-square text-light-green-100 p-2 rounded" onClick={handleSearch}>
        <Icon icon="material-symbols:search" className='h-5 w-5' /></button>
    </div>
    
    {showWarning && (
        <p className="my-4 text-red-600">Por favor, escriba el nombre de un productor para continuar.</p>
    )}
    {notFoundWarning && (
        <p className="my-4 text-red-600">No se encontró el término que escribió. Prueba otra vez.</p>
      )}
    {/**Shows the table depending on the category selected on the leftContent panel and if the state showTable is active*/}
    {showTable && (
      <>
        <div 
          data-tooltip-id="tableAnalysis" 
          data-tooltip-content="Selecciona dos o más productores y pulsa comparar." 
          className="table-container mt-4" 
          onMouseEnter={() => handleMouseEnter('table')}
          onMouseOut={() => onMouseOut('table')}  
        >
          <TableProductsCheckbox
            data={storedItems}
            columns={
              selectedCategory === 'Productores'
                ? COLUMNS_PRODUCERS
                : selectedCategory === 'Productos/Lotes'
                ? COLUMNS_PRODUCT
                : [] 
            }
            onSelectionChange={handleSelectionChange}
          />
        </div>
        <Tooltip className="custom-tooltip" id="tableAnalysis" place="top" type="dark" effect="solid" isOpen={showTooltipTable}/>
        <button className="bg-[#005571] text-light-green-100 p-2 rounded mt-4" onClick={handleCompare}>
        Compara
      </button>
      </>      
      )}
    {/**Similar than the previous but for the compareButton. This also calls the method that opens the Compare window */}
    {/**
     * {showTable && (
      <button className="bg-[#005571] text-light-green-100 p-2 rounded mt-4" onClick={handleCompare}>
        Compara
      </button>
    )}  
     */}
      
  </div>
  );
};

const RightContent = () => (
  
  <div className="flex flex-col items-center justify-center z-0 h-full pt-8 px-8 w-full md:w -2/3 relative">
    <div className="flex flex-col justify-center h-full">
      <h1 className="text-2xl font-bold text-[#005571] absolute top-0 right-0 mr-8 mt-8">Comparativa</h1>
      <p className="text-sm text-dark-blue-900">Busca por elementos y selecciona los que quieras comparar</p>
    </div>
  </div>
);

const Analysis = () => {
  const [selectedComponent, setSelectedComponent] = useState('');
  const [changeComponent, setChangeComponent] = useState(false);
  const [isComparing, setIsComparing] = useState(false); // State to track if the comparison view is active
  //Trying to set the height of the page so that there's no scrollbar to the right and its content fits the height of the screen
  //Sets the height as the window height minus the height of the navbar and the padding of the div that contains all the routes (see app.js).
  //Find a way to calculate it without entering the exact units to substract (60+5px)
  //There was one last bit of scrollbar. In App.js the main container of the pages (routes) had this sm:mt-5, I've added this md:mt-0 and the scrollbar is gone. 
  // const [divHeight, setDivHeight] = useState('calc(100vh - 65px)'); // Initial height set to 100vh
  const [searchTerm, setSearchTerm] = useState('');
  const [tableData, setTableData] = useState([]); // Add this state
  const [showTable, setShowTable] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [viewMode, setViewMode] = useState('default'); // 'default', 'compare', etc.
  const [storedItems, setStoredItems] = useState([]);
  // const [showCalcCO2, setShowCalcCO2] = useState(false);

  //
  // useEffect(() => {
  //   const setDivHeightFunc = () => {
  //     // Assuming your navbar has an id of 'navbar'
  //     //Apparently this doesn't work. Findout the name of the navbar.
  //     const navbar = document.getElementById('Navbar'); 
  //     if (navbar) {
  //       const navbarHeight = navbar.offsetHeight;
  //       const newDivHeight = window.innerHeight - navbarHeight;
  //       setDivHeight(`${newDivHeight}px`);
  //     }
  //   };

  //   setDivHeightFunc(); // Set initial height
  //   window.addEventListener('resize', setDivHeightFunc); // Update on resize

  //   return () => window.removeEventListener('resize', setDivHeightFunc); // Cleanup
  // }, []); // Empty dependency array means this useEffect runs once on mount and cleanup on unmount

  //Handles when the "Compara" button is clicked
  const onCompareClick = () => {
    setIsComparing(true); // Set the flag to show the comparison component
    setViewMode('compare'); // Change to 'compare' mode when "Compara" is clicked  
  };

  //For the back button
  const handleBackToDefaultView = () => {
    setIsComparing(false);
    setViewMode('default'); // Revert to 'default' mode
  };

  //Renders a comparison component based on the selectedComponent
  const renderComparisonComponent = () => {
    switch (selectedComponent) {
      case 'Productores':
        return <CompareProducers storedItems={storedItems} selectedData={selectedData} onBack={handleBackToDefaultView} setSelectedData={setSelectedData}  />;
      case 'Productos/Lotes':
        return <CompareProducts selectedData={selectedData}/>;
      default:
        return null;
    }
  };

  const updateChangeComponent = () => {
    setChangeComponent(!changeComponent);
  };
  

  const updateSelectedComponent = (newComponent) => {
    if (newComponent !== selectedComponent) {
      if (searchTerm !== '') {
        setSearchTerm('');
      }
      setShowTable(false);  // Clear table data when a different button is clicked
    }
    setSelectedComponent(newComponent);
  };

  const handleSelectedDataChange = useCallback((newData) => {
    setSelectedData(newData);
  }, []);

  // Método para actualizar storedItems
  const updateStoredItems = (items) => {
    setStoredItems(items);
  };

  //{selectedComponent && <SearchComponent title={`Comparativa ${selectedComponent}`} selectedCategory={selectedComponent} onCompareClick={onCompareClick}  searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>}
  return (
    //As part of the process to hide the scrollbar, overflow-hidden is set and the calculated height above applied here.
    /**
     *  To achieve the desired layout where LeftContent and RightContent are displayed side by side, but CompareTransport (or any other component rendered by renderComparisonComponent) takes the full width when displayed, you can use a conditional CSS class based on the isComparing state. This approach allows for dynamic layout changes based on the state of your component.

        Here’s how you can modify the Analysis component to achieve this:

        Use a ternary operator to conditionally apply different CSS classes based on the isComparing state.
        When isComparing is false, apply a class that sets the layout to display LeftContent and RightContent side by side.
        When isComparing is true, apply a class that allows the CompareTransport component to take the full width.
     */
    <div className={`overflow-y-auto relative h-full lg:overflow-hidden flex flex-col md:flex-row  ${isComparing ? '' : ''} `}> 
        {/**Shows/Hides the left and right content panels and shows the SearchComponent if a selectedComponent has been clicked
         * The SearchComponent handles the compare button. When clicked onCompareClick sets isComparing to true. 
         * If isComparing is true it calls renderComparisonComponent method that opens the compare component depending on the selectedComponent.
         * NOTE:
         * Pass showTable={showTable} setShowTable={setShowTable} searchTerm={searchTerm} setSearchTerm={setSearchTerm} from Analysis to SearchComponent so that the states can be preserved between components
         */}
        {!isComparing && viewMode === 'default' && (
          <>
            <button onClick={updateChangeComponent} className={`${(changeComponent) ? 'opacity-100 ' : 'opacity-0 pointer-events-none'} cursor-default transition-opacity absolute  inset-0 bg-black/30  z-40`}></button>
            <LeftContent 
            changeComponent={changeComponent} setChangeComponent={updateChangeComponent}  selectedComponent={selectedComponent} setSelectedComponent={updateSelectedComponent}
            />
            {/* <div className={`${selectedComponent ? 'w-full' : 'w-2/3'} z-0`}> */}
              <button onClick={updateChangeComponent} className={`absolute top-2 md:top-10 md:right-[inherit] left-16 md:left-6  transition-opacity flex gap-x-2 py-3 px-3 md:px-2 md:py-1 rounded justify-center items-center bg-white hover:bg-dark-blue-900 hover:text-white shadow-sm transition-colors z-30`}>
                <Icon icon="tabler:switch-horizontal" className="w-5 h-5" />
                <span className="hidden sm:block">
                  Cambiar calculo
                </span>
              </button>

              {/* {!selectedComponent && <RightContent />} */}
              {selectedComponent === 'CO2Production' && (
              <CalcCO2Production/>) }
              {selectedComponent === 'Transportistas' && (
                <CalcCO2 />
              ) }
                {/* {selectedComponent === 'Productores' && (
                  <CalcCO2 />
                ) } */}
                {selectedComponent === 'Productores' && <SearchComponent 
                  title={`Comparativa ${selectedComponent}`} 
                  selectedCategory={selectedComponent} 
                  onCompareClick={onCompareClick} 
                  showTable={showTable} 
                  setShowTable={setShowTable} 
                  searchTerm={searchTerm} 
                  setSearchTerm={setSearchTerm}
                  onSelectedDataChange={handleSelectedDataChange} 
                  storedItems={storedItems} // Pasando storedItems como prop
                  setStoredItems={updateStoredItems} // Pasando el método para actualizarlos
                />}
              {/* </div> */}

            </>
          )}            
        {isComparing && viewMode === 'compare' && renderComparisonComponent()}    
    </div>
  );
};

export default Analysis;