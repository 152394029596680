import React, { createContext, useState, useContext } from 'react';

// Create the context
const AuthContext = createContext();

// AuthProvider component
const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = async () => {
    try {
      setIsAuthenticated(false);
      const response = await fetch('/api/handleUserProfile?action=logout', {
          method: 'GET'
      });
      
      if (response.ok) {
          // Handle successful logout on client-side
          console.log('Logged out successfully');
      } else {
          throw new Error('Failed to log out');
      }
    } catch (error) {
        console.error('Error logging out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Export AuthContext and AuthProvider
//export default AuthContext;
export { AuthProvider };
export const useAuth = () => useContext(AuthContext);
