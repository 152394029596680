import React from 'react';
import { Icon } from '@iconify/react';

const Dropdown = ({ onChange, options }) => (
  <div className="inline-block text-sm relative min-w-[60px] w-fit">
    <select
      onChange={onChange}
      className="block appearance-none text-base w-full bg-white border-2 transition-colors border-dark-green-900 hover:border-light-green-300 pl-3 py-2 pr-7 rounded  leading-tight focus:outline-none "
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-dark-blue-900">
      <Icon icon="mdi:chevron-down" className='h-5 w-5 text-current' />
    </div>
  </div>
);

export default Dropdown;
