import React from 'react';

const ComparisonTableProducers = (props) => {
    const {data} = props;

    //console.log("ComparisonTableProducers selectedData", data);
    //console.log("ComparisonTableProducers selectedData typeof", typeof data);

    if (!data) {
        return <p>No data available</p>;
    }

    return (
        <table className='w-full standard-table'>
            <tbody>
                <tr>
                    <td>GS1</td>
                    <td>{data ? data.gs1 : 'N/A'}</td>
                </tr>
                <tr>
                    <td>VAT ID</td>
                    <td>{data ? data.vatId : 'N/A'}</td>
                </tr>
                <tr>
                    <td>TAX ID</td>
                    <td></td>
                </tr>      
                <tr>
                    <td>Descripción</td>
                    <td>{data ? data.description : 'N/A'}</td>
                </tr>           
                <tr>
                    <td>Fecha añadida</td>
                    <td>{data ? data.date : 'N/A'}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default ComparisonTableProducers;
