// src/components/MapRoute.jsx

import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, useMap  } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from '@iconify/react';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

//Defines a custom icon for the marker
const defaultIcon = L.icon({
    iconUrl: icon, //iconUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    shadowUrl: iconShadow,
    iconAnchor: [16, 45], //Anchor point by default is at Leaflet marker's bottom tip
});

const myIcon = new L.Icon({
    iconUrl: <Icon icon="mdi:map-marker" />,
    iconRetinaUrl: <Icon icon="mdi:map-marker" />,
    popupAnchor:  [-0, -0],
});

L.Marker.prototype.options.icon = defaultIcon;



// This component will update the map's position
// It uses the useMap hook from react-leaflet to access the map instance and then updates the map's view using setView.
function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());

    return null;
}

/**The style={{ zIndex: 1000 }} in the last div is used to ensure that it has a higher z-index
 * than the map layers. The tailwind values were too low.
 */
/** 
 * The MapLocation component renders the ChangeMapView component, passing the current coordinates. 
 * Whenever fromCoordinates changes, ChangeMapView will be re-rendered and will update the map's view to the new coordinates.
*/
const MapLocation = ({ from, height, width }) => {
    // You might want to dynamically fetch these coordinates based on city names using a geocoding service
    const fromCoordinates = [from.lat, from.lng]; // Example: [51.505, -0.09]
    //const toCoordinates = [to.lat, to.lng]; // Example: [51.515, -0.1]
    const mapRef = useRef(null); // Create a ref for the map
    console.log("MapLocation fromCoordinates", fromCoordinates);
    //style={{ height: "100%", width: "100%" }}
    return (
        <div className="relative overflow-clip rounded-md p-2 bg-white h-full w-full">
            <MapContainer 
                center={fromCoordinates} 
                zoom={13} 
                style={{ height: height, width: width }}
                ref={mapRef}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={fromCoordinates} />
                <ChangeMapView coords={fromCoordinates} />
            </MapContainer>            
        </div>
    );
};

export default MapLocation;
