export const COLUMNS_PRODUCT = [
    {
        Header: 'Codigo',
        accessor: 'codigo',    
    },
    {
        Header: 'Producto',
        accessor: 'producto'
    },
    {
        Header: 'Cantidad',
        accessor: 'cantidad'
    },
    
];
