import React, { useEffect } from 'react'
import { Link, NavLink, Navigate } from 'react-router-dom';
import { SiShopware } from 'react-icons/si';
import { AiOutlineMenu } from 'react-icons/ai';
import {Tooltip} from 'react-tooltip';
// import { Icon } from '@iconify/react';
import { links } from '../data/data';  /**Dummy data. Only for testing purposes */
import { useStateContext } from '../contexts/ContextProvider';
import { Icon } from '@iconify/react';
import { useAuth } from '../contexts/AuthContext';
import ZeroFoodLogoNobackground from '../helpers/ZeroFood-logo-dark.svg';
import TrustyLogo from '../helpers/trusty-logo.svg';

import '../fonts/fontWhisper.css';

const Sidebar = () => {
  /**Like in App.js, after creating the contextProvider inside the context folder, instead of this line:
   * const activeMenu = true;
   * We add the following:
   */
  const { activeMenu, setActiveMenu, screenSize, setActiveAppbar, setActiveLoginForm, showIconsOnly, setShowIconsOnly, setScreenSize } = useStateContext();     /**From contextProvider */
  const { handleLogout } = useAuth();

  /**Close the side bar in mobile devices when we click on an option */
  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 768) {
      setActiveMenu(false)
    }   
  }

  const onLogout = () => {
    handleLogout();
    Navigate('/login');
  }

  /** The links array in data.js that holds the names and icons of the menu options of the sidebar had the name "Data Analysis" with one space.
   * That's a problem because when creating the link to open the "Data Analysis" page when you click on the menu, by extracting the name from that array, 
   * it brings only "Data" instead of "Data Analysis" and the page won't open. In order to solve that I've decided to include the name of "DataAnalysis" 
   * with no spaces inside that link array. 
   * For showing it correctly in the menu of the sidebar the next function was created. It must take "link" as an argument (link). Otherwise it throws
   * an error (link is undefined). The function must be called within the loop ("{links.map((item) => (...)") otherwise it'll throw the same error. 
  */
  const formatLinkName = (link) => {
    if (link.name === "SupplyChain") {
      //return link.menuName
      return "Supply Chain"
    } else if (link.name === "TrackNTrace") {
      //return link.menuName
      return "Track & Trace"
    }else {
      return link.menuNameSpa;
    }
  } 

  const formatLinkName2 = (link) => {
    return link.menuName;
  }

  const handleCloseAppbarAndSidebar = (link) => {
    if (link.Name === "Login") {
      // Set activeTopbar to true when link.name is "login"
      setActiveAppbar(false);
      setActiveMenu(false);
      setActiveLoginForm(true);
    } else {
      setActiveAppbar(true);
      setActiveMenu(true);
    }
  }  

  const NavButton = ({  title, customFunc, icon, color, dotColor }) => (
    <div>
        <button 
          type="button" 
          onClick={customFunc} 
          style={{color}} 
          className='relative text-xl px-[0.875rem] py-3 text-light-green-200 hover:text-light-green-500'
          data-tooltip-id="bNavButton"
          data-tooltip-delay-hide="500"
          data-tooltip-delay-show="1000"
          data-tooltip-content="Muestra/Esconde Menú"
        >

          <span style={{ background: dotColor}} className="absolute inline-flex h-2 w-2 right-2 top-2"/>
          <Icon className='text-current' icon="ri:menu-fill" />
                  </button>
        <Tooltip  className='md:hidden custom-tooltip' id="bNavButton" place="right" type="dark" effect="solid"/>
    </div>
  )
  
  const toggleMenuText = () => {
    if (screenSize >= 768) {
      setShowIconsOnly(prev => !prev);
    } 
    else {
      setActiveMenu(prev => !prev);
    }
  }

  const toggleMobileMenu = () => {
    setActiveMenu(prev => !prev);
  }


  /**
   * We figure out the initial size when the window loads.     
   * Leave this here or moved it to sidebar or other file?   
  */
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);

  }, []);
  
  /**Whenever the screen size changes, if the screen size is smaller than 900 we deactivate the sidebar otherwise we activate it */
  /**NOTE: THIS IS THE REASON THE SIDEBAR APPEARS AUTOMATICALLY WHEN THE SCREEN RESIZES EVEN IF THE LOGIN FORM IS ON THE SCREEN.
   * FIND A SOLUTION: MAYBE ADD ANOTHER VARIABLE THAT HOLDS THE STATE OF THE LOGIN FORM. SET IT TO TRUE WHEN THE LOGIN FORM SHOWS UP AFTER CLICK ON THE LOGIN OPTION ON THE SIDEBAR MENU,
   * OTHERWISE SET IT TO FALSE. IF ITS TRUE THE SIDEBAR MUST NOT APPEAR EVEN IF THE SCREEN SIZE IS BIGGER THAN 900. Solution in next useEffect.
   * Leave this here or moved it to sidebar or other file?
   * 
   */
  useEffect(() => {
    if(screenSize < 768) {
      setShowIconsOnly(false);
      setActiveMenu(false);
    }         
    if (screenSize >=768 && screenSize <1024) {
      setShowIconsOnly(true);
      setActiveMenu(true);
    }
    if (screenSize >=1024) {
      setShowIconsOnly(false);
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu, setShowIconsOnly]);

  /**Prevents the sidebar from opening when the login form is on screen after clicking on the login option in the menu
   * LEAVE IT HERE OR MOVED IT TO ANOTHER FILE?
   */  

  /**WARNING: Originally the property "text-white" was added to const activeLink = '' but for some reason the first element within the PAGES category, orders, was not showing.
   * Deleting text-white solved the issue. 
   */
  const activeLink = ' bg-lime-100 hover:bg-lime-200 hover:text-lime-800 ';
  const normalLink = ' hover:bg-lime-200 text-lime-100 hover:text-lime-800';

  return (
    <div className=" h-[100svh] sm:h-screen flex flex-col gap-y-3 overflow-hidden md:overflow w-auto ">            
      {!activeMenu && (
        <div className="fixed z-[9999] rounded top-2 left-2 bg-dark-blue-900 md:hidden"> {/**sm:hidden */}
          <NavButton 
            title="Menu" 
            customFunc={toggleMobileMenu} 
            icon={<AiOutlineMenu/>}>              
          </NavButton>
        </div>
      )}

      {/* <button onClick={handleCloseSidebar} className={`${(activeMenu) ? 'opacity-100 ' : 'opacity-0 pointer-events-none'} cursor-default transition-opacity absolute  inset-0 bg-black/30  -z-10`}></button> */}
      {activeMenu && (<>
        {/**Item at the top with logo and button to close the sidebar */}
        <div className="z-50 relative flex justify-between items-center p-2">
          {/**Close the sidebar */}
          <Link to="/" onClick={handleCloseSidebar} 
            className={`items-center ml-2.5 m-1 flex text-xl font-extrabold tracking-tight text-slate-200 ${showIconsOnly ? 'hidden' : ''}`}>
              <img src={ZeroFoodLogoNobackground} alt="zerofood" className="transition-transform duration-200 hover:scale-110 object-contain object-left w-auto h-10"/>

              {/**We make the text Hortatech invisible only for tablet screen sizes */}
            {/* <SiShopware/> <span className='visible md:invisible lg:visible'>ZeroFood</span> */}
          </Link>
            {/**This button closes the sidebar 
             * NOTE: Adding md:hidden to the properties in className it's not shown on a desktop computer screen
            */}
            {/**We change the value of the menu to its previous state */}

          {/**
           * <Tooltip title="Menu" position="BottomCenter">
            <button type="button" onClick={() => setActiveMenu(prevActiveMenu => !prevActiveMenu)} 
              className="self-end text-xl p-2 block">
              <AiOutlineMenu className="text-lime-200 hover:text-lime-400 transition-colors"/> 
            </button>
          </Tooltip> 
           */}
           {/**
            * 
            */}
          <NavButton 
            title="Menu" 
            customFunc={toggleMenuText} 
            icon={<AiOutlineMenu/>}>              
          </NavButton>
        </div>
        {/**MENU ITEMS AND LINKS*/}
        <div className='z-50'>
          {/**We pass the items of the menu as an array and we loop over them. DON'T FORGET THE KEY */}
          {links.map((item) => (
            <div key={item.title}> 
              {/** We make the title (Main Menu) invisible only for tablet screen sizes */} 
              <p className='text-gray-400 m-3 mt-4 uppercase visible md:invisible lg:visible'>
                {item.title}
              </p>
              {/**We loop over the links. DON'T FORGET THE KEY 
               * className={({ isActive }) => }.We call a function that will accept a state isActive for each item. Based on that state we can render different classNames.
               * We define at the top two different classNames for active and not active links. if isActive we render activeLink, otherwise we render normalLink. 
              */}
              {item.links.map((link) => (
                <NavLink 
                  to={`/${link.name}`} 
                  key={link.name}
                  onClick={() => {
                    handleCloseSidebar();
                    //handleCloseAppbarAndSidebar(link);
                  }}
                  className={({ isActive }) => `flex ${isActive ? activeLink : normalLink} transition-colors duration-150 flex items-center h-12 gap-5 px-[1.375rem] py-3 text-md`}
                  data-tooltip-id="linkSidebar"
                  data-tooltip-content={link.menuNameSpa}
                  data-tooltip-delay-show={100}
                  data-tooltip-delay-hide={500}

                >
                  {/**We render the icon and the name of the link and increase the icon's size*/}
                  <span className='text-xl'>{link.icon}</span>      
                  {/* Conditionally render Tooltip based on showIconsOnly */}
                  {showIconsOnly && (
                      <Tooltip className="custom-tooltip" id="linkSidebar" place="right" type="light" effect="solid">
                        {link.menuNameSpa}
                      </Tooltip>
                  )}
                  {!showIconsOnly && <span>{link.menuNameSpa}</span>}                  
                </NavLink>
              ))}
            </div>
          ))}
        </div>    
        <div className="mt-auto flex flex-col w-full">
        {!showIconsOnly && 
          <div className='flex flex-col my-2 space-y-1 opacity-70'>
            <p className="text-lime-100 text-center text-10 font-sans">Powered by <img src={TrustyLogo} alt="powered by trusty" className="inline-block pointer-events-none text-lime-100 object-contain w-auto h-5"/>
            </p>
            <p className="text-lime-100 text-center text-10 font-sans">Developed by Baukunst</p>
          </div>
          }
          <div className="w-full" key="Settings"> 
            <NavLink 
              to={`/ajustes`} 
              key="settings"
              onClick={() => {
                handleCloseSidebar();
                //handleCloseAppbarAndSidebar("link");
              }}
              className={({ isActive }) => `flex ${isActive ? activeLink : normalLink} transition-colors duration-150 flex items-center h-12 gap-5 px-[1.375rem] py-3 text-md`}
              data-tooltip-id="linkSidebar"
              data-tooltip-content="Perfil de usuario"
              data-tooltip-delay-show={100}
              data-tooltip-delay-hide={500}
            >                  
              <span className='text-xl'>
                <Icon icon="iconamoon:profile-circle" className='h-5 w-5' /> {/**material-symbols:settings */}
              </span>      
                {showIconsOnly && (
                    <Tooltip className="custom-tooltip" id="linkSidebar" place="right" type="light" effect="solid">
                      Peril de usuario
                    </Tooltip>
                )}
                {!showIconsOnly && <span>Perfil de usuario</span>}                  
              </NavLink>
          </div>    
          
          <div className="w-full" key="logout"> 
            <NavLink 
              to={`/login`} 
              key="logout"
              onClick={() => {
                onLogout();              }}
              className={({ isActive }) => `flex ${isActive ? activeLink : normalLink} transition-colors duration-150 flex items-center h-12 gap-5 px-[1.375rem] py-3 text-md ${showIconsOnly ? 'mb -5' : ''}`}
              data-tooltip-id="linkLogout"
              data-tooltip-content="Cerrar la sesión"
              data-tooltip-delay-show={100}
              data-tooltip-delay-hide={500}
            >                  
              <span className='text-xl'>
                <Icon icon="material-symbols:logout-sharp" className='h-5 w-5' />
              </span>      
                {showIconsOnly && (
                    <Tooltip className="custom-tooltip" id="linkLogout" place="right" type="light" effect="solid">
                      Cerrar la sesión
                    </Tooltip>
                )}
                {!showIconsOnly && <span>Cerrar la sesión</span>}                  
              </NavLink>
          </div>  
          
          {/**
           * <div className={`${activeMenu ? 'block' : 'hidden'}`}>
           
          </div>
           */}          
        </div>    
      </>)}
    </div>
  )               
}                 

export default Sidebar