import React, { useMemo, useEffect, useState } from 'react'
import { useTable } from 'react-table'
//import { COLUMNS_FARMS } from '../columns/columnProdFarms'
import './table.css'

const TableProductsSelectRow = (props) => {

    //const harvestData = '/data/harvestData.json'
    /**useMemo Ensures the data is not recreated in every render */
    //const columns = useMemo( () => COLUMNS_FARMS, [])
    const columns = useMemo(() => props.columns, [props.columns]);
    const [data, setData] = useState([]);
    //const data = props.data || []; // Use passed data or default to an empty array
    

    const tableInstance = useTable({
        columns,
        data: props.data || []
    })    
    //console.log("data in TableProducts", data);
    /**Functions and arrays that the useTable hook from react table package has given to us to enable easy table creation */
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, } = tableInstance

    return (
        <div className=''>
            {props.header && <h2 className="text-lg font-bold mb-2">{props.header}</h2>}
            <table className='standard-table compact-table table-auto'{...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th className='' {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}                
                </thead>
                <tbody className="" {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps({
                                    onClick: () => props.onRowSelect(row.original), // Add row click handler
                                    style: { cursor: 'pointer' } // Optional: change cursor on hover
                                })}>
                                    {
                                        row.cells.map( cell=> {
                                            return <td className=' ' {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })
                                    }                                
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
            </table>
        </div>

    )
}

export default TableProductsSelectRow