import React, { useState, useEffect } from 'react';
import { useStateContext } from '../contexts/ContextProvider';

const Ajustes = () => {
  //Setters and getts for user's data
  const [name, setName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [profilePicture, setProfilePicture] = useState(null); // To store profile picture
  const [profession, setProfession] = useState('');
  //Displaying messages 
  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  //
  const { userProfile, setUserProfile } = useStateContext();

  /**
   * 
   * const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleProfilePictureChange = (e) => {
    // Logic to handle profile picture change, set the image to 'profilePicture' state
    const imageFile = e.target.files[0];
    setProfilePicture(URL.createObjectURL(imageFile));
  };

  const handleAccountDeletion = () => {
    // Logic for account deletion
    // This could involve a confirmation dialog and actual deletion of the account
    // For the sake of example, a simple alert is used here
    alert('Are you sure you want to delete your account?');
  };
   */
  
  const validate = () => {
    let isValid = true;
    let errors = {};

    // Validation logic for name, email, confirmEmail, password, and profession

    if (!name) {
      isValid = false;
      errors.name = 'El nombre es necesario';
    }

    if (!email) {
      isValid = false;
      errors.email = 'El email es necesario';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      isValid = false;
      errors.email = 'El email no es válido';
    }

    // Check if new password and confirm password match
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      isValid = false;
      errors.confirmEmail = 'Las contraseñas nuevas no coinciden.';
    }

    /*if (!currentPassword) {
      isValid = false;
      errors.password = 'El password es necesario.';
    }*/

    if (!profession) {
      isValid = false;
      errors.profession = 'Por favor, introduce la profesión.';
    }                                    

    setErrors(errors);
    return isValid;

  }; //End validate 

  const fetchUserProfile = async () => {
    try {
      //Original code with the token stored in local storage (not used)
      //const token = localStorage.getItem('userToken'); // Replace 'userToken' with your token key
      
       /*const response = await fetch('/api/handleUserProfile?action=get', {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` 
        }
      });*/
       
      //Modified code with the token stored in the cookies (not used. Now the user's profile is loaded on startup in the context)
      const response = await fetch('/api/handleUserProfile?action=get', {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();
      console.log("Ajustes fetchUserProfile response", response);
      console.log("Ajustes fetchUserProfile data", data);

      if (response.ok && data) {
        setName(data.name);
        setEmail(data.email);
        setProfession(data.profession);
        // ... set other fields as necessary
      } else {
        errors.userRetrieval = 'No se pudo cargar el perfil de usuario.';
        throw new Error(data.message || 'Failed to fetch user profile');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      // Handle errors here, such as displaying a message to the user
    }
  }; //End fetchUserprofile

  useEffect(() => {
        
    fetchUserProfile();
    /*if (userProfile) {
      setName(userProfile.name);
      setEmail(userProfile.email);
      setProfession(userProfile.profession);
    } else {
      errors.userRetrieval = 'No se pudo cargar el perfil de usuario.';
    }*/
  }, []); // Empty dependency array means this effect runs once on mount

  console.log("Ajustes name, email, profesion", name, email, profession);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (validate()) { // Assuming 'validate' checks form data
      try {
        /**
         * const token = localStorage.getItem('userToken'); // Get stored token
        const response = await fetch('/api/handleUserProfile?action=update', {
          method: 'PUT',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            name,
            email,
            profession,
            // ... other fields to update
          })
        });
         */

        const updatedUserData = {
          name,
          email,
          profession,
        };
  
        // If newPassword is provided, include it in the update
        if (newPassword && newPassword === confirmPassword) {
          updatedUserData.newPassword = newPassword;
          updatedUserData.currentPassword = currentPassword; 
        }

        const response = await fetch('/api/handleUserProfile?action=update', {
          method: 'PUT',
          headers: { 
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedUserData)
        });

        const data = await response.json();
        console.log("Ajustes handleSubmit response", response);
        console.log("Ajustes handleSubmit data", data);

        if (response.ok) {
          console.log("Ajustes handleSubmit response.data", response.data);
          setSuccessMessage('Perfil actualizado con éxito');
          fetchUserProfile();

        } else {
          setErrors({ ...errors, response: 'No se pudo actualizar el perfil de usuario' });
          throw new Error(data.message || 'Failed to update profile');
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        setErrors({ ...errors, form: 'Error al actualizar el perfil' });
        // Handle error, such as showing a message to the user
      }
    }
  } //End handleSubmit

  const handleCancel = () => {
    // Reset state variables to their initial values
    //setName('');
    //setEmail('');
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    //setProfession('');
    // Reset any other states as necessary
  };

  return (
    <div className="flex flex-col gap-y-4 p-6 pt-14 md:pt-10">
      <h1>Perfil de usuario</h1>
        <div className=" bg-white flex flex-col p-4 rounded-lg shadow-sm gap-y-4">
          <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full py-2">
            <div className=" flex flex-col gap-y-4">
              <h2 className="font-serif text-xl">Información Personal</h2>
              <div>
                <div className="relative">
                  <input 
                    value={name} 
                    onChange={(e) => setName(e.target.value)}
                    type="text" 
                    id="floating_Name" 
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                  <label 
                    htmlFor="floating_Name" 
                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Nombre de usuario</label>
                </div>
                  {errors.name && 
                      <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.name}</p>
                  }
              </div>
              <div>
                <div className="relative">
                  <input 
                    value={profession} 
                    onChange={(e) => setProfession(e.target.value)}
                    type="text" 
                    id="floating_Profession" 
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                  <label 
                    htmlFor="floating_Profession" 
                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Profesión</label>
                </div>
                  {errors.profession && 
                      <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.profession}</p>
                  }
              </div>
              <div>
                <div className="relative">
                  <input 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                    type="text" 
                    id="floating_Email" 
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                  <label 
                    htmlFor="floating_Email" 
                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">E-Mail</label>
                </div>
                  {errors.email && 
                      <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.email}</p>
                  }
              </div>
            </div>
            <div className=" flex flex-col gap-y-4">
              <h2 className="font-serif text-xl">Cambiar Contraseña</h2>
              <div>
                <div className="relative">
                  <input 
                    value={currentPassword} 
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    type="password" 
                    id="floating_Password" 
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                  <label 
                    htmlFor="floating_Password" 
                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Contraseña actual</label>
                </div>
                  {errors.password && 
                      <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.password}</p>
                  }
              </div>
              <div>
                <div className="relative">
                  <input 
                    value={newPassword} 
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="password" 
                    id="newPassword" 
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                  <label 
                    htmlFor="newPassword" 
                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Nueva Contraseña</label>
                </div>
                  {errors.password && 
                      <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.password}</p>
                  }
              </div>
              <div>
                <div className="relative">
                  <input 
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password" 
                    id="confirmPassword" 
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                  <label 
                    htmlFor="confirmPassword" 
                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Confirme Contraseña</label>
                </div>
                  {errors.password && 
                      <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.password}</p>
                  }
              </div>
              {/**<div className="mb-4">
                <label htmlFor="profile-picture" className="block text-sm font-medium text-gray-700">Cambiar foto de perfil:</label>
                <input
                  type="file"
                  accept="image/*"
                  id="profile-picture"
                  onChange={handleProfilePictureChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
                {profilePicture && (
                  <img
                    src={profilePicture}
                    alt="Profile"
                    className="mt-2 rounded-md border border-gray-300"
                    style={{ maxWidth: '150px' }}
                  />
                )}
                </div>**/}
            </div>    
            <div className="md:col-start-2 flex justify-between gap-x-2 my-2 md:my-0">
              <button type="submit" className="flex justify-center bg-dark-green-600 hover:bg-dark-green-500 text-white px-4 py-2 rounded-md w-full">Guardar</button>
              <button type="button" onClick={handleCancel} className="flex justify-center bg-red-700 hover:bg-red-500 text-white px-4 py-2 rounded-md w-full">Cancelar</button>
            </div>        
            {errors.userRetrieval && <p className="text-red-500">{errors.userRetrieval}</p>}
            {errors.response && <p className="text-red-500">{errors.response}</p>}
            {successMessage && <p className="text-green-500">{successMessage}</p>}                                   
          </form>
          {/**<button onClick={handleAccountDeletion} className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md">Eliminar cuenta</button>**/}
      </div>
    </div>
  )
};

export default Ajustes;
