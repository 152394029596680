// src/components/MapRoute.jsx

import React, {useState, useMemo, useRef, useCallback} from 'react';
import { MapContainer, TileLayer, Marker, Polyline, useMap, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from '@iconify/react';
// import mapMarker from ''
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

/**The style={{ zIndex: 1000 }} in the last div is used to ensure that it has a higher z-index
 * than the map layers. The tailwind values were too low.
 */
const map = ({ routes, zoomLevel, height, width, showInfo = true }) => {
    // You might want to dynamically fetch these coordinates based on city names using a geocoding service
    /*const fromCoordinates = [from.lat, from.lng]; // Example: [51.505, -0.09]
    const toCoordinates = [to.lat, to.lng]; // Example: [51.515, -0.1]*/
    //console.log("map from", from);
    //console.log("map to", to);
    //console.log("map routes", routes);
    //const fromCoordinates = [from.lat, from.lng]; // Example: [51.505, -0.09]
    //const toCoordinates = [to.lat, to.lng]; // Example: [51.515, -0.1]

    //Defines a custom icon for the marker
    const defaultIcon = L.icon({
        iconUrl: icon, //iconUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        shadowUrl: iconShadow,
        iconAnchor: [16, 45], //Anchor point by default is at Leaflet marker's bottom tip
    });

    const myIcon = new L.Icon({
        iconUrl: '../helpers/map-marker.svg',
        // iconRetinaUrl: <Icon icon="mdi:map-marker" />,
        popupAnchor:  [-0, -0],
        iconSize: [32,45],     
    });

    L.Marker.prototype.options.icon = defaultIcon;
        
    // Log the from and to coordinates of each route
    /*routes.forEach((route, index) => {
        console.log(`Route ${index + 1} - From:`, route.from, "To:", route.to);
    });*/

    // Convert string coordinates to numbers and create objects
    const convertedRoutes = routes.map(route => {
        return {
            from: { lat: parseFloat(route.from[0]), lng: parseFloat(route.from[1]) },
            to: { lat: parseFloat(route.to[0]), lng: parseFloat(route.to[1]) },
            distance: route.distance,
            co2: route.co2
        };
    });
    
    //Finds out the boundaries of the map considering the routes. The map will auto zoom to fit the route lines.
    const MapEffect = () => {
        const map = useMap();

        if (convertedRoutes.length > 0) {
            const allPoints = convertedRoutes.flatMap(route => [route.from, route.to]);
            const bounds = L.latLngBounds(allPoints);
            map.fitBounds(bounds);
        }

        return null;
    };

    // Calculates the midpoint in a route between two points. The map will use that point to center. 
    const calculateMidpoint = (from, to) => {
        if (!from || !to || isNaN(from.lat) || isNaN(from.lng) || isNaN(to.lat) || isNaN(to.lng)) {
            return null; // Return null if coordinates are invalid
        }
        return [(from.lat + to.lat) / 2, (from.lng + to.lng) / 2];
    };

    //Assuming the first route is used for initial centering
    //const initialCenter = routes.length > 0 ? calculateMidpoint(routes[0].from, routes[0].to) : [0, 0];
    const initialCenter = convertedRoutes.length > 0 ? calculateMidpoint(convertedRoutes[0].from, convertedRoutes[0].to) : [0, 0];

    //Draggable Marker TEST
    /**
     * function DraggableMarker() {
        const [draggable, setDraggable] = useState(false)
        const [position, setPosition] = useState(initialCenter)
        const markerRef = useRef(null)
        const eventHandlers = useMemo(
          () => ({
            dragend() {
              const marker = markerRef.current
              if (marker != null) {
                setPosition(marker.getLatLng())
              }
            },
          }),
          [],
        )
        const toggleDraggable = useCallback(() => {
          setDraggable((d) => !d)
        }, [])
      
        return (
          <Marker
            draggable={draggable}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}>
            <Popup minWidth={90}>
              <span onClick={toggleDraggable}>
                {draggable
                  ? 'Marker is draggable'
                  : 'Click here to make marker draggable'}
              </span>
            </Popup>
          </Marker>
        )
      }
     * 
     */
    

    return (
        <div className="relative h-full w-full rounded-sm overflow-clip">
            <MapContainer center={initialCenter} zoom={zoomLevel} style={{ height: height, width: width }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {convertedRoutes.map((route, index) => (
                    <React.Fragment key={index}>
                        <Marker position={route.from} /> {/*** icon={defaultIcon}*/}
                        <Marker position={route.to} />
                        <Polyline positions={[route.from, route.to]} color="blue" />
                    </React.Fragment>
                ))}
                <MapEffect/>
                {/**<DraggableMarker/>**/}
            </MapContainer>
            {/**
             * <MapContainer center={fromCoordinates} zoom={13} style={{ height: "100%", width: "100%" }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={fromCoordinates} />
                <Marker position={toCoordinates} />
                <Polyline positions={[fromCoordinates, toCoordinates]} color="blue" />
            </MapContainer>
            <div className="absolute top-2.5 right-2.5 bg-white p-3 rounded-md shadow-sm" style={{ zIndex: 1000 }}>
                <p class="text-sm">Distance: {distance} km</p>
                <p class="text-sm">CO2 Emission: {co2} kg</p>
            </div>
             */}
            {/**"Card" used initially to show certain data on top of the map, in one corner. No longer in used. */}
            {showInfo && (
                <div className="absolute top-2.5 right-2.5 bg-white p-3 rounded-md shadow-sm" style={{ zIndex: 1000 }}>
                    {convertedRoutes.map((route, index) => (
                        <div key={index} >
                            <p className="text-sm">Route {index + 1} - Distance: {route.distance} km</p>
                            <p className="text-sm">CO2 Emission: {route.co2} kg</p>
                        </div>
                    ))}
                </div>
            )}            
        </div>
    );
};

export default map;
