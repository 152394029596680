import React from 'react';

const ComparisonTableCompaniesCertifications = (props) => {
    const {data} = props;

    //console.log("ComparisonTableProducers selectedData", data);
    //console.log("ComparisonTableProducers selectedData typeof", typeof data);

    if (!data || data.length === 0) {
        return <p>No hay certificados disponibles</p>;
      }

    return (
        <table className='w-full standard-table'>
            <tbody>
            {data.map((certification, index) => (
                <React.Fragment key={index}>
                    <tr>
                        <td>Nombre</td>
                        <td>{certification.name || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Descripción</td>
                        <td>{certification.description || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Autoridad</td>
                        <td>{certification.authority || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Número</td>
                        <td>{certification.num || 'N/A'}</td>
                    </tr>
                </React.Fragment>
            ))}   
            </tbody>
        </table>
    );
};

export default ComparisonTableCompaniesCertifications;
