import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import ZeroFoodLogoNobackground from "../helpers/ZeroFoodLogoNobackground.png";
import { Icon } from "@iconify/react";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profession, setProfession] = useState("");
  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // State for spinner visibility

  const validate = () => {
    let isValid = true;
    let errors = {};

    // Validation logic for name, email, confirmEmail, password, and profession

    if (!name) {
      isValid = false;
      errors.name = "El nombre es necesario";
    }

    if (!email) {
      isValid = false;
      errors.email = "El email es necesario";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      isValid = false;
      errors.email = "El email no es válido";
    }

    if (email !== confirmEmail) {
      isValid = false;
      errors.confirmEmail = "Los emails no coinciden.";
    }

    if (!password) {
      isValid = false;
      errors.password = "El password es necesario.";
    }

    if (!profession) {
      isValid = false;
      errors.profession = "Por favor, introduce to profesión.";
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      try {
        //Calling the serverless function to connect to the DB and update it with the data of the new user
        const response = await fetch("/api/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            password,
            profession,
          }),
        });
        console.log("Register response", response);
        const data = await response.json();

        if (response.ok) {
          // Registration successful
          console.log("Registration successful:", data);
          setSuccessMessage("Se ha registrado de forma correcta");
          //set a delay for showing the success message
          setTimeout(() => {
            //navigate('/login'); // Redirect to the login page after successful registration
            navigate("/verifyEmailMsg");
          }, 1000);
        } else {
          // Handle registration error
          console.error("Registration error:", data.message);
          setResponseMessage(data.message || "Error during registration");
        }
      } catch (error) {
        console.error("Request error:", error);
        setResponseMessage("Error connecting to the server");
      } finally {
        setLoading(false); // Hide spinner after request completes
      }
    }
  };

  //MODIFY IT
  return (
    <div className="flex h-[100svh] sm:h-screen w-full flex-col justify-center items-center gap-y-4 p-2">
      <div className="flex flex-col rounded-xl pt-10 pb-6 px-3 md:px-8 w-full max-w-md gap-y-6 bg-white shadow-md">
        <div className="flex flex-col gap-y-3 items-center">
          <h1 className="text-dark-blue-950 w-auto text-center font-bold uppercase text-2xl">
            Registrate en
          </h1>
          <img
            src={ZeroFoodLogoNobackground}
            alt="zerofood logo"
            className="pointer-events-none object-contain w-20 h-24"
          />
        </div>
        <form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
          <div>
            <div className="relative">
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                id="floating_Name"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_Name"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                Nombre
              </label>
            </div>
            {errors.name && (
              <p id="outlined_error_help" className="mt-2 text-xs text-red-600">
                {errors.name}
              </p>
            )}
          </div>
          <div>
            <div className="relative">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="floating_Email"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_Email"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                E-Mail
              </label>
            </div>
            {errors.email && (
              <p id="outlined_error_help" className="mt-2 text-xs text-red-600">
                {errors.email}
              </p>
            )}
          </div>
          <div>
            <div className="relative">
              <input
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
                type="email"
                id="floating_confirmEmail"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_confirmEmail"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                Confirma tu E-Mail
              </label>
            </div>
            {errors.confirmEmail && (
              <p id="outlined_error_help" className="mt-2 text-xs text-red-600">
                {errors.confirmEmail}
              </p>
            )}
          </div>
          {/* <div >
                  <label className="text-dark-blue-950 block text-sm md:text-base font-thin">Confirma tu email</label>
                  <input
                      id="confirmEmail"
                      type="confirmEmail"
                      placeholder="ejemplo@zerofood.es"
                      className="w-full placeholder:text-dark-blue-950/50 text-dark-blue-950 mt-1 p-2 bg-white border-2 border-dark-blue-950 focus:border-dark-green-500 focus:outline-none focus:ring-0  rounded"
                      value={confirmEmail}
                      onChange={(e) => setConfirmEmail(e.target.value)}
                  />
                  {errors.confirmEmail && <p className="text-red-500">{errors.confirmEmail}</p>}
              </div> */}
          <div>
            <div className="relative">
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="floating_Password"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_Password"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                Contraseña
              </label>
            </div>
            {errors.password && (
              <p id="outlined_error_help" className="mt-2 text-xs text-red-600">
                {errors.password}
              </p>
            )}
          </div>
          <div>
            <div className="relative">
              <input
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
                type="text"
                id="floating_Profession"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_Profession"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                Profesión
              </label>
            </div>
            {errors.profession && (
              <p id="outlined_error_help" className="mt-2 text-xs text-red-600">
                {errors.profession}
              </p>
            )}
          </div>
          <input
            type="submit"
            value="Registrarse"
            className="bg-dark-blue-700 w-full py-3 text-white uppercase font-bold rounded hover:cursor-pointer hover:bg-sky-500 transition-colors"
          />
        </form>
        <div className="h-4 flex justify-center">
          {loading && (
            <Icon icon="svg-spinners:180-ring" className="text-dark-blue-700" />
          )}
          {responseMessage && <p className="text-sm text-red-500">{responseMessage}</p>}
          {successMessage && <p className="text-sm text-green-500">{successMessage}</p>}
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center gap-2">
          <p className="text-sm text-center ">
            ¿Ya tienes cuenta?
            <Link
              className="inline-block text-center ml-1 text-dark-blue-700 hover:text-dark-blue-500"
              to="/login">
              Login
            </Link>
          </p>
          <Link
            className="block text-center text-dark-blue-700 hover:text-dark-blue-500 w-fit text-sm"
            to="/forgotPassword">
            Olvidé mi contraseña
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
