import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ZeroFoodLogoNobackground from '../helpers/ZeroFood-logo.svg';
import { Link } from 'react-router-dom';
import { Icon } from "@iconify/react";

const VerifyEmail = () => {
  const [message, setMessage] = useState('Verificando tu email...');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false); // State for spinner visibility

  //Extract token from URL
  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/api/verifyEmail?token=${token}`, {
                method: 'GET',
            });
            const data = await response.json();
            if (response.ok) {
                setMessage('Correo electrónico verificado con éxito');
                setTimeout(() => navigate('/login'), 10000); // Redirigir después de 3 segundos
            } else {
                setErrors(data.message || 'La verificación ha fallado. Por favor, intenta de nuevo.');
            }
        } catch (error) {
            setMessage('Ocurrió un error durante la verificación. Por favor, intenta de nuevo.');
        } finally {
            setLoading(false); //Hide spinner after request completes
        }
    };

    if (token) {
        verifyEmail();
    } else {
        setMessage('No se encontró el token de verificación.');
    }
  }, [token, navigate]);

  return (
    <div className="flex h-[100svh] sm:h-screen w-full flex-col justify-center items-center gap-y-4 p-2">
    <div className="flex flex-col rounded-xl pt-10 pb-6 px-3 md:px-8 w-full max-w-md gap-y-6 bg-white shadow-md">
        <div className="flex flex-col gap-y-3 items-center">
            {/**Note: Change of colors and style of login for testing purposes. Colors originally: text-sky-600, For testing: dark-blue-900 */}
            <h1 className="text-dark-blue-950 w-fit text-center font-bold uppercase text-2xl">Verificación de tu cuenta en</h1> 
            <img src={ZeroFoodLogoNobackground} alt="zerofood logo" className="pointer-events-none object-contain w-20 h-24"/>
        </div>
        {message && <p className="text-green-500">{message}</p>}
        {errors.success && <p className="text-sm text-green-500">{errors.success}</p>}
        <div className="h-4 flex justify-center">
            {loading && (
                <Icon icon="svg-spinners:180-ring" className="text-dark-blue-700" />
            )}
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center gap-2">
            <p className="text-sm text-center ">
                A continuación se te redireccionará a la página de inicio de sesión. Si no es así, haz clic en el siguiente enlace:
                <Link
                    className="inline-block text-center ml-1 text-dark-blue-700 hover:text-dark-blue-500"
                    to="/login"
                >
                Login
                </Link>
            </p>
        </div>
    </div>
</div>
  );
};

export default VerifyEmail;
