import React, { useState } from 'react';
import TableProductsCheckbox from '../tables/TableProductsCheckbox';
import {Tooltip} from 'react-tooltip';
import { Icon } from '@iconify/react';

const SearchBar = ({ data, columns, onSelectionChange, onCompareClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleCompareAndCloseDropdown = () => {
    onCompareClick(); // Call the original onCompareClick
    toggleDropdown(); // Then close the dropdown
};

  return (
    <div className={`p-2 relative bg-light-green-100 z-10  ${showDropdown ? 'shadow-lg' : ''}` }>
      <button 
        onClick={toggleDropdown} 
        className="bg-white z-[11] rounded-md px-3 py-1.5 flex items-center justify-between text-dark-blue-900 hover:text-dark-blue-700">
          <span className="mr-2">
          Productores
          </span>
          <Icon icon="mdi:chevron-down" className='h-5 w-5 text-current' />
      </button>
      {showDropdown && (
        <div 
          className="absolute left-0 z-10 bg-light-green-100 p-2  rounded-tr-xl rounded-b-xl shadow-lg "
          data-tooltip-id="tableSearchBar"
          data-tooltip-content="Selecciona dos o más productores y pulsa Compara para comparar el consumo de agua."
        >
          <TableProductsCheckbox 
            data={data} 
            columns={columns} 
            onSelectionChange={onSelectionChange} 
          />
          <Tooltip className="custom-tooltip" id="tableSearchBar" place="top" type="dark" effect="solid"/>
          <button className="bg-dark-blue-900 text-white w-full p-2 rounded mt-2 hover:bg-dark-blue-700" onClick={handleCompareAndCloseDropdown}>Compara</button>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
