// src/components/ChartBar.jsx

import React, { useState, useEffect }  from 'react';
import { Bar } from 'react-chartjs-2';
import ReactECharts from 'echarts-for-react';

const ChartBarEvents = ({ CO2absorption }) => {
  //console.log("Inside chartbar");
  //const [chartData, setChartData] = useState({ categories: [], values: [] });  

  const grid = {
    left: 100,
    right: 100,
    top: 70,
    bottom: 50
  };

  const options = {
    title: {
      text: CO2absorption?.description || '',
    },    
    xAxis: {
      type: 'category',
      data: CO2absorption?.categories || [],//['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
    },
    yAxis: {
      type: 'value',
    },
    responsive: true,
    series: [
      {
        data: CO2absorption?.values || [],//CO2absorption?.values || [],//[120, 200, 150, 80, 70],
        type: 'bar',
        barWidth: '40%', // Set the width of the bars
        itemStyle: {
        color: '#74c475', // Set the color of the bars
        },
        label: {
            show: true,
            position: 'top',
            color: '#333', // You can set the color of the text here
            //formatter: '{c}' // '{c}' refers to the value of the data point
            formatter: function (params) {
                return params.value + ' kg CO2'; // Appends 'kg CO2' after the value
            }
        },
      },
    ],
  };

    return (
        <div className="flex w-full grow-1 items-center h-full bg-white p-1 rounded-md">
          <ReactECharts 
            option={options} 
            style={{  height: '300px', width: '100%', }} //height 100% - chart is shown too short in Events
          />
        </div>
    );
};

export default ChartBarEvents;
