import React, { useMemo, useEffect, useState, useRef } from 'react'
import { useTable } from 'react-table'
//import { COLUMNS_FARMS } from '../columns/columnProdFarms'
import './table.css'

const TableProductsSelectRowHigh = (props) => {

    //const harvestData = '/data/harvestData.json'
    /**useMemo Ensures the data is not recreated in every render */
    //const columns = useMemo( () => COLUMNS_FARMS, [])
    const columns = useMemo(() => props.columns, [props.columns]);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const tableRef = useRef(null);
    //const data = props.data || []; // Use passed data or default to an empty array
    
    const handleClickOutside = (event) => {
        if (tableRef.current && !tableRef.current.contains(event.target)) {
            setSelectedRow(null); // Reset the selected row
        }
    };
    
    const changeColor = (id) => {
        setSelectedRow(id);
        console.log("Table changeCOlor selectedRow", selectedRow);
    }

    useEffect(() => {
        // Attach an event listener to handle clicks outside of the table
        /*const handleClickOutside = (event) => {
            if (tableRef.current && !tableRef.current.contains(event.target)) {
                props.onRowSelect(null); // Reset the selected row in the parent component
            }
        };*/

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); //props

    const tableInstance = useTable({
        columns,
        data: props.data || []
    })    
    //console.log("data in TableProducts", data);
    /**Functions and arrays that the useTable hook from react table package has given to us to enable easy table creation */
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, } = tableInstance

    console.log("Table selectedRow", props.selectedRowData);

    return (
        <div className=''>
            {props.header && <h2 className="text-lg font-bold mb-2">{props.header}</h2>}
            <table ref={tableRef} className='standard-table compact-table table-auto'{...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th className='' {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}                
                </thead>
                <tbody className="" {...getTableBodyProps()}>
                    {
                        rows.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps({    
                                        key: index,                               
                                        onClick: () => {            
                                            //changeColor(row.original.id);                                
                                            //console.log("Table row.original", row.original);
                                            //console.log("Table Currently selected row:", selectedRow);
                                            props.onRowSelect(row.original);
                                            //setSelectedRow(row.original); // Update the selected row state
                                        },
                                        style: { cursor: 'pointer' },
                                        className: row.original.uuid === props.selectedRowData?.uuid ? 'selected-row' : '' // Tailwind class for background color
                                    })}
                                    //key={index}
                                    //className="selected-row"
                                >
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
            </table>
        </div>

    )
}

export default TableProductsSelectRowHigh