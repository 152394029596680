import React from 'react';

const ComparisonTableCompaniesContact = (props) => {
    const {data} = props;

    //console.log("ComparisonTableProducers selectedData", data);
    //console.log("ComparisonTableProducers selectedData typeof", typeof data);

    if (!data) {
        return <p>No data available</p>;
    }

    return (
        <table className='w-full standard-table'>
            <tbody>
                <tr>
                    <td>Teléfono</td>
                    <td>{data ? data.phoneNumber : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Teléfono Móvil</td>
                    <td>{data ? data.mobileNumber : 'N/A'}</td>
                </tr> 
                <tr>
                    <td>Email</td>
                    <td>{data ? data.email : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Página web</td>
                    <td>{data ? data.website : 'N/A'}</td>
                </tr>    
                <tr>
                    <td>Ecommerce</td>
                    <td>{data ? data.ecommerce : 'N/A'}</td>
                </tr>             
            </tbody>
        </table>
    );
};

export default ComparisonTableCompaniesContact;
