import React from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

const ChartLine = ({ data, title, value }) => {
  console.log("ChartLine data", data);
  //const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];
  /**Example */
  /*const rand = 300;
  for (let i = 0; i < 7; i++) {
    let d = {
      year: 2000 + i,
      value: Math.random() * (rand + 50) + 100
    };
  
    data.push(d);
  }*/

   // Check if there's only one data point and duplicate it if necessary
   const adjustedData = data.length === 1
   ? [
       { ...data[0], date: new Date(new Date(data[0].date).getTime() - (24 * 60 * 60 * 1000)).toISOString().split('T')[0] }, // day before
       data[0],
       { ...data[0], date: new Date(new Date(data[0].date).getTime() + (24 * 60 * 60 * 1000)).toISOString().split('T')[0] }, // day after
     ]
   : data;

 // Calculate dataMin and dataMax
 const counts = adjustedData.map(item => item.count);
 const dataMax = Math.max(...counts);
 const dataMin = Math.min(...counts);

  //bg-white
  return (
    <div className="p-4 bg-white rounded-md flex shadow-sm justify-center items-center" style={{ width: '100%', height: '100%', padding: '20px' }}>
      <h2>{title}</h2>
      {/**
       *       <LineChart width={400} height={250} data={data} margin={{ top: 5, right: 5, bottom: 5, left: 0 }}>
       */}
      <LineChart width={700} height={600} data={data} margin={{ top: 5, right: 5, bottom: 5, left: 0 }}>
        <Line type="monotone" dataKey="value" stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="date" />
        <YAxis/> {/* Adjust domain to ensure single data point is visible */}
        {/**
         * <YAxis domain={['dataMin - 1', 'dataMax + 1']} />
         */}
      </LineChart>
    </div>
  )
}

export default ChartLine