import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useRowSelect } from 'react-table';
import './table.css';

const TableProductsCheckbox = (props) => {
    const columns = useMemo(() => props.columns, [props.columns]);
    const [data, setData] = useState([]);

    const tableInstance = useTable(
        {
            columns,
            data: props.data || []
        },
        useRowSelect
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows // This function provides you with the selected rows
    } = tableInstance;

    // Use useEffect to detect changes in selected rows and notify the parent component
    useEffect(() => {
        if (props.onSelectionChange) {
            // Extract the data from the selected rows
            const selectedData = selectedFlatRows.map(row => row.original);
            props.onSelectionChange(selectedData); // Notify the parent component
        }
    }, [selectedFlatRows]);

    /**
     * This was just below the table.
     * <div>
            <p>Selected Rows: {selectedFlatRows.length}</p>
        </div>
     */
    return (
        <div>
            {props.header && <h2 className="text-lg font-bold mb-2">{props.header}</h2>}
            <table className='table-auto standard-table' {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            <th></th> {/* Add an empty th to offset the checkbox column */}
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                <td>
                                    <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                                </td>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>            
        </div>
    );
};

export default TableProductsCheckbox;
