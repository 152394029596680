import React, { useState, useEffect  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ForgotPasswordReset = () => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [responseMessage, setResponseMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // To track loading state

    //Extract the token from URL query parameters
    const token = new URLSearchParams(location.search).get('token');
    
    useEffect(() => {
        if (!token) {
            setMessage('Token de restablecimiento no proporcionado o inválido.');
            setTimeout(() => navigate('/login'), 3000);
        }
    }, [token]);

    const validate = () => {
        let isValid = true;
        let errors = {};
    
        if (!email) {
            isValid = false;
            errors.email = 'El email es necesario';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            isValid = false;
            errors.email = 'El email no es válido';
        }
        
        if (!newPassword) {
            isValid = false;
            errors.newPassword = 'Se necesita una nueva contraseña.';
        }

        if (newPassword !== confirmPassword) {
            isValid = false;
            errors.confirmPassword = 'Las contraseñas no coinciden.';
        }
        
        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setIsLoading(true); //Indicate loading state
            try {
                // Call the serverless function to reset password
                const response = await fetch('/api/passwordReset', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, resetToken: token, newPassword }),
                });

                const data = await response.json();
                //setMessage(data.message);

                //If the response is OK, navigate to the login page
                if (response.ok) {
                    setMessage('Nueva contraseña creada con éxito'); //displays text in red. Duplicated text. It's already shown in green (setMessage(data.message);)

                    setTimeout(() => {
                        navigate('/login');
                    }, 3000); 
                } else {
                    const data = await response.json();
                    setMessage(data.message || 'Ocurrió un error al restablecer la contraseña.');
                }
            } catch (error) {
                console.error('Request error:', error);
                setResponseMessage('Error conectando con el servidor');
            } finally {
                setIsLoading(false); // Reset loading state
            }
        }         
    };

    return (
        <div className="flex h-[100svh] sm:h-screen w-full flex-col justify-center items-center gap-y-4 p-2">
            <div className="flex flex-col rounded-xl pt-10 pb-6 px-3 md:px-8 w-full max-w-md gap-y-6 bg-white shadow-md">
                <h1 className="text-dark-blue-950 w-auto text-center font-bold uppercase text-2xl">Crear nueva contraseña</h1> 
                <form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
                    <div>
                        <div className="relative">
                            <input 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)}
                                type="email" 
                                id="floating_Email" 
                                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                            <label 
                                htmlFor="floating_Email" 
                                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">E-Mail</label>
                        </div>
                        {errors.email && 
                            <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.email}</p>
                        }
                    </div>                    
                    <div>
                        <div className="relative">
                        <input 
                            value={newPassword} 
                            onChange={(e) => setNewPassword(e.target.value)}
                            type="password" 
                            id="newPassword" 
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                        <label 
                            htmlFor="newPassword" 
                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Nueva Contraseña</label>
                        </div>
                        {errors.newPassword && 
                            <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.newPassword}</p>
                        }
                    </div>
                    <div>
                        <div className="relative">
                        <input 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type="password" 
                            id="confirmPassword" 
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none hover:border-gray-400 transition-colors  focus:outline-none focus:ring-0 focus:border-dark-blue-600 peer" placeholder=" " />
                        <label 
                            htmlFor="confirmPassword" 
                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-dark-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75  peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Confirma Nueva Contraseña
                        </label>
                        </div>
                        {errors.confirmPassword && 
                            <p id="outlined_error_help" className="mt-2 text-xs text-red-600">{errors.confirmPassword}</p>
                        }
                    </div>
                    <input
                        type="submit"
                        value="Crear Nueva Contraseña"
                        className="bg-dark-blue-700 w-full py-3 text-white uppercase font-bold rounded hover:cursor-pointer hover:bg-sky-500 transition-colors"
                        />
                </form>
                {responseMessage && <p className="text-red-500">{responseMessage}</p>}
                {message && <p className="text-green-500">{message}</p>}
            </div>
        </div>
    );
};

export default ForgotPasswordReset;
