import React, { useState } from 'react';
import Dropdown from './Dropdown';
import ChartBar from '../charts/ChartBar';
import ChartPie from '../charts/ChartPie';

const ChartSelection = () => {
  const [selectedChart, setSelectedChart] = useState('pie');
  const chartBarCO2 = '/data/chartBarCO2.json';

  return (
    <div className="bg-white p-4 rounded-lg flex flex-col shadow-sm h-full">
      <div className="flex justify-between items-center mb-4">
        <div className="mr-4">
          <h1 className="text-xl font-semibold">Productos fuera de temporada</h1>
          <p className="text-sm text-gray-600">Impacto en el medioambiente</p>
        </div>
        <Dropdown 
          onChange={(e) => setSelectedChart(e.target.value)} 
          options={[
            { value: 'bar', label: 'Bar Chart' },
            { value: 'pie', label: 'Pie Chart' },
          ]}
        />
      </div>
      {selectedChart === 'bar' && <ChartBar jsonFilePath={chartBarCO2}/>}
      {selectedChart === 'pie' && <ChartPie />}
    </div>
  );
};

export default ChartSelection;
