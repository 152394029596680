import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';

/**Components */
import { Navbar, Footer, Sidebar, VerifyEmailMsg, VerifyEmail } from './components';
/**Pages */
import { Dashboard, Companies, Facilities, Products, Events, TrackNTrace, Analysis, Ajustes, Login, Register, ForgotPassword, ForgotPasswordReset } from './pages';

import { useStateContext } from './contexts/ContextProvider';   /**From contextProvider */
import { useAuth } from './contexts/AuthContext';   /**From contextProvider */
import './App.css'

const App = () => {
    /**Before creating a ContextProvider inside contexts folder we can add this line:
     * const activeMenu = true;  
     * After creating the contextProvider and importing it into App.js we no longer need it. 
     * We substitute it with the following line:
     */
    const { activeMenu, setActiveMenu, setActiveTopbar, showIconsOnly } = useStateContext(); /**Hook. from ContextProvider */
    // Dynamic sidebar width based on showIconsOnly state
    const sidebarWidthClass = showIconsOnly ? "md:w-16 " : "lg:w-72";
    const mainContentMarginClass = showIconsOnly ? 'md:pl-16' : 'lg:pl-72'; // Adjust these margins to match the sidebar widths
    const { isAuthenticated } = useAuth();

    return (
        <div>
            <BrowserRouter>
                <div className='flex relative transition-[max-width] duration-200 overflow-hidden bg-light-green-100'>
                    {isAuthenticated ?
                        (
                            <>
                                {activeMenu && (
                                    <button onClick={() => setActiveMenu(false)} className={`${(activeMenu) ? 'opacity-100 ' : 'opacity-0 pointer-events-none'} cursor-default md:hidden transition-opacity absolute  inset-0 bg-black/30 z-40`}></button>
                                )}
                                {activeMenu ? (
                                    <div className={`fixed sidebar transition-[width] duration-200 bg-dark-blue-900 z-[9999] ${sidebarWidthClass}`}>
                                        <Sidebar />
                                    </div>
                                ) : (
                                    <div className='w-0'>
                                        <Sidebar />
                                    </div>
                                )}
                                <div className={`min-h-[100svh] md:h-screen w-full ${mainContentMarginClass}`}>
                                    <div className='h-full m-0'>
                                        {/**Only accessible if logged in successfully */}
                                        <Routes>
                                            <Route path="/" element={<Dashboard />} />
                                            <Route path="/dashboard" element={<Dashboard />} />
                                            <Route path="/companies" element={<Companies />} />
                                            <Route path="/facilities" element={<Facilities />} />
                                            <Route path="/products" element={<Products />} />
                                            <Route path="/events" element={<Events />} />
                                            <Route path="/analysis" element={<Analysis />} />
                                            <Route path="/ajustes" element={<Ajustes />} />
                                        </Routes>
                                    </div>
                                </div>
                            </>
                        ) : (
                            //Accessible by anyone.
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/forgotPassword" element={<ForgotPassword />} />             {/**Is it safe to leave this here? Maybe add a condition handled in AuthContext*/}
                                <Route path="/verifyEmailMsg" element={<VerifyEmailMsg />} />             {/**Is it safe to leave this here? Maybe add a condition handled in AuthContext */}
                                <Route path="/forgotPasswordReset" element={<ForgotPasswordReset />} />   {/**Is it safe to leave this here? Maybe add a condition handled in AuthContext */}
                                <Route path="/verifyEmail" element={<VerifyEmail />} />   {/**Is it safe to leave this here? Maybe add a condition handled in AuthContext */}
                                <Route path="*" element={<Navigate to="/login" />} />
                            </Routes>
                        )
                    }
                    {/**
                 * {activeMenu ? (
                        <div className={`fixed sidebar transition-[width] duration-200 bg-dark-blue-900 z-[9999] ${sidebarWidthClass}`}>
                        <Sidebar/>
                        </div>
                    ) : (
                        <div className='w-0 dark:bg-secondary-dark-bg'>
                            <Sidebar/>
                        </div>    
                    )}
                    <div className={`min-h-screen md:h-screen w-full ${mainContentMarginClass}`}>
                        <div className='h-full m-0'>
                        <Routes>
                            <Route path="/" element={<Dashboard/>}/>
                            <Route path="/dashboard" element={<Dashboard/>}/>
                            <Route path="/companies" element={<Companies/>}/>
                            <Route path="/facilities" element={<Facilities/>}/>
                            <Route path="/products" element={<Products/>}/>
                            <Route path="/events" element={<Events/>}/> 
                            <Route path="/analysis" element={<Analysis/>}/> 
                            <Route path="/ajustes" element={<Ajustes/>}/> 
                        </Routes>
                        </div>
                    </div>
                 */}
                    {/**
                 * { isAuthenticated ? (
                    <>
                    {activeMenu && (
                        <div className={`fixed sidebar transition-[width] duration-200 bg-dark-blue-900 z-[9999] ${sidebarWidthClass}`}>
                        <Sidebar/>
                        </div>
                    )}

                    <div className={`min-h-screen md:h-screen w-full ${mainContentMarginClass}`}>
                        <div className='h-full m-0'>
                        <Routes>
                            <Route path="/" element={<Dashboard/>}/>
                            <Route path="/dashboard" element={<Dashboard/>}/>
                            <Route path="/companies" element={<Companies/>}/>
                            <Route path="/facilities" element={<Facilities/>}/>
                            <Route path="/products" element={<Products/>}/>
                            <Route path="/events" element={<Events/>}/> 
                            <Route path="/analysis" element={<Analysis/>}/> 
                            <Route path="/ajustes" element={<Ajustes/>}/> 
                        </Routes>
                        </div>
                    </div>
                    </>
                ) : (
                    <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/forgotPassword" element={<ForgotPassword/>}/>
                    <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                )}
                 */}
                </div>
            </BrowserRouter>
        </div>
    )
}

export default App