import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import './table.css';
import { Icon } from '@iconify/react';

const TableEventsSelectRow = (props) => {
    const columns = useMemo(() => props.columns, [props.columns]);
    const [data, setData] = useState([]);

    const tableInstance = useTable(
        {
            columns,
            data: props.data || [],
        },
        useSortBy // Enable sorting
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <div className=''>
            {props.header && <h2 className="text-lg font-bold mb-2">{props.header}</h2>}
            <table className='standard-table compact-table table-auto' {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th className='relative' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span className='absolute right-0'>
                                        {column.isSorted ? (column.isSortedDesc ?
                                            <Icon className="text-gray-300" icon="bi:caret-down-fill"></Icon>
                                            // ' 🔽' 
                                            :
                                            <Icon className="text-gray-300" icon="bi:caret-up-fill"></Icon>
                                            // ' 🔼'
                                        )
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className='' {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        const rowClassName = props.selectedRow === row.original ? 'bg-lime-green-400' : '';

                        return (
                            <tr
                                {...row.getRowProps({
                                    className: rowClassName,
                                    onClick: () => props.onRowSelect(row.original),
                                    style: { cursor: 'pointer' },
                                })}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td className='' {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TableEventsSelectRow;
