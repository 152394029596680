import React, { useState, useEffect, useMemo, useRef } from 'react'
// import TableProductsSelectRow from '../tables/TableProductsSelectRow';
import ComparisonTableEventsData from '../tables/ComparisonTableEvents';
import ChartBarHorizontal from '../charts/ChartBarHorizontal';
import ChartBarStack from '../charts/ChartBarStack';
import ChartBar from '../charts/ChartBar';
import ChartBarEvents from '../charts/ChartBarEvents';
import TableEventsSelectRow from '../tables/TableEventsSelectRow';
import {Tooltip} from 'react-tooltip';
import { Icon } from '@iconify/react';
import { useStateContext } from '../contexts/ContextProvider';
import FinanceSection from '../components/FinanceSection';

const LeftContent = ({ showTable, setShowTable, onSearch, onRowSelect, setSelectedRowData, setShowMiddleContent, setShowRightContent, setCO2Transport, setCO2TransportMx, setCO2Packaging, setCO2PackagingMx, setCO2absorption }) => {
    const [input, setInput] = useState("");
    const [storedItems, setStoredItems] = useState([]);
    //const transportistasJSON = '/data/transportistas.json';
    const trustyEventsJSON = '/data/trustyEvents.json';
    const [error, setError] = useState(null);
    const timerRef = useRef(null);
    const [showTooltipInput, setShowTooltipInput] = useState(false);
    const [showTooltipTable, setShowTooltipTable] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    //
    const [responseMessage, setResponseMessage] = useState('');
    const [noDataFoundMessage, setNoDataFoundMessage] = useState('');      
    //  
    const [isLoading, setIsLoading] = useState(false);
    //
    const { products, setProducts, locations, setLocations } = useStateContext();     /**From contextProvider */

    //Get the product name of the GTIN received from the API. It takes the name from product state used in Products component
    const getProductName = (gtin) => {
      const product = products.find(p => p.gtin === gtin);
      return product ? product.name : gtin;
    };    

    const getLocationName = (gln) => {
      //console.log("Events getLocationName gln", gln);
      //console.log("Inside getLocationName, locations:", locations, "type:", typeof locations);
      if (!Array.isArray(locations)) {
        console.error('Expected locations to be an array, received:', locations);
        return 'No Location';
      }
      if (!gln) {
        return 'No Location'; // or any other default value you prefer
      }
      const location = locations.find(l => l.gln === gln);
      if (!location) {
        //console.log('Events No matching location for gln', gln);
      }
      return location ? location.name : 'Localización desconocida';
    };

    //console.log("Events locations", locations);
    //console.log("Events products", products);
    const fetchData = async (category) => {
      setIsLoading(true); // Start loading
      setError(null); // Reset previous errors
      try {
        // Call the serverless function here
        const response = await fetch(`/api/getTrustyData?category=${category}`, {
          method: 'GET'
        });
        //console.log("Eventos response", response);
        const retrievedJSON = await response.json();
        if (response.ok && Array.isArray(retrievedJSON.data)) {
          //Note: Filter is a temporary hack to hide some events created as test and therefore not having the right data
          const formattedData = retrievedJSON.data.filter(item => 
            item.name && //Checks if name is not false (covers undefined, null, and "")
            item.inputs?.length > 0 && 
            item.outputs?.length > 0 && 
            item.name !== "Shipping to Destributor")
          .map(item => ({
            ...item,
            eventTime: new Date(item.eventTime).toLocaleDateString('en-GB'), // Formats to 'DD/MM/YYYY'
            sourceLocations: item.sourceLocations.map(gln => getLocationName(gln)),
            destinationLocations: item.destinationLocations.map(gln => getLocationName(gln)),
            location: getLocationName(item.location),
            inputs: item.inputs.map(input => ({ ...input, productName: getProductName(input.gtin) })),
            outputs: item.outputs.map(output => ({ ...output, productName: getProductName(output.gtin) })),
          }));
  
          setStoredItems(formattedData);
          setFilteredItems(formattedData);       
          setShowTable(retrievedJSON.data.length > 0); //setShowTable(true); 
          //console.log('Eventos data received:', retrievedJSON);
          //console.log('Eventos formattedData:', formattedData);
        } else {
          console.error('Error recibiendo datos de Productos:', retrievedJSON.message); //Reads the message sent from the "backend"
          //setError('No se pudieron recibir datos del servidor'); //Reads the message sent from the "backend"
          setError('Error en el formato de datos o en la respuesta del servidor')
        }
        
      } catch (error) {
        console.error('Request error:', error);
        setResponseMessage('Error conectando con el servidor');
      }
      setIsLoading(false); // End loading
    } //End fetchData 

    useEffect(() => {
      fetchData('events');
    }, [])

    const handleSearch = () => {
      if (input.trim() !== "") {
        const filteredData = storedItems.filter(item =>
            item.name.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredItems(filteredData);
        setShowTable(filteredData.length > 0);
  
        // Set message if no data is found
        if (filteredData.length === 0) {
            setNoDataFoundMessage('No se encontraron instalaciones con ese nombre.');
        } else {
            setNoDataFoundMessage('');
        }
      // Check if the input is empty
      /*if (input.trim() === "") {
        //setStoredItems([]);   // Clear any stored items
        setFilteredItems([]);
        //onSearch(storedItems);         // Clear the filtered data
        setShowTable(true); // if input is empty, show all items
        //setSelectedRowData(null); // Reset selected row data
        //console.log("TrackNTrace onSearch", onSearch);
      } else {
        const filteredData = storedItems.filter(item =>         
          item.event.input.product.toLowerCase().includes(input.toLowerCase()) ||
          item.event.input.lotcode.toLowerCase().includes(input.toLowerCase())
        );

        //console.log("TrackNTrace filteredData", filteredData);

        setError(filteredData.length === 0 ? "No se encontraron productos o lotes con ese nombre." : null);
        setFilteredItems(filteredData);
        //onSearch(filteredData);
        //setStoredItems(filteredData);
        setShowTable(filteredData.length > 0);*/
      }  
       
      

      /*fetch(trustyEventsJSON) 
        .then(response => {
          console.log("TrackNTrace response", response);
          return response.json();
        })
        .then(data => {
          console.log("TrackNTrace data", data);
          console.log("TrackNTrace Input value", input.toLowerCase());
          const filteredData = data.filter(item =>         
            item.event.input.product.toLowerCase().includes(input.toLowerCase()) ||
            item.event.input.lotcode.toLowerCase().includes(input.toLowerCase())
          );
          //
          if (filteredData.length === 0) {
            setError("No se encontraron productos o lotes con ese nombre.");
          } else {
            setError(null);
          }
          console.log("TrackNTrace filteredData", filteredData);
          console.log("TrackNTrace filteredData", typeof filteredData, filteredData);
          setStoredItems(filteredData);
          onSearch(filteredData);
          setShowTable(filteredData.length > 0);
        })
        .catch(error => console.error("Failed to load data", error));*/
    };
    //console.log("storedItems", storedItems);
    /**
     * const columns = useMemo(() => [
      { Header: "Fecha del Evento", accessor: "eventTime"},
      { Header: "Evento", accessor: "name"},
      { Header: "Tipo de Evento", accessor: item => `${item.eventType} - ${item.bizStep}`},
      { Header: "Localización", accessor: "location"}, //?
      { Header: "Entrada - Producto", accessor: item => item.inputs.map(input => input.gtin).join(", ") }, //?
      { Header: "Salida - Producto", accessor: item => item.outputs.map(output => output.gtin).join(", ") }, //?
      { Header: "Blockchain", accessor: item => item.blockchain.map(block => block.publicUrl).join(", ") } //?
    ], []);
     */

    const columns = useMemo(() => [
      { Header: "Fecha", accessor: "eventTime"},
      { Header: "Tipo", accessor: item => `${item.eventType} - ${item.bizStep}`},
      { Header: "Evento", accessor: "name"},
      { Header: "Instalación", accessor: "location"},
      { Header: "Entrada", accessor: item => item.inputs.map(input => input.productName).join(", ") },
      { Header: "Salida", accessor: item => item.outputs.map(output => output.productName) },
      // { 
      //   Header: "Blockchain", 
      //   accessor: "blockchain",
      //   Cell: ({ cell: { value } }) => {
      //     // Assuming value is an array of objects with a 'publicUrl' property
      //     return value.map((block, index) => (
      //       <a key={index} href={block.publicUrl} target="_blank" rel="noopener noreferrer">
      //         <Icon icon="icon-park:blockchain"/>
      //       </a>
      //     ));
      //   }
      // }
    ], []);

    //console.log("Events columns", columns);

    const handleInputChange = (e) => {
      const value = e.target.value;
      // Regular expression to allow only numbers, letters, accents, and spaces
      if (/^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 ]*$/.test(value)) {
        setInput(value);
        if (value.trim() === "") {
            setFilteredItems(storedItems); 
            setShowTable(storedItems.length > 0);
            setSelectedRowData(null); // Reset selected row data
            setNoDataFoundMessage(''); // Reset no data found message
        } else {
          handleSearch(); // Call handleSearch to filter items based on the input
        }
      }
      /*setInput(e.target.value);
      if (e.target.value.trim() === "") {
        setFilteredItems([]);  // Clear the filtered items
        //onSearch(storedItems); // Pass all stored items to the parent component
        setShowTable(storedItems.length > 0); // Show the table if there are items
        //handleSearch();
        //setShowRightContent(false);
        //setShowMiddleContent(false);
      }
      setShowTooltipInput(true);*/
    };  
  
    const handleRowSelect = (data) => {
      //console.log("Event row selected:", data);
      // Reset CO2 data
      setCO2Transport(null);
      setCO2Packaging(null);
      setCO2TransportMx(null);
      setCO2PackagingMx(null);
      setCO2absorption(null);
      //
      setSelectedRowData(data);
      setShowRightContent(true);
    };
  
    const handleMouseEnter = (element) => {
      if (element === 'input') {
        setShowTooltipInput(true); // Show tooltip for input
      } else if (element === 'table') {
        setShowTooltipTable(true); // Show tooltip for table
      }
  
      timerRef.current = setTimeout(() => {
        if (element === 'input') {
          setShowTooltipInput(false); // Hide tooltip for input
        } else if (element === 'table') {
          setShowTooltipTable(false); // Hide tooltip for table
        }
      }, 2000);
    }
  
    const onMouseOut = (element) => {
      if (element === 'input') {
        setShowTooltipInput(false); // Hide tooltip for input
      } else if (element === 'table') {
        setShowTooltipTable(false); // Hide tooltip for table
      }
      clearTimeout(timerRef.current);
    }
  
    return (
      <div className="h-[100svh] sm:h-full flex gap-y-4 flex-col p-4 md:p-6 pt-14 md:pt-10 md:border-r border-white w-full sm:w-2/3 relative">
      <h1 className="text-3xl lg:text-3xl font-semibold text-dark-blue-600">Eventos</h1>
        <p className="text-sm text-dark-blue-900">Busca por nombre de evento para obtener más información.</p>
        <div className="flex">
        {/* ! TODO REVISA SI OPINAS QUE ESTO ESTA BIEN CON EL FAKE USER PARA SAFARI */}
      <input id="fake_user_name" name="fake_user[name]" className="-top-[100px] absolute opacity-0" type="text" value="Safari Autofill Me"/>

        <input 
            className="rounded p-2 flex-grow mr-2 focus:outline-primary-200 focus:outline-none focus:ring-0" 
            type="search" 
            autoComplete="new-off"
            placeholder="Escribe el nombre de un producto o número de lote..." 
            value={input} 
            onChange={handleInputChange} 
            onKeyUp={e => e.key === 'Enter' && handleSearch()}
            onMouseEnter={() => handleMouseEnter('input')}
            onMouseOut={() => onMouseOut('input')}
            data-tooltip-delay-show={500}
            data-tooltip-id="inputTrack"
            data-tooltip-html="Escribe el nombre de un evento." 
          />
          <button className="bg-dark-blue-900 hover:bg-dark-blue-700 h-full flex justify-center items-center aspect-square text-light-green-100 p-2 rounded" onClick={handleSearch}>
          <Icon icon="material-symbols:search" className='h-5 w-5' /></button>
          <Tooltip className="custom-tooltip" id="inputTrack" place="bottom" type="dark" effect="solid" isOpen={showTooltipInput}/>
        </div>
        {noDataFoundMessage && (
          <p className="my-4 text-red-600">{noDataFoundMessage}</p>
        )}
        {isLoading && 
          <div className="h-full w-full flex justify-center items-center">
            <p className="text-sm text-dark-blue-900">Cargando...</p>
          </div>
        }
        {error && (
          <p className="my-4 text-red-600">
          {error}
          </p>
        )}
        {/**Shows the table depending on the category selected on the leftContent panel and if the state showTable is active*/}
        
        {/**Similar than the previous but for the compareButton. This also calls the method that opens the Compare window */}
        {showTable && (
          <>      
            <div className="rounded-md h-full overflow-clip relative">
              <div 
                data-tooltip-id="tableTransportistas" 
                data-tooltip-content="Selecciona un lote de la tabla." 
                className="h-full  shrink-1 grow-1 overflow-y-auto table-container" 
                onMouseEnter={() => handleMouseEnter('table')}
                onMouseOut={() => onMouseOut('table')}  
              >
                <TableEventsSelectRow 
                  data={filteredItems} 
                  columns={columns} 
                  onRowSelect={handleRowSelect} 
                />                                  
              </div>          
              <Tooltip className="custom-tooltip" id="tableTransportistas" place="top" type="dark" effect="solid" isOpen={showTooltipTable}/>         
            </div>
          </>
        )}    
      </div>
    );
}; //End LeftContent
  
const MiddleContent = ({ selectedData, onButtonClick }) => {
    
    //console.log("MiddleContent selectedData", selectedData);
    //console.log("MiddleContent selectedData.event.eventtime", selectedData.event.eventtime);
    //console.log("MiddleContent selectedData.event.eventtime", Object.entries(selectedData.event.category));
    const [selectedCategory, setSelectedCategory] = useState(null);

    //Handles category selectio
    const handleCategoryButtonClick = (category) => {
      if (selectedCategory === category) {
        setSelectedCategory(null); // Reset if the same category is clicked again
      } else {
          setSelectedCategory(category);
      }
    }

    // Function to return button class based on the selected category
    /*const getButtonClass = (category) => {
      const baseClass = 'rounded-md px-2 text-xs py-1.5 text-white';
      const activeClass = selectedCategory === category ? `bg-${category}-event` : 'bg-grey';
      return `${baseClass} ${activeClass}`;
    };*/

    return (
      // <div className="h-[100svh] sm:h-full flex gap-y-4 flex-col p-6 pt-14 sm:pt-10 border-b-2 sm:border-b-0 sm:border-r-2 border-dark-blue-900 w-full sm:w-1/2 relative">

      <div className={`flex flex-col gap-y-4 ${selectedData ? ' items-start justify-start' : ' items-center justify-center'} overflow-clip sm:h-full w-full sm:w-1/3 border-b-2 sm:border-b-0 sm:border-r-2 border-dark-blue-900 pt-14 sm:pt-10 p-6 `}>
      {!selectedData && (
        <div className="flex flex-col justify-center h-full">
          <p className="text-dark-blue-900">Selecctiona un lote para rastrearlo.</p>
        </div>
      )}
      {selectedData && (
        <div className="relative gap-y-3 flex flex-col h-full w-full">
          <div className="w-full h-8 bor der bord er-black overflow-x-auto">
            <div className="absolute flex gap-x-3  w-full  ">
              {/* filter buttons */}
              <button onClick={() => handleCategoryButtonClick('productor')} className='rounded-md bg-producer-event px-2 text-xs py-1.5 text-white '>
                  Productores
              </button>
              <button onClick={() => handleCategoryButtonClick('mayorista')} className='rounded-md bg-mayorist-event px-2 text-xs py-1.5 text-white '>
                  Mayoristas
              </button>
              <button onClick={() => handleCategoryButtonClick('minorista')} className='rounded-md bg-minorist-event px-2 text-xs py-1.5 text-white '>
                  Minoristas
              </button>
              <button onClick={() => handleCategoryButtonClick('distribuidor')} className='rounded-md bg-distributor-event px-2 text-xs py-1.5 text-white '>
                  Distribuidores
              </button>
            </div>
          </div>
          {/* ! each separate button opens the event to the right for the selected "category" */}
          <div className="h-full w-full relative flex flex-col py-6 gap-y-6">
            <div className="absolute left-0 w-0.5 bg-dark-blue-950 inset-y-0">
            </div>      
            {/**Original design */}                  
            {/**
             * <div className="flex flex-col ml-4 gap-y-1 relative text-producer-event">
              <div className="absolute w-2 h-0.5 -left-[1.125rem] top-1.5 bg-producer-event "></div>
              <span className='text-xs font-sembil'>11.02.2023</span>
              <span>Mayorista 1</span>
            </div>
             */}
            
            {/**Modified to add funcionality */}
            {/**For each category: Productor, Mayorista, Minorista, Distribudiro added to json file to match the design */}
            {/**
             *  if (!selectedCategory || categoryName === selectedCategory) {
             */}
            {Object.entries(selectedData.event.category).map(([categoryName, categoryValue]) => {
                if (!selectedCategory || categoryName === selectedCategory) {
                  return (
                    <button 
                      key={categoryName}
                      className="flex flex-col ml-4 gap-y-1 relative text-producer-event"
                      onClick={() => onButtonClick(selectedData)}
                    >
                      <div className={`absolute w-2 h-0.5 ${categoryName} top-1.5 bg-producer-event`} style={{ left: '-1.125rem'}}></div>
                      <span className='text-xs font-'>{selectedData.event.eventtime}</span>                
                      <span className='text-base font-semibold'>{categoryValue}</span>
                    </button>
                  );
                } 
                return null;
              })}         
          </div>
        </div>
      )}
      </div>      
    );
};

const RightContent = ({selectedData, locations, CO2Transport, setCO2Transport, CO2Packaging, setCO2Packaging, CO2TransportMx, setCO2TransportMx, CO2PackagingMx, setCO2PackagingMx, CO2absorption, setCO2absorption}) => {
  // States for distance, CO2 emissions from transport, and CO2 emissions from packaging
  const [distance, setDistance] = useState(null);
  const chartBarCO2 = '/data/chartBarCO2.json';
  /*const [CO2Transport, setCO2Transport] = useState(null);
  const [CO2Packaging, setCO2Packaging] = useState(null);
  const [CO2TransportMx, setCO2TransportMx] = useState(null);
  const [CO2PackagingMx, setCO2PackagingMx] = useState(null);*/

  //console.log("RightContent selectedData", selectedData);

  // if (!selectedData) {
  //   // Handle the case when selectedData or selectedData.event is not available
  //   return <div className="flex hidden md:block flex-col justify-center h-full">
  //   <p className=" text-dark-blue-900">No se seleccionó ningún dato.</p>
  // </div>
  // }
    //console.log("RightContent selectedData", selectedData.event);

    /**
     * ORIGINAL CODE 
     * / MAKE 1 COLOR FOR EACH TYPE
      <div className='border-l-2 border-dark-blue-950 flex flex-col w-full sm:w-1/3 gap-y-8 p-6'>
        <div className="flex flex-row justify-between">
          <h1>Eventos</h1>
          <div className='flex flex-col '>
            <span className="text-right text-xs font-bold tracking-wide">LOTE</span>
            <span className="text-right text-xs">{selectedData.event.input.lotcode}</span>
          </div>
        </div>
        //MAKE THIS AN EACH LOOP FOR EACH EVENT CREATED *
        //Colors: producer-event, mayorist-event, minorist-event, distributor-event *
        <div className='grid grid-cols-2 gap-3'>
          <h2 className='font-serif text-producer-event text-lg font-bold'>
            EVENTONR
          </h2>
          <div className='text-producer-event grid grid-cols-2 text-xs gap-1'>
            <span className="font-bold">Ubicación</span>
            <span>{selectedData.event.location}</span>
            <span className="font-bold">Fecha</span>
            <span>{selectedData.event.eventtime}</span>
          </div>
          <div className='flex flex-col p-2 bg-white gap-y-1 rounded-lg'>
            <h5 className="text-producer-event font-serif">Entradas</h5>
            <div className="grid rounded-md grid-cols-1 text-white text-xs p-1.5 gap-1 bg-producer-event">
              <span className="font-bold">Productos</span>
              <span>{selectedData.event.name}</span>
              <span className="font-bold">Detalles</span>
              <span>{selectedData.event.input.quantity} {selectedData.event.input.uom}</span>
            </div>
          </div>
          <div className='flex flex-col p-2 bg-white gap-y-1 rounded-lg'>
            <h5 className="text-producer-event font-serif">Salidas</h5>
            <div className="grid rounded-md grid-cols-1 text-white text-xs p-1.5 gap-1 bg-producer-event">
              <span className="font-bold">Productos</span>
              <span>{selectedData.event.name}</span>
              <span className="font-bold">Detalles</span>
              <span>{selectedData.event.output.quantity} {selectedData.event.input.uom}</span>
            </div>
          </div>
        </div>
      </div>
     */
      useEffect(() => {
        const calculateCO2 = async () => {
          /**
           * Note: Use the following data to find out the CO2 between:
           * CO2 Veracruz - Valencia
           * -https://www.carboncare.org/en/co2-emissions-calculator:
           * Veracruz - Valencia = 5285 nm, 9787.82 km
           * Duration: 10 days
           * Transport: ship
           * CO2: 0.84 kg (10kg) Note: Use this as reference to calculate other weights between Veracruz and Valencia
           * (N kg * 0.84 kg CO2) / 10
           * Ex: 1000 kg * 0.84 / 10 = 84 kg CO2 (84.31 kg CO2)
           * -https://www.pier2pier.com/Co2/:
           * Veracruz - Valencia = 5245.07 nm, 9713.87 km
           * Duration: 14 days 
           * Transport: ship
           * CO2: 1.45 kg (10kg)
           * 
           * Note: 
           * CO2 Torrent - Valencia
           * Torrent - Valencia = 10.50 km
           * CO2: 0.01 kg (10kg)
           */
          /**
           * COPY COMMENTED FOR SAFETY PURPOSES. DELETE IT WHEN THIS IS CHANGED TO ADAPT THE CACHE SYSTEM
           * if (!selectedData || !locations) return;

          // Check conditions for transport CO2 calculation
          if (selectedData.bizStep === "shipping" && 
              selectedData.sourceLocations?.length > 0 &&
              selectedData.destinationLocations?.length > 0) {
            
              // Extract necessary details from selectedData
              const sourceLocationName = selectedData.sourceLocations[0];
              const destinationLocationName = selectedData.destinationLocations[0];
              const { quantity } = selectedData.outputs[0]; // Assuming quantity and uom are in outputs

              // Get lat, long for source and destination locations
              const sourceLocation = locations.find(location => location.name === sourceLocationName);
              const destinationLocation = locations.find(location => location.name === destinationLocationName);

              if (!sourceLocation || !destinationLocation) {
                console.error('Source or destination location not found');
                return;
              }

              //const sourceCoordinates = { long: sourceLocation.long, lat: sourceLocation.lat };
              //const destinationCoordinates = { long: destinationLocation.long, lat: destinationLocation.lat };

              const sourceCoordinates = [sourceLocation.long, sourceLocation.lat];
              const destinationCoordinates = [destinationLocation.long, destinationLocation.lat];

              // Get distance
              const calculatedDistance = await getDistance(sourceCoordinates, destinationCoordinates);
              const RoundedcalculatedDistance = parseFloat(calculatedDistance.toFixed(2)); 

              // Get CO2 emissions for transport
              const transportCO2 = await getCO2(calculatedDistance, 'truck', quantity); // Assuming transport method is 'truck'
              const RoundedtransportCO2 = parseFloat(transportCO2.toFixed(2));

              // Get CO2 emissions for packaging
              const packagingCO2 = getCO2packaging(quantity);
              const roundedPackagingCO2 = parseFloat(packagingCO2.toFixed(2)); 

              //
              const CO2tMx = quantity * 0.8431 / 10;
              const roundedCO2tMx = parseFloat(CO2tMx.toFixed(2));
              setCO2TransportMx(roundedCO2tMx);
              const packagingCO2Mx = getCO2packaging(quantity);
              const roundedPackagingCO2Mx = parseFloat(packagingCO2Mx.toFixed(2));
              setCO2PackagingMx(roundedPackagingCO2Mx);

              // Update state with calculated values
              setDistance(calculatedDistance);
              setCO2Transport(RoundedtransportCO2);
              setCO2Packaging(roundedPackagingCO2);
           */
          if (!selectedData || !locations) return;

          // Check conditions for transport CO2 calculation
          if (selectedData.bizStep === "shipping" && 
              selectedData.sourceLocations?.length > 0 &&
              selectedData.destinationLocations?.length > 0 &&
              selectedData.eventType !== "commission") {
            
              // Extract necessary details from selectedData
              const sourceLocationName = selectedData.sourceLocations[0];
              const destinationLocationName = selectedData.destinationLocations[0];
              const { quantity } = selectedData.outputs[0]; // Assuming quantity and uom are in outputs

              // Generate a unique key for the event
              const eventKey = `CO2-${sourceLocationName}-${destinationLocationName}-${quantity}`;

              // Try to get cached CO2 data
              const cachedCO2Data = sessionStorage.getItem(eventKey);
              //console.log("Events calculateCO2 cachedCO2Data", cachedCO2Data);
              if (cachedCO2Data) {
                // Parse the cached data and update the state
                const { transportCO2, packagingCO2, transportCO2Mx, packagingCO2Mx} = JSON.parse(cachedCO2Data);
                setCO2Transport(transportCO2);
                setCO2Packaging(packagingCO2);
                setCO2TransportMx(transportCO2Mx);
                setCO2PackagingMx(packagingCO2Mx);

              } else {
                // Data not in cache, proceed with calculations

                // Get lat, long for source and destination locations
                const sourceLocation = locations.find(location => location.name === sourceLocationName);
                const destinationLocation = locations.find(location => location.name === destinationLocationName);

                if (!sourceLocation || !destinationLocation) {
                  console.error('Source or destination location not found');
                  return;
                }

                //const sourceCoordinates = { long: sourceLocation.long, lat: sourceLocation.lat };
                //const destinationCoordinates = { long: destinationLocation.long, lat: destinationLocation.lat };

                const sourceCoordinates = [sourceLocation.long, sourceLocation.lat];
                const destinationCoordinates = [destinationLocation.long, destinationLocation.lat];

                // Get distance
                const calculatedDistance = await getDistance(sourceCoordinates, destinationCoordinates);
                const RoundedcalculatedDistance = parseFloat(calculatedDistance.toFixed(2)); 

                // Get CO2 emissions for transport
                const transportCO2 = await getCO2(calculatedDistance, 'truck', quantity); // Assuming transport method is 'truck'
                const RoundedtransportCO2 = parseFloat(transportCO2.toFixed(2));

                // Get CO2 emissions for packaging
                const packagingCO2 = getCO2packaging(quantity);
                const roundedPackagingCO2 = parseFloat(packagingCO2.toFixed(2)); 

                //CO2 emissions for transport and packaging from an imported package from Mexico
                const CO2tMx = quantity * 0.8431 / 10;
                const roundedCO2tMx = parseFloat(CO2tMx.toFixed(2));
                const packagingCO2Mx = getCO2packaging(quantity);
                const roundedPackagingCO2Mx = parseFloat(packagingCO2Mx.toFixed(2));

                //Cache the new CO2 data
                const newCO2Data = {
                  transportCO2: RoundedtransportCO2,
                  packagingCO2: roundedPackagingCO2,
                  transportCO2Mx: roundedCO2tMx,
                  packagingCO2Mx: roundedPackagingCO2Mx,
                };
                sessionStorage.setItem(eventKey, JSON.stringify(newCO2Data));

                // Update state with calculated values
                setDistance(calculatedDistance);
                setCO2Transport(RoundedtransportCO2);
                setCO2Packaging(roundedPackagingCO2);
                setCO2TransportMx(roundedCO2tMx);
                setCO2PackagingMx(roundedPackagingCO2Mx);
              }              
            } ;
            
            // Check conditions for CO2 absorption calculation
            if (selectedData.eventType === "commission" && selectedData.name === "Harvest") {
              //Ensure 'quantity' is defined in a scope accessible by this block
              const { quantity } = selectedData.outputs[0]; // Assuming quantity and uom are in outputs
              const absorptionCO2 = getCO2Absorption(quantity);
              const roundedAbsorptionCO2 = parseFloat(absorptionCO2.toFixed(2));
              const absorptionData = {
                categories: ['Absorción de CO2'],
                values: [roundedAbsorptionCO2],
              };
              //Set state or perform other actions with absorptionCO2
              setCO2absorption(absorptionData);
            }                              
        };
      
        calculateCO2();
      }, [selectedData, locations]);

      //console.log("Events rightContent calculateCO2 distance", distance);
      //console.log("Events rightContent calculateCO2 absorptionCO2", CO2absorption);
      //console.log("Events rightContent calculateCO2 CO2Transport", CO2Transport);
      //console.log("Events rightContent calculateCO2 CO2Packaging", CO2Packaging);

      const getDistance = async (coordenadasOrigen, coordenadasDestino) => {
        // Paso 2: Find out the distance 
        const requestOptions = {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ coordinates: [coordenadasOrigen, coordenadasDestino] })
        };
        //console.log("getDistance start");
        //console.log("Request Options:", requestOptions);
        const responseDistancia = await fetch(`/api/getDistancia`, requestOptions);
        //console.log("responseDistancia",responseDistancia);
        const dataDistancia = await responseDistancia.json();
        //console.log("dataDistancia",dataDistancia);
        //const distancia = dataDistancia.features[0].properties.summary.distance / 1000; // Convertir a km
        const distancia = dataDistancia.routes[0].summary.distance / 1000; // La distancia está en metros
        //console.log("distancia",distancia);
        //console.log("getDistance end");
        //setChartDistancia(distancia);
        return distancia;
      }
    
      const getCO2 = async (distancia, medioTransporte, pesoCarga) => {
          // Paso 3: Calculate CO2 emissions
          //Translate transport to English
          //const translatedTransportMethod = transportMethodTranslations[medioTransporte] || medioTransporte;
          const translatedTransportMethod = "truck";
          const requestOptions2 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              distance_value: distancia,
              transport_method: translatedTransportMethod,
              weight_value: pesoCarga,
              type: "shipping",
              weight_unit: "kg",
              distance_unit: "km"
            })
          }
          const responseCO2 = await fetch(`/api/getCalcularCO2`, requestOptions2);
          const dataCO2 = await responseCO2.json();
          //console.log("getCO2 requestOptions2",requestOptions2);
          //console.log("getCO2 responseCO2",responseCO2);
          //console.log("dataCO2",dataCO2);
          const emisionCO2 = dataCO2.data.attributes.carbon_kg;
      
          // Mostrar o procesar la emisión de CO2
          //console.log('getCO2 Emisión de CO2:', emisionCO2, 'kg');
          //setChartDistancia(distancia);
          //setChartCoordenadasOrigen(coordenadasOrigen);
          //setChartCoordenadasDestino(coordenadasDestino);
          //setChartEmisionCO2(emisionCO2);
          return emisionCO2;
      }

      const getCO2packaging = (kgOfAvocados) => {
        /**
         * Note: CO2 emissions per kg of avocado 0.035 kg
         * Source: "Cálculo de la huella de carbono del proceso de normalización del aguacate (Persea americana) en la entidad SAT-FAST de Tenerife (Canarias)."
         * https://riull.ull.es/xmlui/handle/915/25400
         */
        /**
         * Use example:
         * //Calculate CO2 emissions for 1000 kg of avocados
          const exampleBatchSize = 1000; // Batch size (in kg)
          const emissionsForBatch = calculateCO2Emissions(exampleBatchSize);

          return (
              <div>
                  <p>For a batch of {exampleBatchSize} kg of avocados, the CO2 emissions for the packaging process are approximately {emissionsForBatch.toFixed(3)} kg.</p>
              </div>
          );
         */
        //console.log("Events getCO2packaging kgOfAvocados", kgOfAvocados);
         // Constant for CO2 emissions per kg of avocado in the packaging process
        const CO2EmissionsPerKgAvocado = 0.035; // CO2 emissions per kg of avocado

        // Calculate the CO2 emissions for the given kg of avocados
        const CO2Emissions = kgOfAvocados * CO2EmissionsPerKgAvocado;

        //console.log("Events getCO2packaging CO2Emissions", CO2Emissions);
        // Return the CO2 emissions for the packaging process
        return CO2Emissions;
      }
  
      const getCO2Absorption = (kgOfAvocados) => {
        /**
         * Note: Data provided by Ismael's own plantation in Finca El Violi
         * Annual production of avocados: 30000 kg
         * 1000 trees absorps 20000 kg of CO2 annually
         */
        /**
         * Use example:
         * //Calculate CO2 absorption for 1000 kg of avocados
          const exampleBatchSize = 1000; // Batch size (in kg)
          const absorptionForBatch = calculateCO2Absorption(exampleBatchSize);
          
          return (
              <div>
                  <p>For a batch of {exampleBatchSize} kg of avocados, the CO2 absorption is approximately {absorptionForBatch.toFixed(2)} kg.</p>
              </div>
          );
         */
        //console.log("Events getCO2Absorption kgOfAvocados", kgOfAvocados);
        // Constants for annual data
        const annualCO2Absorption = 20000; // Annual CO2 absorption for the entire plantation (in kg)
        const annualAvocadoProduction = 30000; // Annual avocado production for the entire plantation (in kg)

        // Calculate the ratio of the batch size to the annual production
        const ratio = kgOfAvocados / annualAvocadoProduction;

        // Calculate the CO2 absorption for the batch size
        const CO2AbsorptionForBatch = ratio * annualCO2Absorption;

        //console.log("Events getCO2Absorption CO2AbsorptionForBatch", CO2AbsorptionForBatch);
        // Return the CO2 absorption for the batch
        return CO2AbsorptionForBatch;
      }

    return (
      <div className={`flex flex-col gap-y-4 ${selectedData ? ' items-start justify-start ' : 'translate-y-full md:translate-y-0 items-center justify-center'} transition-transform h-[75svh] shadow-lg md:shadow-none bg-light-green-100 md:h-full w-full md:w-1/3 pt-10 p-4 md:p-6 absolute bottom-0 md:top-0 z-50 md:z-0 inset-x-0 md:relative overflow-x-clip overflow-y-auto`}>
        {!selectedData && (
        <div className="flex flex-col justify-center h-full">
          <p className="text-dark-blue-900 text-sm">Busca o selecciona una empresa para obtener más información.</p>
        </div>
        )}
      {selectedData && (
        <div className='relative flex flex-col w-full gap-y-4'>
          {/* <button  className='absolute top-0 right-0 md:hidden text-xl' ><Icon icon="mdi:close" /></button> */}
          <div className="flex flex-col justify-between">
            <span className="text-md w-auto tracking-wide uppercase text-dark-blue-900">{selectedData.eventType}</span>
            <h1 className="capitalize">{selectedData.bizStep}</h1>    
          </div>
          {/* <div className='grid grid-cols-1 gap-3'> */}                        
            <div className="mb-4 w-full">
              <h6>Datos</h6>
              <ComparisonTableEventsData data={selectedData}/>
            </div> 
            <div className='flex flex-col p-2 bg-white gap-y-1 rounded-lg'>
              <h5 className="text-producer-event font-serif">Entrada</h5>
              <div className="grid rounded-md grid-cols-1 text-white text-xs p-1.5 gap-1 bg-producer-event">
                  <span className="font-bold">Producto</span>
                  <span>{selectedData.inputs.map(input => `${input.productName}`).join(', ')}</span>
                  <span className="font-bold">Cantidad</span>
                  <span>{selectedData.inputs.map(input => `${input.quantity} ${input.uom}`).join(', ')}</span>
                  <span className="font-bold">Lote</span>
                  <span>{selectedData.inputs[0]?.lotNumber}</span>
              </div>
            </div>             
            <div className="w-full items-center justify-center flex">
            <Icon icon="mdi:arrow-down" className='w-5 h-5 mx-auto' />
            </div>
            <div className='flex flex-col p-2 bg-white gap-y-1 rounded-lg'>
              <h5 className="text-mayorist-event font-serif">Salida</h5>
              <div className="grid rounded-md grid-cols-1 text-white text-xs p-1.5 gap-1 bg-mayorist-event"> {/**grid-cols-1 */}
                  <span className="font-bold">Producto</span>
                  <span>{selectedData.outputs.map(output => `${output.productName}`).join(', ')}</span>
                  <span className="font-bold">Cantidad</span>
                  <span>{selectedData.outputs.map(output => `${output.quantity} ${output.uom}`).join(', ')}</span>
                  <span className="font-bold">Lote</span>
                  <span>{selectedData.outputs[0]?.lotNumber}</span>
              </div>
            </div>
            {selectedData.blockchain.map((block, index) => (
              <a
                key={index}
                href={block.publicUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-x-1.5 transition-colors py-1.5 px-2 bg-white rounded hover:bg-light-green-200"
              >
                <Icon icon="icon-park-solid:blockchain" />
                <span>Blockchain</span>
              </a>
            ))}          {/* </div> */}
            {/* CO2 data visualization */}
            {selectedData && (CO2Transport || CO2Packaging || CO2TransportMx || CO2PackagingMx) && (
              <div className="mb-4 w-full flex flex-col space-y-2">
                <h6>Emisiones de CO2</h6>
                {/**<h2>Emisiones de CO2 durante el empaquetado y transporte local comparado con la misma cantidad de producto importado desde México.</h2> */}      
                <h2>Comparativa: Transporte Local vs. Importado desde México</h2>        
                <ChartBarStack 
                  CO2Transport={CO2Transport} 
                  CO2Packaging={CO2Packaging} 
                  CO2TransportMx={CO2TransportMx} 
                  CO2PackagingMx={CO2PackagingMx}
                />                
                {/**<p className="text-xs">Para el cálculo del empaquetado se ha tenido en cuenta el estudio realizado por la Universidad de la Laguna, Tenerife</p>  */}
                <p className="text-xs">Fuente:</p>
                <p className="text-xs">"Calculo de la huella de carbono del proceso de normalizacion del aguacate tidad SAT-FAST de Tenerife (Canarias). Autor: Vargas, Jiménez, Cinthia Azucena. 2021."</p>
              </div>                         
            )}
            {/** */}
            {selectedData && (CO2absorption) && (
              <div className="mb-4 w-full flex flex-col space-y-2">
                <h6>Absorción de CO2</h6>
                <h2>Los árboles absorben CO2 durante la producción.</h2>                                  
                {/**
                 * <ChartBar jsonFilePath={chartBarCO2}/>
                 */}                                
                 <ChartBarEvents 
                  //jsonFilePath={CO2absorption}
                  CO2absorption={CO2absorption} 
                />                 
                <p className="text-xs">Fuente:</p>
                <p className="text-xs">"Se toma como referencia una plantacion con 1000 árboles y una producción anual de 30000 kg de aguacate"</p>                                                                                    
              </div>                         
            )}
        </div>
      )}
      </div>
    // ) 
    // : (
    //   <div className="flex justify-center items-center w-full sm:w-1/3 text-grey-500">
    //     <p>No ha seleccionado ninguna categoría.</p>
    //   </div>
    );
            
}

const Events = () => {
          
    //const harvestData= '/data/harvestData.json'
    const [divHeight, setDivHeight] = useState('calc(100vh)'); // Initial height set to 100vh
    const [filteredData, setFilteredData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [storedItems, setStoredItems] = useState([]);
    //
    const [showRightContent, setShowRightContent] = useState(false);
    const [showMiddleContent, setShowMiddleContent] = useState(false);
    //
    const { products, companies, locations, CO2Transport, setCO2Transport, CO2Packaging, setCO2Packaging, CO2TransportMx, setCO2TransportMx, CO2PackagingMx, setCO2PackagingMx, CO2absorption, setCO2absorption } = useStateContext([]);     /**From contextProvider */

    useEffect(() => {
        const setDivHeightFunc = () => {
        // Assuming your navbar has an id of 'navbar'
        //Apparently this doesn't work. Findout the name of the navbar.
        const navbar = document.getElementById('Navbar'); 
        if (navbar) {
            const navbarHeight = navbar.offsetHeight;
            const newDivHeight = window.innerHeight - navbarHeight;
            setDivHeight(`${newDivHeight}px`);
        }
        };

        setDivHeightFunc(); // Set initial height
        window.addEventListener('resize', setDivHeightFunc); // Update on resize

        return () => window.removeEventListener('resize', setDivHeightFunc); // Cleanup
    }, []); // Empty dependency array means this useEffect runs once on mount and cleanup on unmount

    const handleSearch = (filteredData) => {
        //setFilteredData(data);
        setStoredItems(filteredData);
    };

    const handleRowSelect = (data) => {
        //console.log("Products row selected:", data);
        setSelectedRowData(data);
        setShowRightContent(true); //setShowRightContent(false);
        //setShowMiddleContent(true); 
    };

    //Handles button clicks in middleContent
    const handleButtonClick = (data) => {
      setSelectedRowData(data);
      setShowRightContent(true); //setShowRightContent(true);
    }    

    //("Events selectedRowData:", selectedRowData);

    return (
      <div className="flex relative flex-col w-full md:overflow-clip h-full">
      <div className="flex h-[calc(100svh_-_99px)] flex-col md:flex-row">
      <LeftContent 
            onSearch={handleSearch} 
            showTable={showTable} 
            setShowTable={setShowTable} 
            onRowSelect={handleRowSelect}
            setSelectedRowData={setSelectedRowData}
            setShowRightContent={setShowRightContent}
            setShowMiddleContent={setShowMiddleContent}
            setCO2Transport={setCO2Transport}
            setCO2Packaging={setCO2Packaging}
            setCO2TransportMx={setCO2TransportMx}
            setCO2PackagingMx={setCO2PackagingMx}
            setCO2absorption={setCO2absorption}
        />

        <button onClick={() => setSelectedRowData(null) } className={`${(selectedRowData) ? 'opacity-100 md:hidden' : 'opacity-0 pointer-events-none'} cursor-default transition-opacity absolute  inset-0 bg-black/30  z-40`}></button>

        {/**{showMiddleContent && <MiddleContent selectedData={selectedRowData} onButtonClick={handleButtonClick}/>}*/}        
        {showRightContent && 
        <RightContent 
          selectedData={selectedRowData} 
          locations={locations}
          CO2Transport={CO2Transport}
          setCO2Transport={setCO2Transport}
          CO2Packaging={CO2Packaging}
          setCO2Packaging={setCO2Packaging}
          CO2TransportMx={CO2TransportMx}
          setCO2TransportMx={setCO2TransportMx}
          CO2PackagingMx={CO2PackagingMx}
          setCO2PackagingMx={setCO2PackagingMx}
          CO2absorption={CO2absorption}
          setCO2absorption={setCO2absorption}
          />}
             </div>
      <FinanceSection />
        </div>
    )
}

export default Events