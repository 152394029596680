import React, {useEffect, useState} from 'react';
import { useStateContext  } from '../contexts/ContextProvider'; 
import { useNavigate, Link } from 'react-router-dom';
import ZeroFoodLogoNobackground from '../helpers/ZeroFood-logo.svg';
import { Icon } from "@iconify/react";

const VerifyEmailMsg = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // State for spinner visibility

    useEffect(() => {
        // Set a timeout to redirect to the login page after 5 seconds
        const timer = setTimeout(() => {
            setLoading(false); //Stop the spinner before redirecting
            navigate('/login');
        }, 12000); // Adjust the delay as needed

        // Cleanup the timer when the component unmounts
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="flex h-[100svh] sm:h-screen w-full flex-col justify-center items-center gap-y-4 p-2">
            <div className="flex flex-col rounded-xl pt-10 pb-6 px-3 md:px-8 w-full max-w-md gap-y-6 bg-white shadow-md">
                <div className="flex flex-col gap-y-3 items-center">
                    {/**Note: Change of colors and style of login for testing purposes. Colors originally: text-sky-600, For testing: dark-blue-900 */}
                    <h1 className="text-dark-blue-950 w-fit text-center font-bold uppercase text-2xl">Bienvenido a</h1> 
                    <img src={ZeroFoodLogoNobackground} alt="zerofood logo" className="pointer-events-none object-contain w-20 h-24"/>
                </div>              
                <div className='flex flex-col gap-y-3 '>
                    <p className='text-dark-blue-950 w-fit'>Activa tu cuenta.</p>
                    <p className='text-dark-blue-950 w-fit'>Por favor, revisa tu cuenta de email, te hemos enviado un mensaje con un enlace de activación</p>
                    <p className='text-dark-blue-950 w-fit'>A continuación se te redireccionará a la pantalla de login...</p>
                </div> 
                <div className="h-4 flex justify-center">
                    {loading && (
                        <Icon icon="svg-spinners:180-ring" className="text-dark-blue-700" />
                    )}
                </div>
                <nav className="flex flex-col sm:flex-row justify-between items-center gap-2">    
                    <p className="text-sm text-center ">¿No te redirecciona a la pantalla de login?
                        <Link className="inline-block text-center ml-1 text-dark-blue-700 hover:text-dark-blue-500"
                        to="/login">Login</Link>
                    </p>                
                </nav>           
            </div>
        </div>
    )
}

export default VerifyEmailMsg






