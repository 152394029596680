import React from 'react';

const ComparisonTableTransports = (props) => {
    const {data} = props;

    //console.log("ComparisonTableTransports selectedData", data);
    //console.log("ComparisonTableTransports selectedData typeof", typeof data);

    if (!data) {
        return <p>No data available</p>;
    }

    return (
        <table className='standard-table'>
            <tbody>
                <tr>
                    <td>GLN</td>
                    <td>{data ? data.gln : 'N/A'}</td>
                </tr>

                <tr>
                    <td>Código de la Instalación</td>
                    <td>{data ? data.facilityCode : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Rol</td>
                    <td>{data ? data.role : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Estado</td>
                    <td>{data ? data.status : 'N/A'}</td>
                </tr>
                <tr>
                    <td>Fecha añadida</td>
                    <td>{data ? data.date : 'N/A'}</td>
                </tr>

            </tbody>
        </table>
    );
};

export default ComparisonTableTransports;
